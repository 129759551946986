import React, { useMemo } from "react";
import { useGetProjectApi } from "../../../hooks/project-api/query/addProjectApi.query";
import ProjectApiInterface from "./components/ProjectApiInterface";

const ProjectApi = () => {
  const { data, isLoading } = useGetProjectApi();

  const projects: TApiDocs[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data.map((project: TApiDocs) => ({
        ...project,
      }));
    }
    return [];
  }, [isLoading, data]);

  return <ProjectApiInterface data={projects} />;
};

export default ProjectApi;
