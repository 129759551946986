export type TSprintValues = {
  name: string;
  status: string;
  projectId: number;
  _id?: string;
  sprintId: number;
  progress: number;
};

export const AddSprintValues: TSprintValues = {
  name: "",
  projectId: 0,
  status: "",
  _id: "",
  progress: 0,
  sprintId: 0,
};
