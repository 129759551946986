import { Box, createStyles } from "@mantine/core";
import React, { useState } from "react";
import { COLORS } from "../../assets/colors/index.colors";
import Feedback from "./components/Feedback";
import moment from "moment";

const EmployFeedback = () => {
  const { classes } = useStyles();
  const [year, setYear] = useState<Date | null>(new Date());
  const [selectedStudent, setSelectedStudent] = useState<string>("All");
  const startDate = moment(year).startOf("year").toDate();
  const endDate = moment(year).endOf("year").toDate();

  return (
    <Box className={classes.root}>
      <Feedback
        endDate={endDate}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        setYear={setYear}
        startDate={startDate}
      />
    </Box>
  );
};

export default EmployFeedback;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
}));
