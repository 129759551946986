import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const CreateNotificationSend = async (data: TNotificationSend) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.notificationSend.CREATE_NOTIFICATION_SEND,
    method: "POST",
    data: data,
  });
  return response;
};

export const useCreateNotificationSendMutation = () => {
  return useMutation(CreateNotificationSend);
};
