import { Box, createStyles, Group, Select, Text, Tooltip } from "@mantine/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { DateInput, DateValue } from "@mantine/dates";
import { IconCaretLeftFilled, IconCaretRightFilled } from "@tabler/icons-react";
import moment from "moment";
import { handleDateTypeChange } from ".././helper/handleDateTypeChange";
import { updateDateRange } from ".././helper/updateDateRange";
import { calculateHours } from ".././helper/calculateHours";
import { startOfDay } from "date-fns";
import { COLUMNS } from "../../../../columns";
import { useGetAllStudent } from "../../../../hooks/students/query/getAllStudent.query";
import { useGetAllTaskSpendTime } from "../../../../hooks/reports/query/getAllTaskSpendTime.query";
import { useGetAllTaskReport } from "../../../../hooks/reports/query/getAllTaskReport.query";
import { useGetAllHoliday } from "../../../../hooks/holiday/query/getAllHoliday.query";
import { useGetAllLeaveDate } from "../../../../hooks/reports/query/getAllLeaveDate.query";
import CustomTable from "../../../../component/table";
import { CONSTANTS } from "../../../../constant";

interface IReport {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedStudent: string | undefined;
  setSelectedStudent: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectStudent?: boolean;
  defaultSelectedStudent?: string;
}

const Report: FC<IReport> = ({
  endDate,
  selectedStudent,
  setEndDate,
  setSelectedStudent,
  setStartDate,
  startDate,
  selectStudent = true,
  defaultSelectedStudent,
}) => {
  const { classes } = useStyles();

  const tableColumns = [...COLUMNS.taskReport];
  const [activePage, setActivePage] = useState(1);

  const [dateType, setDateType] = useState<string | null>("Day");
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });

  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const { data: spendTime } = useGetAllTaskSpendTime({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const { data, isLoading } = useGetAllTaskReport({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const taskReport: ITaskReport[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  useEffect(() => {
    if (students.length > 0 && !selectedStudent) {
      setSelectedStudent(
        defaultSelectedStudent && defaultSelectedStudent !== ""
          ? defaultSelectedStudent
          : students[0].value
      );
    }
  }, [students, defaultSelectedStudent]);

  const { data: allHoliday } = useGetAllHoliday();

  const { data: leaveDate } = useGetAllLeaveDate({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const calculatedHours = useMemo(
    () =>
      calculateHours(
        startDate,
        endDate,
        allHoliday?.data,
        leaveDate?.data?.map((leave) => ({
          dates: leave.dates,
          type: leave.type,
          status: leave.status,
        })) ?? []
      ),
    [startDate, endDate, dateType, allHoliday?.data, leaveDate?.data]
  );

  const handleStartDateChange = (value: DateValue) => {
    if (value) {
      setStartDate(new Date(value.toString()));
    }
  };
  const handleEndDateChange = (value: DateValue) => {
    if (value) {
      setEndDate(new Date(value.toString()));
    }
  };

  return (
    <Box>
      <Group position="apart" style={{ marginBottom: 20 }}>
        {selectStudent && (
          <Group>
            <Select
              data={[...students]}
              value={selectedStudent}
              onChange={(value) => {
                if (value) setSelectedStudent(value);
              }}
              searchable
            />
            <Box w={100}>
              <Select
                defaultValue="Day"
                data={["Day", "Week", "Month", "Custom"]}
                value={dateType}
                onChange={(value) => {
                  if (value) {
                    handleDateTypeChange(
                      value,
                      setDateType,
                      setStartDate,
                      setEndDate
                    );
                  }
                }}
              />
            </Box>
            {dateType === "Custom" ? (
              <Group className={classes.dateInputContainer}>
                <Tooltip label="Start Date">
                  <DateInput
                    value={startDate}
                    onChange={handleStartDateChange}
                    placeholder="Date input"
                    w={80}
                    height={"auto"}
                    mx="auto"
                    classNames={{ input: classes.dateInput }}
                    valueFormat="DD MMM YYYY "
                    maxDate={new Date()}
                  />
                </Tooltip>

                <Text>-</Text>
                <Tooltip label="End Date">
                  <DateInput
                    value={endDate}
                    onChange={handleEndDateChange}
                    placeholder="Date input"
                    w={80}
                    height={"auto"}
                    mx="auto"
                    classNames={{ input: classes.dateInput }}
                    valueFormat="DD MMM YYYY "
                    maxDate={new Date()}
                  />
                </Tooltip>
              </Group>
            ) : (
              <Group>
                <button
                  className={classes.arrowContainer}
                  onClick={() =>
                    updateDateRange(
                      "prev",
                      startDate,
                      dateType,
                      setStartDate,
                      setEndDate
                    )
                  }
                >
                  <IconCaretLeftFilled
                    className={classes.linkIconActive}
                    stroke={1.5}
                  />
                </button>
                <Box className={classes.dateContainer}>
                  <Text size="sm" fw={500}>
                    {startDate ? moment(startDate).format("DD MMM YYYY") : ""} -
                    {endDate ? moment(endDate).format("DD MMM YYYY") : ""}
                  </Text>
                </Box>
                <button
                  className={classes.arrowContainer}
                  onClick={() =>
                    updateDateRange(
                      "next",
                      startDate,
                      dateType,
                      setStartDate,
                      setEndDate
                    )
                  }
                  disabled={endDate ? endDate >= startOfDay(new Date()) : false}
                >
                  <IconCaretRightFilled
                    className={classes.linkIconActive}
                    stroke={1.5}
                  />
                </button>
              </Group>
            )}
          </Group>
        )}

        {selectedStudent !== "All" && (
          <Group className={classes.hoursContainer}>
            <Text size={"sm"} fw={400}>
              Hours :{" "}
              <span style={{ fontWeight: 500 }}>{calculatedHours}h</span>
            </Text>

            <Text size={"sm"} fw={400}>
              Spend: <span style={{ fontWeight: 500 }}>{spendTime?.data}H</span>
            </Text>
          </Group>
        )}
      </Group>

      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={taskReport}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
    </Box>
  );
};

export default Report;

const useStyles = createStyles(() => ({
  hoursContainer: {
    borderRadius: 6,
    border: "1px solid #ced4da",
    padding: "8px 16px",
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
  dateInput: {
    borderWidth: 0,
    padding: 0,
    cursor: "pointer",
    fontWeight: 500,
  },
  dateInputContainer: {
    border: "1px solid #757373",
    padding: "0px 8px",
    borderRadius: 6,
  },
}));
