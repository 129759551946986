import { addMonths, endOfMonth, startOfMonth, startOfDay } from "date-fns";

export const updateMonth = (
  monthOffset: number,
  setStartDate: (value: React.SetStateAction<Date>) => void,
  setEndDate: (value: React.SetStateAction<Date>) => void
) => {
  setStartDate((prev) => startOfMonth(addMonths(prev, monthOffset)));

  setEndDate((prev) => {
    const newEndDate = endOfMonth(addMonths(prev, monthOffset));
    const today = startOfDay(new Date());
    return newEndDate > today ? today : newEndDate;
  });
};
