import { Box, createStyles } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { COLUMNS } from "../../../columns";
import CustomTable from "../../../component/table";
import { COLORS } from "../../../assets/colors/index.colors";
import { useGetAllWorkingTasks } from "../../../hooks/task-status/query/getWorkingTasks.query";
import { filterAndSortWorkingTaskData } from "./utils";

const WorkingTasks: React.FC = () => {
  const { classes } = useStyles();
  const tableColumns = [...COLUMNS.workingTask];
  const [search, setSearch] = useState("");

  const { data, isLoading } = useGetAllWorkingTasks();

  const taskStatus: IWorkingTasks[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      return filterAndSortWorkingTaskData(data.data, search);
    } else {
      return [];
    }
  }, [isLoading, data, search]);

  return (
    <Box className={classes.root}>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={taskStatus}
        grid={false}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
        }}
      />
    </Box>
  );
};

export default WorkingTasks;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
  hoursContainer: {
    borderRadius: 6,
    border: "1px solid #ced4da",
    padding: "8px 16px",
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
  dateInput: {
    borderWidth: 0,
    padding: 0,
    cursor: "pointer",
    fontWeight: 500,
  },
  dateInputContainer: {
    border: "1px solid #757373",
    padding: "0px 8px",
    borderRadius: 6,
  },
}));
