import React from "react";
import { Box, Chip, Flex, Stack, Text } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import { openProjectLink } from "../helper/openProjectLink";
import { openTaskLink } from "../helper/openTaskLink";

export const MONTHLY_PROJECT_HOURS: TMonthlyWorkHours[] = [
  {
    key: "date",
    header: "Date",
    renderCell: (row: TMonthlyWorkHourRecordColumn) => {
      const { date, hoursWorked, status } = row;
      return (
        <Flex direction={"column"}>
          <Text color={"#000"} weight={"500"} size={"sm"}>
            {date}
          </Text>

          <Box mt={4}>
            <Text span color="gray" weight={"lighter"}  size="xs">
              Total Hours :{" "}
            </Text>
            <Text span c="blue" weight={"lighter"} >
              {hoursWorked.toFixed(2)} h
            </Text>
          </Box>

          {status !== "Present" && (
            <Box mt={10}>
              <span
                style={{
                  fontWeight: "600",
                  color: "white",
                  padding: "4px 12px",
                  fontSize: 10,
                  borderRadius: 8,
                  background: `${
                    status.includes("On Leave") ? "#f89b29" : "#d90303a8"
                  }`,
                }}
              >
                {status}
              </span>
            </Box>
          )}
        </Flex>
      );
    },
    minWidth: 200,
  },
  {
    key: "projectId",
    header: "Projects & Time",
    renderCell: (row: TMonthlyWorkHourRecordColumn) => {
      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
          {row.projects.length > 0 &&
            row.projects.map((project, index) => (
              <Box key={project.project_id + index}>
                {project.project_name && (
                  <Chip
                    checked={false}
                    size="lg"
                    styles={{ label: { padding: "24px 0px" } }}
                  >
                    <a
                      href={openProjectLink(Number(project.project_id))}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        padding: "0px 24px",
                      }}
                    >
                      <Stack align="flex-start" spacing={0}>
                        <Text size="sm" weight={500} color="#5e5e5e">
                          {project.project_name}
                        </Text>
                        <Flex align="center" gap={4}>
                          <IconClockHour4
                            stroke={2}
                            size={14}
                            color="#868e96"
                          />
                          <Text
                            size="sm"
                            c="blue"
                            style={{ marginRight: 8 }}
                            weight={500}
                          >
                            {parseFloat(
                              project.spend_time.toString() || "0"
                            ).toFixed(2)}{" "}
                            h
                          </Text>
                        </Flex>
                      </Stack>
                    </a>
                  </Chip>
                )}
              </Box>
            ))}
        </Flex>
      );
    },
  },
  {
    key: "task_id",
    header: "Tasks & Time",
    renderCell: (row: TMonthlyWorkHourRecordColumn) => {
      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
          {row.tasks.length > 0 &&
            row.tasks.map((task, index) => (
              <Box key={task.task_id + index}>
                {task.task_id && (
                  <Chip
                    checked={false}
                    size="lg"
                    styles={{ label: { padding: "24px 0px" } }}
                  >
                    <a
                      href={openTaskLink(Number(task.task_id))}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        padding: "0px 24px",
                      }}
                    >
                      <Stack align="flex-start" spacing={0}>
                        <Text size="sm" weight={500} color="#5e5e5e">
                          Task #{task.task_id}
                        </Text>
                        <Flex align="center" gap={4}>
                          <IconClockHour4
                            stroke={2}
                            size={14}
                            color="#868e96"
                          />
                          <Text
                            size="sm"
                            c="blue"
                            style={{ marginRight: 8 }}
                            weight={500}
                          >
                            {parseFloat(task.spend_time.toString()).toFixed(2)}{" "}
                            h
                          </Text>
                        </Flex>
                      </Stack>
                    </a>
                  </Chip>
                )}
              </Box>
            ))}
        </Flex>
      );
    },
  },
];
