export const handleDateTypeChange = (
  value: string | null,
  setDateType: (value: React.SetStateAction<string | null>) => void,
  setStartDate: (value: React.SetStateAction<Date>) => void,
  setEndDate: (value: React.SetStateAction<Date>) => void
) => {
  if (!value) return;

  setDateType(value);

  const today = new Date();
  let newStartDate = new Date();
  let newEndDate = new Date();

  if (value === "Day") {
    newStartDate = today;
    newEndDate = today;
  } else if (value === "Week") {
    const dayOfWeek = today.getDay();
    newStartDate.setDate(today.getDate() - dayOfWeek);
    newEndDate.setDate(newStartDate.getDate() + 6);
  } else if (value === "Month") {
    newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
    newEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  }

  if (newEndDate > today) {
    newEndDate = today;
  }

  setStartDate(newStartDate);
  setEndDate(newEndDate);
};
