import { Chip, Flex, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconClock, IconListCheck } from "@tabler/icons-react";
import TableModal from "./TableModal";
import React from "react";

export const openTableModal = (
  row: EmployeeWorkData,
  hiddenTasks: TaskEntry[]
) => {
  modals.open({
    modalId: "tableModal",
    centered: true,
    title: (
      <Flex gap="sm" mt={"md"}>
        <Text color={"#000"} fw={600} size="md" mb="sm">
          {row.empName}
        </Text>

        <Flex gap="sm">
          <Chip
            variant="light"
            color="blue"
            radius="md"
            size="sm"
            sx={{ pointerEvents: "none" }}
          >
            <Flex align="center" gap={"xs"}>
              <IconClock size={16} />
              <Text color={"#000"} fw={600} size="sm">
                Work Hours
              </Text>
              {Number(row.totalWorkHours).toFixed(2)} hrs
            </Flex>
          </Chip>

          <Chip
            variant="light"
            color="green"
            radius="md"
            size="sm"
            sx={{ pointerEvents: "none" }}
          >
            <Flex align="center" gap="xs">
              <IconListCheck size={16} />
              <Text color={"#000"} fw={600} size="sm">
                Tasks
              </Text>
              {row.tasks.length}
            </Flex>
          </Chip>
        </Flex>
      </Flex>
    ),
    size: "lg",
    radius: "md",
    withCloseButton: true,
    children: <TableModal row={row} hiddenTasks={hiddenTasks} />,
  });
};
