import { Box } from "@mantine/core";
import React, { useMemo, useState } from "react";
import CustomTable from "../../../component/table";
import { COLUMNS } from "../../../columns";
import { CONSTANTS } from "../../../constant";
import { useGetAllNotificationSend } from "../../../hooks/notificationSend/query/getAllFeedback.query";

const NotificationSendLogs = () => {
  const tableColumns = [...COLUMNS.notificationSend];

  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const [search, setSearch] = useState("");

  const { data, isLoading } = useGetAllNotificationSend({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    searchParams: {
      search,
    },
  });

  const emailSendData: TNotificationSend[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={emailSendData}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
        }}
      />
    </Box>
  );
};

export default NotificationSendLogs;
