import { ActionIcon } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { Tooltip } from "@mantine/core";
import { openProjectLink } from "../helper/openProjectLink";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PROJECTS_COLUMNS: TProjectsTableColumns[] = [
  {
    key: "name",
    header: "Name",
    renderCell: (row: TProjectsData) => {
      const { name, projectId } = row;
      return (
        <div>
          <a
            href={openProjectLink(Number(projectId))}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            {name}
          </a>
        </div>
      );
    },
  },
  {
    key: "projectId",
    header: "ID",
    renderCell: (row: TProjectsData) => {
      const { projectId } = row;
      return (
        <div>
          <a
            href={openProjectLink(Number(projectId))}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            {projectId}
          </a>
        </div>
      );
    },
  },
  { key: "status", header: "Status" },
  {
    key: "Info",
    header: "Info",
    renderCell: (row: TProjectsData) => {
      const navigate = useNavigate();
      const { projectId } = row;
      return (
        <Tooltip label="Project Details">
          <ActionIcon
            variant="outline"
            color="teal"
            onClick={() => navigate(`/project-info/${projectId}`)}
          >
            <IconExternalLink size={20} cursor={10} />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
];
