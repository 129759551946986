import { Box, createStyles, Text } from "@mantine/core";
import React from "react";
import IMAGES from "../../../images";
import moment from "moment";

interface IProps {
  type: "leave" | "late" | "absent" | "incomplete" | "noLogOut" | "noTag";
  name: string;
  time?: Date;
  workingHours?: string;
}

const EmployeeCard = ({ type, name, time, workingHours }: IProps) => {
  const { classes } = useStyles();
  const statusStyles = {
    leave: {
      background: "rgba(250, 176, 5, 0.1)",
      color: "rgb(250, 176, 5)",
    },
    late: {
      background: "rgba(250, 176, 5, 0.1)",
      color: "rgb(250, 176, 5)",
    },
    absent: {
      background: "rgba(255, 99, 71, 0.1)",
      color: "rgb(255, 99, 71)",
    },
    incomplete: {
      background: "rgba(255, 165, 0, 0.1)",
      color: "rgb(255, 165, 0)",
    },
    noLogOut: {
      background: "rgba(255, 99, 71, 0.1)",
      color: "rgb(220, 20, 60)",
    },
    noTag: {
      background: "transparent",
      color: "transparent",
    },
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.flex}>
          <img src={IMAGES.profile} alt="profile" className={classes.image} />
          <Box>
            <Text size={"md"} color="#646566">
              {name}
            </Text>
            {type === "late" && time && (
              <Text size="xs" color="#000">
                {moment(time).format("hh:mm A")}
              </Text>
            )}
            {["incomplete", "noLogOut", "noTag"].includes(type) && (
              <Text size="xs" color="#646566">
                Total Hours :-{" "}
                <Text span size="xs" fw={600} color="#000">
                  {workingHours}
                </Text>
              </Text>
            )}
          </Box>
        </Box>

        {type !== "noTag" && (
          <Box
            style={{
              background: statusStyles[type]?.background,
              padding: "2px 10px",
              borderRadius: 6,
              paddingBottom: 4,
            }}
          >
            <Text size={"xs"} fw={"bold"} color={statusStyles[type]?.color}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EmployeeCard;

const useStyles = createStyles(() => ({
  root: {
    marginTop: 24,
    marginBottom: 16,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid rgb(234, 234, 234)",
    paddingBottom: 8,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: 30,
    height: 30,
    borderRadius: 50,
    marginRight: 12,
  },
  leaveBgContainer: {
    background: "rgba(250, 176, 5, 0.1)",
    padding: "2px 10px",
    borderRadius: 6,
    paddingBottom: 4,
  },
}));
