import { Box } from "@mantine/core";
import React, { useMemo, useRef } from "react";
import CustomTable from "../../../component/table";
import ThemeButton from "../../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import { COLUMNS } from "../../../columns";
import ActionButton from "./components/ActionButton";
import LeavePolicyModel, {
  ILeavePolicyModelRef,
} from "./model/LeavePolicyModel";
import { useGetLeavePolicy } from "../../../hooks/leave-policy/query/getLeavePolicy";

const LeavesPolicy = () => {
  const modalRef = useRef<ILeavePolicyModelRef>(null);
  const tableColumns = [...COLUMNS.leavePolicy];
  const { data, isLoading, refetch } = useGetLeavePolicy();

  const leavePolicy: TLeavePolicy[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      return data.data.map((student: TLeavePolicy) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  tableColumns.push({
    header: "Actions",
    key: "editProjects",
    renderCell: (row: TLeavePolicy) => (
      <ActionButton
        handleClick={() => {
          modalRef.current?.toggleModal();
          modalRef.current?.updateData(row);
        }}
      />
    ),
  });

  return (
    <Box>
      <LeavePolicyModel ref={modalRef} reload={refetch} />
      {/* Table Component */}
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={leavePolicy}
        grid={false}
        headerProps={{
          rightComponent: (
            <Box
              onClick={() => modalRef.current?.toggleModal()}
              style={{ margin: "10px" }}
            >
              <ThemeButton
                title="Add Leave Policy"
                mr={15}
                leftIcon={<IconPlus size={20} />}
              />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default LeavesPolicy;
