export const getDateRange = (
  dateStrings: string[]
): { from: Date; to: Date } => {
  if (dateStrings.length === 0) {
    throw new Error("Date array is empty");
  }

  const dates = dateStrings.map((dateStr) => new Date(dateStr));

  dates.sort((a, b) => a.getTime() - b.getTime());

  const from = dates[0];
  const to = dates[dates.length - 1];

  return { from, to };
};
