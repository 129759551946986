export type TStoryValues = {
  name: string;
  status: string;
  projectId: number;
  _id?: string;
  storyId: number;
  progress: number;
};

export const AddStoryValues: TStoryValues = {
  name: "",
  projectId: 0,
  status: "",
  _id: "",
  progress: 0,
  storyId: 0,
};
