import {
  Box,
  Text,
  Group,
  Title,
  Avatar,
  createStyles,
  Indicator,
  Divider,
  Center,
  Grid,
  Flex,
  Tooltip,
  Select,
} from "@mantine/core";
import React, { useState } from "react";
import { IconCaretLeftFilled, IconCaretRightFilled } from "@tabler/icons-react";
import IMAGES from "../../../images";
import { DateInput, DateValue } from "@mantine/dates";
import { startOfDay } from "date-fns";
import moment from "moment";
import { handleDateTypeChange } from "../../works/task-report/helper/handleDateTypeChange";
import { updateDateRange } from "../../works/task-report/helper/updateDateRange";

interface IHeaderValues {
  extraData: TEmployeeHeaderData | null;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const Header: React.FC<IHeaderValues> = ({
  extraData,
  endDate,
  setEndDate,
  setStartDate,
  startDate,
}) => {
  const { classes } = useStyles();
  const [dateType, setDateType] = useState<string | null>("Month");

  const handleStartDateChange = (value: DateValue) => {
    if (value) {
      setStartDate(new Date(value.toString()));
    }
  };
  const handleEndDateChange = (value: DateValue) => {
    if (value) {
      setEndDate(new Date(value.toString()));
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Flex justify="space-between" align="start" wrap="wrap">
        <Box w={400}>
          <Title size="2rem" weight={500}>
            Employee Details
          </Title>

          <Divider my="sm" />
          <Box mt={10}>
            <Group spacing="xs" align="flex-start" noWrap mt={25} mr={30}>
              <Avatar src={IMAGES.profile} size={45} radius="xl" mb={30} />
              <Box mr={30}>
                <Text fz="md" tt="uppercase" fw={700}>
                  {extraData?.name}
                </Text>
                <Text fz="sm" fw={500} c="dimmed">
                  {extraData?.email}
                </Text>
              </Box>
            </Group>
          </Box>
          <Divider my="sm" />
          <Grid className={classes.dayListContainer} align="center">
            <Grid.Col
              span={4}
              className={`${classes.dayList} ${classes.dayListStart}`}
            >
              <Center>
                <Indicator
                  zIndex={0}
                  size={8}
                  position="middle-start"
                  color="#1570ef"
                >
                  <Text ml={10} size="xs">
                    On Time
                    <Text ml={10} size="xs" span={true} c="dimmed">
                      {extraData?.percentageOnTime
                        ? extraData.percentageOnTime.toPrecision(3)
                        : 0}
                      %
                    </Text>
                  </Text>
                </Indicator>
              </Center>
            </Grid.Col>
            <Grid.Col
              span={4}
              className={`${classes.dayList} ${classes.dayListMid}`}
            >
              <Center>
                <Box>
                  <Indicator
                    zIndex={0}
                    size={8}
                    position="middle-start"
                    color="#f79009"
                  >
                    <Text ml={10} size="xs">
                      Late
                      <Text ml={10} size="xs" span={true} c="dimmed">
                        {extraData?.percentageLate
                          ? extraData.percentageLate.toPrecision(3)
                          : 0}
                        %
                      </Text>
                    </Text>
                  </Indicator>
                </Box>
              </Center>
            </Grid.Col>
            <Grid.Col
              span={4}
              className={`${classes.dayList} ${classes.dayListEnd}`}
            >
              <Center>
                <Indicator
                  zIndex={0}
                  size={8}
                  position="middle-start"
                  color="#ff4405"
                >
                  <Text ml={10} size="xs">
                    Absent
                    <Text ml={10} size="xs" span={true} c="dimmed">
                      {extraData?.percentageAbsent
                        ? extraData.percentageAbsent.toPrecision(3)
                        : 0}
                      %
                    </Text>
                  </Text>
                </Indicator>
              </Center>
            </Grid.Col>
          </Grid>
        </Box>

        <Box>
          <Box>
            <Group position="apart" style={{ marginBottom: 20 }}>
              <Group>
                <Box w={100}>
                  <Select
                    defaultValue="Day"
                    data={["Day", "Week", "Month", "Custom"]}
                    value={dateType}
                    onChange={(value) => {
                      if (value) {
                        handleDateTypeChange(
                          value,
                          setDateType,
                          setStartDate,
                          setEndDate
                        );
                      }
                    }}
                  />
                </Box>
                {dateType === "Custom" ? (
                  <Group className={classes.dateInputContainer}>
                    <Tooltip label="Start Date">
                      <DateInput
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Date input"
                        w={80}
                        height={"auto"}
                        mx="auto"
                        classNames={{ input: classes.dateInput }}
                        valueFormat="DD MMM YYYY "
                        maxDate={new Date()}
                      />
                    </Tooltip>

                    <Text>-</Text>
                    <Tooltip label="End Date">
                      <DateInput
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Date input"
                        w={80}
                        height={"auto"}
                        mx="auto"
                        classNames={{ input: classes.dateInput }}
                        valueFormat="DD MMM YYYY "
                        maxDate={new Date()}
                      />
                    </Tooltip>
                  </Group>
                ) : (
                  <Group>
                    <button
                      className={classes.arrowContainer}
                      onClick={() =>
                        updateDateRange(
                          "prev",
                          startDate,
                          dateType,
                          setStartDate,
                          setEndDate
                        )
                      }
                    >
                      <IconCaretLeftFilled
                        className={classes.linkIconActive}
                        stroke={1.5}
                      />
                    </button>
                    <Box className={classes.dateContainer}>
                      <Text size="sm" fw={500}>
                        {startDate
                          ? moment(startDate).format("DD MMM YYYY")
                          : ""}{" "}
                        -{endDate ? moment(endDate).format("DD MMM YYYY") : ""}
                      </Text>
                    </Box>
                    <button
                      className={classes.arrowContainer}
                      onClick={() =>
                        updateDateRange(
                          "next",
                          startDate,
                          dateType,
                          setStartDate,
                          setEndDate
                        )
                      }
                      disabled={
                        endDate ? endDate >= startOfDay(new Date()) : false
                      }
                    >
                      <IconCaretRightFilled
                        className={classes.linkIconActive}
                        stroke={1.5}
                      />
                    </button>
                  </Group>
                )}
              </Group>
            </Group>
          </Box>
          <Box style={{ textAlign: "end" }} mt={30}>
            <Text c="dimmed">Total Attendance</Text>
            <Text fz="lg" fw={700}>
              {extraData?.totalAttendance}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
const useStyles = createStyles(() => ({
  mainBox: {
    padding: "1.5rem",
    marginTop: 0,
  },
  currentDate: {
    marginBlock: "0.5rem",
  },
  dayListContainer: {
    alignItems: "center",
    marginBlock: "1rem",
    minWidth: "25rem",
  },
  dayList: {
    borderColor: "#9ea7b7",
    borderStyle: "solid",
    borderWidth: "1px",
  },
  dayListStart: {
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
  },
  dayListMid: {
    borderInline: "0.5px",
  },
  dayListEnd: {
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
  calendarResetButton: {
    backgroundColor: "white",
    borderColor: "#ff008a",
    color: "#ff008a",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
  dateInputContainer: {
    border: "1px solid #757373",
    padding: "0px 8px",
    borderRadius: 6,
  },
  dateInput: {
    borderWidth: 0,
    padding: 0,
    cursor: "pointer",
    fontWeight: 500,
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
}));
export default Header;
