export const attendanceUrls = {
  ALL_EMPLOYEE_ATTENDANCE: "/admin/all-employee-attendance",
  ALL_EMPLOYEE_ABSENT_ATTENDANCE: "/admin/all-employee-absent-attendance",
  EMPLOYEE_ATTENDANCE: "/admin/employee-attendance",
  GET_TODAY_ATTENDANCE: "/admin/get-today-attendance",
  GET_TODAY_ABSENT_ATTENDANCE: "/admin/all-today-absent-attendance",
  GET_TODAY_LATE_ATTENDANCE: "/admin/all-today-late-attendance",
  GET_TODAY_LEAVE_ATTENDANCE: "/admin/all-today-leave-attendance",
  GET_TODAY_UNDER_NINE_HOURS_ATTENDANCE:
    "/admin/all-today-Under-nine-hours-attendance",
  GET_PREVIOUS_NOT_OUT_ATTENDANCE: "/admin/all-previous-not-out-attendance",
  GET_LESS_WORK_HOUR_EMPLOYEES:"/admin/get-less-work-hour-employee",
};
