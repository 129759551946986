import { format } from "date-fns";

export const projectHoliday = (
  date: Date,
  isHoliday: (date: string) => boolean
): string => {
  const formattedDate = format(date, "yyyy-MM-dd");

  if (isHoliday(formattedDate)) {
    return "Holiday";
  }

  return "";
};
