import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getWorkLogApi = async () => {
  const response: TServerResponse<TApiDocs[], unknown> = await request({
    url: apiUrls.projectApi.GET_WORK_LOG,
    method: "GET",
  });
  return response;
};

export const useGetWorkLogApi = () => {
  return useQuery(["api", "get-work-log"], () => getWorkLogApi());
};
