import React, { useEffect, useMemo, useState } from "react";
import { Box, SimpleGrid, Tabs } from "@mantine/core";
import { useGetEmployeeAttendance } from "../../../hooks/student-attendance/query/getAttendance.query";
import { CONSTANTS } from "../../../constant";
import { useParams } from "react-router-dom";
import ThemeLoader from "../../../component/loader/ThemeLoader";
import ThemePagination from "../../../component/table/pagination/ThemePagination";
import Header from "./Header";
import AttendanceCard from "./AttendanceCard";
import moment from "moment";
import {
  IconCalendar,
  IconChecklist,
  IconTimelineEventText,
  IconUserEdit,
} from "@tabler/icons-react";
import { IconMessagePlus } from "@tabler/icons-react";
import Feedback from "../../employ-feedback/components/Feedback";
import SingleEmpWorkDetail from "./SingleEmpWorkDetail";
import Scheduler from "../../works/timeline/task-report-scheduler/Scheduler";
import Report from "../../works/task-report/components/Report";

const InfoCard = () => {
  const { userId } = useParams();
  const [activePage, setActivePage] = useState(1);
  const inputDate = moment(new Date()).startOf("month").toDate();
  const [startDate, setStartDate] = useState<Date>(inputDate);
  const endDates = moment(new Date()).toDate();
  const [endDate, setEndDate] = useState<Date>(endDates);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });

  const { refetch, data, isLoading } = useGetEmployeeAttendance({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    studentId: userId,
  });
  useEffect(() => {
    refetch();
  }, [refetch]);

  const attendance: TAttendanceData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <ThemeLoader loading={isLoading} />;
  }

  return (
    <Box bg={"#fff"} px={20} pb={20} style={{ borderRadius: 5 }}>
      <Header
        extraData={data?.extraData !== undefined ? data?.extraData : null}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
      />
      <Tabs defaultValue="attendance">
        <Tabs.List>
          <Tabs.Tab value="attendance" icon={<IconCalendar size="0.8rem" />}>
            All Attendance
          </Tabs.Tab>
          <Tabs.Tab value="feedback" icon={<IconMessagePlus size="0.8rem" />}>
            Feedback
          </Tabs.Tab>
          <Tabs.Tab
            value="timeLine"
            icon={<IconTimelineEventText size="0.8rem" />}
          >
            Timeline
          </Tabs.Tab>
          <Tabs.Tab value="taskReport" icon={<IconChecklist size="0.8rem" />}>
            Task Report
          </Tabs.Tab>
          <Tabs.Tab value="empWork" icon={<IconUserEdit size="0.8rem" />}>
            Emp Work
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="attendance" pt="xs">
          <Box>
            <SimpleGrid cols={3} spacing="xl" mt={50}>
              <AttendanceCard attendance={attendance} />
            </SimpleGrid>
            <ThemePagination
              setPage={setActivePage}
              totalPages={pagedData.total}
            />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="timeLine" pt="xs">
          <Box>
            <Scheduler
              endDate={endDate}
              selectedStudent={userId}
              setEndDate={setEndDate}
              setSelectedStudent={() => []}
              setStartDate={setStartDate}
              startDate={startDate}
              selectStudent={false}
            />
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="feedback" pt="xs">
          <Box>
            <Feedback
              endDate={endDate}
              selectedStudent={userId}
              setSelectedStudent={() => {}}
              setYear={() => []}
              startDate={startDate}
              selectStudent={false}
            />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="taskReport" pt="xs">
          <Box mt={10}>
            <Report
              endDate={endDate}
              selectedStudent={userId}
              setEndDate={setEndDate}
              setSelectedStudent={() => []}
              setStartDate={setStartDate}
              startDate={startDate}
              selectStudent={false}
            />
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="empWork" pt="xs">
          <Box mt={10}>
            <SingleEmpWorkDetail
              endDate={endDate}
              startDate={startDate}
              selectedStudent={userId}
            />
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default InfoCard;
