import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { TStoryValues } from "../../../form/initial-value/story.values";

const AddStory = async (data: TStoryValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.story.CREATE_STORY,
    method: "POST",
    data: data,
  });
  return response;
};

export const useAddStoryMutation = () => {
  return useMutation(AddStory);
};
