import React, { useState } from "react";
import { Text, ActionIcon, Popover } from "@mantine/core";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";
import { IconInfoCircle } from "@tabler/icons-react";
import moment from "moment";
const STATUS_ORDER = [
  "absent",
  "initiate",
  "late",
  "on time",
  "remote",
  "leave",
];
export const ATTENDANCE_COLUMNS: TTableColumns[] = [
  {
    key: "name",
    header: "User Name",
    renderCell: (row) => {
      const { name, studentID } = row;
      const url = generateTaskReportUrl(studentID ?? "");
      return (
        <Text color={"#000"} weight={"500"} component={Link} to={url}>
          {name}
        </Text>
      );
    },
  },
  {
    key: "status",
    header: "Status",
    sortMethod: (a: string, b: string) => {
      const indexA = STATUS_ORDER.indexOf(a);
      const indexB = STATUS_ORDER.indexOf(b);
      return indexA - indexB;
    },
    renderCell: (row) => {
      const { status } = row;
      return <Text>{status}</Text>;
    },
  },
  {
    key: "inTime",
    header: "In-Time",
    renderCell: (row) => {
      const { inTime, inNote } = row;
      const formattedInTime = inTime ? moment(inTime).format("HH:mm:ss") : "NA";
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text>{formattedInTime}</Text>
          {inNote && <PopoverComponent note={inNote} />}
        </div>
      );
    },
  },
  {
    key: "outTime",
    header: "Out-Time",
    renderCell: (row) => {
      const { outTime, outNote } = row;
      const formattedOutTime = outTime
        ? moment(outTime).format("HH:mm:ss")
        : "NA";
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text>{formattedOutTime}</Text>
          {outNote && <PopoverComponent note={outNote} />}
        </div>
      );
    },
  },
  {
    key: "totalOfficeTime",
    header: "Total Office Time",
    renderCell: (row) => {
      const { inTime, outTime } = row;
      let totalTime = "NA";
      if (inTime) {
        const inMoment = moment(inTime);
        if (outTime) {
          const outMoment = moment(outTime);
          const duration = moment.duration(outMoment.diff(inMoment));
          totalTime = `${duration.hours()}h ${duration.minutes()}m`;
        } else {
          const nowMoment = moment();
          const duration = moment.duration(nowMoment.diff(inMoment));
          totalTime = `${duration.hours()}h ${duration.minutes()}m`;
        }
      }
      return <Text>{totalTime}</Text>;
    },
  },
];
const PopoverComponent = ({ note }: { note: string }) => {
  const [opened, setOpened] = useState(false);
  return (
    <Popover
      width={300}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
    >
      <Popover.Target>
        <ActionIcon
          color="blue"
          size="sm"
          style={{ marginLeft: 8 }}
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
        >
          <IconInfoCircle />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: "none" }}>
        <Text size="sm">{note}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};
