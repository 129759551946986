import { ActionIcon, Card, Flex, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React from "react";
import { useDeleteCategoryMutation } from "../../../../hooks/leave/mutation/deleteCategory";
import { notifications } from "@mantine/notifications";
import { queryClient } from "../../../..";

interface IProps {
  name: string;
  id: string;
}

export const CategoryTile: React.FC<IProps> = ({ name, id }) => {
  const { mutateAsync: deleteCategory } = useDeleteCategoryMutation();

  const handleDelete = async () => {
    const res = await deleteCategory(id);
    if (res.status === "success") {
      await queryClient.invalidateQueries("categories");
      notifications.show({
        title: "Category Deleted",
        message: "Category Deleted Successfully",
      });
    } else {
      notifications.show({
        title: res.title,
        message: res.message,
        color: "red",
      });
    }
  };

  return (
    <Card
      dir={"row"}
      my={5}
      p={10}
      shadow="sm"
      bg="#fafafa"
      withBorder
      style={{ borderLeftColor: "#ff008a", borderWidth: 0, borderLeftWidth: 4 }}
    >
      <Flex direction={"row"} justify={"space-between"} align={"center"}>
        <Text tt={"capitalize"}>{name}</Text>
        <ActionIcon onClick={handleDelete}>
          <IconTrash color="red" size={20} />
        </ActionIcon>
      </Flex>
    </Card>
  );
};
