import React, { useState } from "react";
import { Box, createStyles } from "@mantine/core";
import { COLORS } from "../../../assets/colors/index.colors";
import Report from "./components/Report";
import moment from "moment";
import { useLocation } from "react-router-dom";

const TaskReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const { classes } = useStyles();
  const inputDate = moment(new Date()).startOf("day").toDate();
  const [startDate, setStartDate] = useState<Date>(inputDate);
  const endDates = moment(new Date()).endOf("day").toDate();
  const [endDate, setEndDate] = useState<Date>(endDates);
  const [selectedStudent, setSelectedStudent] = useState<string>();

  return (
    <Box className={classes.root}>
      <Report
        endDate={endDate}
        selectedStudent={selectedStudent}
        setEndDate={setEndDate}
        setSelectedStudent={setSelectedStudent}
        setStartDate={setStartDate}
        startDate={startDate}
        defaultSelectedStudent={id ?? ""}
      />
    </Box>
  );
};

export default TaskReport;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
}));
