import { Box, Chip, Flex, Stack, Text, Button } from "@mantine/core";
import { IconClockHour4, IconPlus } from "@tabler/icons-react";
import { openTaskLink } from "../helper/openTaskLink";
import React from "react";
import { openTableModal } from "../modals/table-modal/openTableModal";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";

export const PROJECT_TASK_COLUMNS: TProjectTaskColumns[] = [
  {
    key: "empName",
    minWidth: 200,
    header: "Employee Name",
    renderCell: (row: EmployeeWorkData) => {
       const url = generateTaskReportUrl(row.employeeId ??"");
      return (
        <div>
          <Text color={"#000"} fw={500} size="sm" component={Link} to={url}>
            {row.empName}
          </Text>
          <Text color={"#000"} fw={500} size="sm">
            {row.empEmail}
          </Text>

          <Box mt={4}>
            <Text span color="gray" size="xs">
              Total Hours :{" "}
            </Text>
            <Text span c="blue" inherit>
              {parseFloat(row.totalWorkHours.toString()).toFixed(2)} h
            </Text>
          </Box>
        </div>
      );
    },
  },

  {
    key: "tasks",
    header: "All Tasks",
    renderCell(row: EmployeeWorkData) {
      const showMore = row.tasks.length > 5;
      const visibleTasks = row.tasks.slice(0, 5);
      const hiddenTasks = row.tasks.slice(5);

      return (
        <>
          <Flex justify={"flex-start"} wrap={"wrap"} gap="sm">
            {visibleTasks.map((item, index) => (
              <Chip
                key={index}
                checked={false}
                size="lg"
                styles={{ label: { padding: "24px 0px" } }}
              >
                <a
                  href={openTaskLink(Number(item.taskId))}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    padding: "0px 24px",
                  }}
                >
                  <Stack align="flex-start" spacing={0}>
                    <Text size="sm" weight={500} color="#5e5e5e">
                      {`Task: ${item.taskId}`}
                    </Text>
                    <Flex align="center" gap={4}>
                      <IconClockHour4 stroke={2} size={14} color="#868e96" />
                      <Text size="sm" c="blue" weight={500}>
                        {parseFloat(item.spendTime.toString()).toFixed(2)} h
                      </Text>
                    </Flex>
                  </Stack>
                </a>
              </Chip>
            ))}

            {showMore && (
              <Button
                size="xs"
                variant="light"
                leftIcon={<IconPlus size={16} />}
                onClick={() => openTableModal(row, hiddenTasks)}
              >
                More
              </Button>
            )}
          </Flex>
        </>
      );
    },
  },
];
