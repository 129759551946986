import { Box, createStyles } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { COLUMNS } from "../../../columns";
import CustomTable from "../../../component/table";
import { COLORS } from "../../../assets/colors/index.colors";
import { useGetAllTaskStatus } from "../../../hooks/task-status/query/getAllTaskStatus.query";
import { CONSTANTS } from "../../../constant";

const TaskStatus = () => {
  const { classes } = useStyles();
  const tableColumns = [...COLUMNS.taskStatus];
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });

  const { data, isLoading } = useGetAllTaskStatus({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    searchParams: {
      search,
    },
  });

  const taskStatus: ITaskStatus[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box className={classes.root}>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={taskStatus}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
        }}
      />
    </Box>
  );
};

export default TaskStatus;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
  hoursContainer: {
    borderRadius: 6,
    border: "1px solid #ced4da",
    padding: "8px 16px",
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
  dateInput: {
    borderWidth: 0,
    padding: 0,
    cursor: "pointer",
    fontWeight: 500,
  },
  dateInputContainer: {
    border: "1px solid #757373",
    padding: "0px 8px",
    borderRadius: 6,
  },
}));
