import React from "react";
import { Box, Chip, Flex, Stack, Text } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import { openProjectLink } from "../helper/openProjectLink";
import moment from "moment";
import { getSlotBackgroundColor } from "../helper/timeline/getSlotBackgroundColor";
import { getEmptySlotText } from "../helper/timeline/getEmptySlotText";
import { openTaskLink } from "../helper/openTaskLink";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";

export const EMP_WORK_COLUMNS: TEmpWorkTableColumns[] = [
  {
    key: "employee",
    header: "Name",
    renderCell: (row: TAttendanceRecord) => {
      const { leaveDate, startDate, isHoliday, absentData } = row;
      const employeeId = row[0]?._id;

      const leaveEntry = leaveDate?.data?.find(
        (leave: LeaveEntry) =>
          leave.studentId === employeeId &&
          leave.status === "accepted" &&
          leave.dates.some((leaveDate: moment.MomentInput) =>
            moment(leaveDate).isSame(startDate, "day")
          )
      );
      const leaveType = leaveDate?.data?.find(
        (leave: LeaveEntry) =>
          leave.studentId === employeeId &&
          leave.status === "accepted" &&
          leave.type === "halfday"
      );

      const isLeaveDay = !!leaveEntry;

      const absentEntry = absentData?.data?.find(
        (absent: AbsentEntry) =>
          absent.studentID === employeeId &&
        (absent.status === "absent" || absent.status === "initiate") &&
          moment(absent.createdAt).isSame(startDate, "day")
      );

      const isAbsent = !!absentEntry;

      const slotBackgroundColor = getSlotBackgroundColor(
        startDate,
        isLeaveDay,
        isHoliday,
        isAbsent
      );

      const userStatusText = getEmptySlotText(
        startDate,
        isLeaveDay,
        isHoliday,
        isAbsent
      );

      const attendanceEntries = Object.entries(row)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([, value]) => value as AttendanceData);

      const employeeName =
        attendanceEntries.length > 0
          ? attendanceEntries[0].employee
          : "No Employee";

      const hasProjects = attendanceEntries.some(
        (project) => project.project_name
      );

      const totalSpendTime = attendanceEntries.reduce((acc, project) => {
        return acc + (parseFloat(project.spend_time || "0") || 0);
      }, 0);

       const url = generateTaskReportUrl(employeeId ??"");

      return (
        <div>
          <Text color={hasProjects ? "#000" : "red"} fw={500} size="sm" component={Link} to={url}>
            {employeeName}
          </Text>
          {totalSpendTime !== 0 && (
            <Box mt={4}>
              <Text span color="gray" size="xs">
                Total Hours :{" "}
              </Text>
              <Text span c="blue" inherit>
                {totalSpendTime.toFixed(2)} h
              </Text>
            </Box>
          )}
          {userStatusText !== "" && (
            <Box mt={10}>
              <span
                style={{
                  fontWeight: "600",
                  color: "white",
                  padding: "4px 12px",
                  fontSize: 10,
                  borderRadius: 8,
                  background: slotBackgroundColor,
                }}
              >
                {userStatusText}
              </span>
              <Text
                span
                c="blue"
                size={"xs"}
                px={5}
                style={{ textTransform: "capitalize", display: "inline-block" }}
              >
                {leaveType?.type}
              </Text>
            </Box>
          )}
        </div>
      );
    },
  },
  {
    key: "projectId",
    header: "Projects & Time",
    renderCell: (row: TAttendanceRecord) => {
      const attendanceEntries = Object.entries(row)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([, value]) => value as AttendanceData);

      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
          {attendanceEntries.map((project, index) => (
            <Box key={project._id + index}>
              {project.project_name && (
                <Chip
                  checked={false}
                  size="lg"
                  styles={{ label: { padding: "24px 0px" } }}
                >
                  <a
                    href={openProjectLink(Number(project._id))}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      padding: "0px 24px",
                    }}
                  >
                    <Stack align="flex-start" spacing={0}>
                      <Text size="sm" weight={500} color="#5e5e5e">
                        {project.project_name}
                      </Text>
                      <Flex align="center" gap={4}>
                        <IconClockHour4 stroke={2} size={14} color="#868e96" />
                        <Text
                          size="sm"
                          c="blue"
                          style={{ marginRight: 8 }}
                          weight={500}
                        >
                          {parseFloat(project.spend_time || "0").toFixed(2)} h
                        </Text>
                      </Flex>
                    </Stack>
                  </a>
                </Chip>
              )}
            </Box>
          ))}
        </Flex>
      );
    },
  },
  {
    key: "task_id",
    header: "Tasks & Time",
    renderCell: (row: TAttendanceRecord) => {
      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
          {row.taskData[0].map((task, index) => (
            <Box key={task.task_id + index}>
              {task.task_id && (
                <Chip
                  checked={false}
                  size="lg"
                  styles={{ label: { padding: "24px 0px" } }}
                >
                  <a
                    href={openTaskLink(Number(task.task_id))}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      padding: "0px 24px",
                    }}
                  >
                    <Stack align="flex-start" spacing={0}>
                      <Text size="sm" weight={500} color="#5e5e5e">
                        Task #{task.task_id}
                      </Text>
                      <Flex align="center" gap={4}>
                        <IconClockHour4 stroke={2} size={14} color="#868e96" />
                        <Text
                          size="sm"
                          c="blue"
                          style={{ marginRight: 8 }}
                          weight={500}
                        >
                          {parseFloat(task.spend_time.toString()).toFixed(2)} h
                        </Text>
                      </Flex>
                    </Stack>
                  </a>
                </Chip>
              )}
            </Box>
          ))}
        </Flex>
      );
    },
  },
];
