import React from "react";
import Dashboard from "../pages/dashboard/Index";
import ForgotPass from "../pages/forgot-password/Index";
import Login from "../pages/logIn/Index";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "../protected-route/ProtectedRoute";
import ResetPassword from "../pages/reset-password/Index";
import ErrorPage from "../pages/error-page/Index";
import AllStudentAttendance from "../pages/all-student-attendance/Index";
import StudentAttendance from "../pages/student-attendance/Index";
import Settings from "../pages/settings";
import Holiday from "../pages/holidays";
import Employees from "../pages/employees/Index";
import { WorkLog } from "../pages/notes";
import EmployFeedback from "../pages/employ-feedback/EmployFeedback";
import Projects from "../pages/works/projects/Projects";
import EmployMapping from "../pages/works/employ-mapping/EmployMapping";
import TaskStatus from "../pages/works/task-status/TaskStatus";
import { LeaveManagement } from "../pages/leave/leave-management";
import LeavePolicy from "../pages/leave/leave-policy/LeavePolicy";
import EmpWork from "../pages/works/emp-work/EmpWork";
import ProjectWork from "../pages/works/project-work/ProjectWork";
import TaskReport from "../pages/works/task-report/TaskReport";
import Timeline from "../pages/works/timeline/Timeline";
import Story from "../pages/works/story/Story";
import Sprint from "../pages/works/sprint/Sprint";
import ProjectStory from "../pages/works/project-story/ProjectStory";
import ProjectSprint from "../pages/works/project-sprint/ProjectSprint";
import TodayAttendance from "../pages/hr/today-attendance/TodayAttendance";
import WorkLogApi from "../pages/apis/work-log-api/WorkLogApi";
import ProjectApi from "../pages/apis/project-api/ProjectsApi";
import SprintApi from "../pages/apis/sprint-api/SprintApi";
import EmailSend from "../pages/email-send/EmailSend";
import NotificationSend from "../pages/notification-send/NotificationSend";
import EmailSendApi from "../pages/apis/email-send-api/EmailSendApi";
import NotificationLogs from "../pages/notifications-logs/notification-logs/NotificationLogs";
import EmailSendLogs from "../pages/notifications-logs/email-send-logs/EmailSendLogs";
import NotificationSendLogs from "../pages/notifications-logs/notification-send-logs/NotificationSendLogs";
import EmpPoint from "../pages/hr/emp-point/EmpPoint";
import ProjectInfo from "../pages/project-info/ProjectInfo";
import WorkingTasks from "../pages/works/working-tasks/WorkingTasks";
import WorkHourLoss from "../pages/work-hour-loss/WorkHourLoss";
import EmpProjectHours from "../pages/works/emp-project-hours/EmpProjectHours";
import ProjectWorkHours from "../pages/works/project-work-hours/ProjectWorkHours";
import ProjectTaskMonthWise from "../pages/project-info/ProjectTaskMonthWise";
import Officeloss from "../pages/office-hours/Officeloss";
import SprintLogs from "../pages/notifications-logs/sprint-logs/SprintLogs";
import StoryLogs from "../pages/notifications-logs/story-logs/StoryLogs";
import StoryApi from "../pages/apis/story-api/SprintApi";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "/something-went-wrong",
    element: (
      <ErrorPage
        errorCode={501}
        errorTitle="Something Went Wrong"
        errorMessage="We encounter some internal issue, Please try again later."
      />
    ),
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "today-attendance",
        element: <TodayAttendance />,
      },
      {
        path: "employees",
        element: <Employees />,
      },
      {
        path: "attendance",
        element: <AllStudentAttendance />,
      },
      {
        path: "employ-detail/:userId",
        element: <StudentAttendance />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "holidays",
        element: <Holiday />,
      },
      {
        path: "worklog",
        element: <WorkLog />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "leave",
        element: <LeaveManagement />,
      },
      {
        path: "employ-feedback",
        element: <EmployFeedback />,
      },
      {
        path: "employ-mapping",
        element: <EmployMapping />,
      },
      {
        path: "task-report",
        element: <TaskReport />,
      },
      {
        path: "notification-logs",
        element: <NotificationLogs />,
      },
      {
        path: "timeline",
        element: <Timeline />,
      },

      {
        path: "project-api",
        element: <ProjectApi />,
      },
      {
        path: "task-status",
        element: <TaskStatus />,
      },
      {
        path: "leave-policy",
        element: <LeavePolicy />,
      },
      {
        path: "employee-work",
        element: <EmpWork />,
      },
      {
        path: "project-work",
        element: <ProjectWork />,
      },
      { path: "project-work-hours", element: <ProjectWorkHours /> },
      {
        path: "sprint",
        element: <Sprint />,
      },
      {
        path: "story",
        element: <Story />,
      },
      {
        path: "project-story/:projectId",
        element: <ProjectStory />,
      },
      {
        path: "project-sprint/:projectId",
        element: <ProjectSprint />,
      },
      {
        path: "work-log-api",
        element: <WorkLogApi />,
      },
      {
        path: "sprint-api",
        element: <SprintApi />,
      },
      {
        path: "email-send",
        element: <EmailSend />,
      },
      {
        path: "email-send-logs",
        element: <EmailSendLogs />,
      },
      {
        path: "notification-send",
        element: <NotificationSend />,
      },
      {
        path: "notification-send-logs",
        element: <NotificationSendLogs />,
      },
      {
        path: "email-send-api",
        element: <EmailSendApi />,
      },
      {
        path:"office-hours-loss",
        element: <Officeloss/>
      },
      {
        path: "employee-point",
        element: <EmpPoint />,
      },
      {
        path: "/employee-project-hours/:id",
        element: <EmpProjectHours />,
      },
      {
        path: "/employee-project-hours",
        element: <EmpProjectHours />,
      },
      {
        path: "project-info/:id",
        element: <ProjectInfo />,
      },
      { path: "working-tasks", element: <WorkingTasks /> },
      {
        path: "employee/work-hour-loss",
        element: <WorkHourLoss />,
      },
      {
        path: "project-task",
        element: <ProjectTaskMonthWise />,
      },
      {
        path: "sprint-logs",
        element: <SprintLogs />,
      },
      {
        path: "story-logs",
        element: <StoryLogs />,
      },
      {
        path: "story-api",
        element: <StoryApi />,
      },
    ],
  },
  {
    path: "/forgot-password",
    element: <ForgotPass />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
