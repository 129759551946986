import React, { useMemo } from "react";
import { useGetSprintApi } from "../../../hooks/project-api/query/getSprintApi.query";
import SprintApiInterface from "./components/SprintApiInterface";

const SprintApi = () => {
  const { data, isLoading } = useGetSprintApi();

  const sprint: TApiDocs[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data.map((project: TApiDocs) => ({
        ...project,
      }));
    }
    return [];
  }, [isLoading, data]);

  return <SprintApiInterface data={sprint} />;
};

export default SprintApi;
