import React, { memo, useCallback, useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { useUpdateRequestMutation } from "../../../../hooks/leave/mutation/updateRequest";
import { queryClient } from "../../../..";
import { notifications } from "@mantine/notifications";
import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { IconCheckbox, IconSquareRoundedX } from "@tabler/icons-react";

interface Props {
  leaveId: string;
  disabled?: boolean;
}

const ActionBtn: React.FC<Props> = ({ leaveId, disabled }) => {
  const [title, setTitle] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const { isLoading: isUpdateLoading, mutateAsync: updateRequest } =
    useUpdateRequestMutation();

  const handleFormSubmit = useCallback(
    async (status: string) => {
      const res = await updateRequest({ status: status, leaveId: leaveId });
      if (res.status === "success") {
        await queryClient.invalidateQueries("leaves-monthly");
        await queryClient.invalidateQueries("pending-request");
        notifications.show({
          color: "green",
          message: res.message,
        });
      }
    },
    [updateRequest]
  );
  return (
    <div>
      <Group noWrap>
        <Tooltip label="Accept">
          <ActionIcon
            variant="outline"
            color="green"
            onClick={async (e) => {
              e.stopPropagation();
              setTitle("accepted");
              setVisible(true);
            }}
            disabled={disabled}
            loading={isUpdateLoading}
          >
            <IconCheckbox />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Reject">
          <ActionIcon
            variant="outline"
            color="red"
            onClick={async (e) => {
              e.stopPropagation();
              setTitle("rejected");
              setVisible(true);
            }}
            disabled={disabled}
            loading={isUpdateLoading}
          >
            <IconSquareRoundedX size={20} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <ConfirmationModal
        visible={visible}
        onClose={() => setVisible(false)}
        title={title}
        onContinue={async () => {
          if (title) await handleFormSubmit(title);
        }}
      />
    </div>
  );
};

export default memo(ActionBtn);
