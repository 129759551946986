import {
  ActionIcon,
  Box,
  createStyles,
  Flex,
  Group,
  rem,
  Select,
  Text,
} from "@mantine/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { COLUMNS } from "../../../../columns";
import { useGetAllStudent } from "../../../../hooks/students/query/getAllStudent.query";
import { useGetAllTaskSpendTime } from "../../../../hooks/reports/query/getAllTaskSpendTime.query";
import { useGetAllHoliday } from "../../../../hooks/holiday/query/getAllHoliday.query";
import { useGetAllLeaveDate } from "../../../../hooks/reports/query/getAllLeaveDate.query";
import CustomTable from "../../../../component/table";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { MonthPickerInput } from "@mantine/dates";
import moment from "moment";
import { calculateHours } from "../../task-report/helper/calculateHours";
import { updateMonth } from "../../../../helper/timeline/updateMonth";
import { eachDayOfInterval, startOfDay } from "date-fns";
import { useGetSingleEmpProjectWork } from "../../../../hooks/emp-work/query/getSingleEmpProjectHour";
import { useParams } from "react-router-dom";
import { useGetAllEmployeeAbsentAttendance } from "../../../../hooks/all-student-attendance/query/useAllEmployeeAbsentAttendance.query";

interface IReport {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedStudent: string | undefined;
  setSelectedStudent: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectStudent?: boolean;
}

const EmpProjectTable: FC<IReport> = ({
  endDate,
  selectedStudent,
  setEndDate,
  setSelectedStudent,
  setStartDate,
  startDate,
  selectStudent = true,
}) => {
  const { classes } = useStyles();
  const inputDate = moment(new Date()).startOf("day").toDate();
  const [date, setDate] = useState<Date | null>(inputDate);
  const tableColumns = [...COLUMNS.monthlyProjectHour];
  const [activePage, setActivePage] = useState(1);
  const { id } = useParams<{ id?: string }>(); 
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);
  useEffect(() => {
    if (id) {
      setSelectedStudent(id);
    } else if (students.length > 0 && !selectedStudent) {
      setSelectedStudent(students[0].value);
    }
  }, [id, students]);
  
  const { data: spendTime } = useGetAllTaskSpendTime({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });
  
  const daysInMonth = eachDayOfInterval({
      start: startDate as Date,
      end: endDate as Date,
    }).reverse();
  useEffect(() => {
    if (students.length > 0 && !selectedStudent) {
      setSelectedStudent(students[0].value);
    }
  }, [students]);
  const { data: empworkdata, isLoading: empWOrkLoading } =
    useGetSingleEmpProjectWork({
      startDate: startDate ?? new Date(),
      endDate: endDate ?? new Date(),
      searchParams: {
        search: selectedStudent ?? "",
      },
    });

  const EmpWork = useMemo(() => {
    if (!empWOrkLoading && empworkdata?.data) {
      if (empworkdata.pageData) {
        setPagedData(empworkdata.pageData);
      }

      return empworkdata.data;
    }

    return [];
  }, [empWOrkLoading, empworkdata]);

  const { data: allHoliday } = useGetAllHoliday();
  const { data: absentData } = useGetAllEmployeeAbsentAttendance({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    studentId: selectedStudent ?? "",
  });
  const { data: leaveDate } = useGetAllLeaveDate({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });
  const handleDateChange = (val: Date | null) => {
    if (val) {
      setDate(val);
      setStartDate(moment(val).startOf("month").toDate());
      setEndDate(moment(val).endOf("month").toDate());
    }
  };
  const calculatedHours = useMemo(
    () =>
      calculateHours(
        startDate,
        endDate,
        allHoliday?.data,
        leaveDate?.data?.map((leave) => ({
          dates: leave.dates,
          type: leave.type,
          status: leave.status,
        })) ?? []
      ),
    [startDate, endDate, allHoliday?.data, leaveDate?.data]
  );

  const mergedData = useMemo(() => {
    const filteredHolidays = allHoliday?.data?.filter((holiday) => {
      const holidayDate = moment(holiday.date).format("DD MMM YYYY");
      return moment(holidayDate).isBetween(moment(startDate).format("DD MMM YYYY"), moment(endDate).format("DD MMM YYYY"), undefined, '[]');
    }) ?? [];
    return daysInMonth.map((day) => {
      const formattedDate = moment(day).format("DD MMM YYYY");
  
      const workEntry = EmpWork.find(
        (entry) => moment(entry.date).format("DD MMM YYYY") === formattedDate
      );
  
      const wasAbsent = absentData?.data?.some(
        (absent) => moment(absent.createdAt).format("DD MMM YYYY") === formattedDate
      );
  
      const leaveEntry = leaveDate?.data?.find(
        (leave) => moment(leave.createdAt).format("DD MMM YYYY") === formattedDate
      );
  
      const isHoliday = filteredHolidays.find(
        (holiday) => moment(holiday.date).format("DD MMM YYYY") === formattedDate
      );
      
      return {
        date: formattedDate,
        projects: workEntry?.projects || [],
        tasks: workEntry?.tasks || [],
        hoursWorked: workEntry?.totalWork || 0,
        status: isHoliday
          ? `Holiday`
          : leaveEntry
          ? `On Leave (${leaveEntry.type})`
          : wasAbsent
          ? "Absent"
          : "Present",
      };
    });
  }, [daysInMonth, EmpWork, absentData, leaveDate, allHoliday,startDate,endDate]);
  
  
  return (
    <Box>
      <Group position="apart" style={{ marginBottom: 20 }}>
        {selectStudent && (
          <Group>
            <Select
              data={[...students]}
              value={selectedStudent}
              onChange={(value) => {
                if (value) setSelectedStudent(value);
              }}
              searchable
            />
            <Flex align={"center"} mr={6}>
              <ActionIcon
                onClick={() => {
                  updateMonth(-1, setStartDate, setEndDate);
                  setDate((prevDate) =>
                    moment(prevDate).subtract(1, "month").toDate()
                  );
                }}
                size={"lg"}
                variant="light"
                bg={"white"}
                mr={3}
              >
                <IconChevronLeft size={16} color="black" />
              </ActionIcon>
              <MonthPickerInput
                maxDate={new Date()}
                styles={{ input: { border: "none" } }}
                icon={
                  <IconCalendar
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                placeholder="Pick month"
                value={date}
                onChange={handleDateChange}
              />
              <ActionIcon
                disabled={(endDate as Date) >= startOfDay(new Date())}
                onClick={() => {
                  updateMonth(1, setStartDate, setEndDate);
                  setDate((prevDate) =>
                    moment(prevDate).add(1, "month").toDate()
                  );
                }}
                size={"lg"}
                variant="light"
                bg={"white"}
                ml={3}
              >
                <IconChevronRight size={16} color="black" />
              </ActionIcon>
            </Flex>
          </Group>
        )}

        {selectedStudent !== "All" && (
          <Group className={classes.hoursContainer}>
            <Text size={"sm"} fw={400}>
              Hours :{" "}
              <span style={{ fontWeight: 500 }}>{calculatedHours}h</span>
            </Text>

            <Text size={"sm"} fw={400}>
              Spend: <span style={{ fontWeight: 500 }}>{spendTime?.data}H</span>
            </Text>
          </Group>
        )}
      </Group>

      <CustomTable
        isDashboardTable={false}
        loading={empWOrkLoading}
        columns={tableColumns}
        data={mergedData}
        grid={false}
        paginationProps={{
          active: activePage,
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
    </Box>
  );
};

export default EmpProjectTable;

const useStyles = createStyles(() => ({
  hoursContainer: {
    borderRadius: 6,
    border: "1px solid #ced4da",
    padding: "8px 16px",
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
  dateInput: {
    borderWidth: 0,
    padding: 0,
    cursor: "pointer",
    fontWeight: 500,
  },
  dateInputContainer: {
    border: "1px solid #757373",
    padding: "0px 8px",
    borderRadius: 6,
  },
}));
