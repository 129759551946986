import { resetValidationSchema } from "./Reset.validation";
import { AddFeedbackValidationSchema } from "./addFeedback.validations";
import { AddProjectsValidationSchema } from "./addProjects.validations";
import { AddStudentValidationSchema } from "./addStudent.validations";
import { loginValidationSchema } from "./login.validations";
import { forgotPasswordSchema } from "./resetPassword.validations";
import { settingsValidationSchema } from "./settings.validations";
import { AddEmpMappingValidationSchema } from "./addEmpMapping.validation";
import { AddStoryValidationSchema } from "./addStory.validations";
import { AddSprintValidationSchema } from "./addSprint.validations";
import { AddEmpPointValidationSchema } from "./addEmpPoint.validations";

export const validations = {
  login: loginValidationSchema,
  forgotPassword: forgotPasswordSchema,
  reset: resetValidationSchema,
  addStudent: AddStudentValidationSchema,
  settings: settingsValidationSchema,
  projects: AddProjectsValidationSchema,
  feedback: AddFeedbackValidationSchema,
  empMapping: AddEmpMappingValidationSchema,
  story: AddStoryValidationSchema,
  sprint: AddSprintValidationSchema,
  empPoint:AddEmpPointValidationSchema
};
