import { Box, createStyles } from "@mantine/core";
import React, { useState } from "react";
import { COLORS } from "../../../assets/colors/index.colors";
import moment from "moment";
import ProjectWorkHoursTable from "./components/ProjectWorkHoursTable";

const ProjectWorkHours = () => {
  const { classes } = useStyles();
  const inputDate = moment(new Date()).startOf("month").toDate();
  const endDates = moment(new Date()).endOf("month").toDate();
  const [startDate, setStartDate] = useState<Date>(inputDate);
  const [endDate, setEndDate] = useState<Date>(endDates);

  return (
    <Box className={classes.root}>
      <ProjectWorkHoursTable
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
      />
    </Box>
  );
};

export default ProjectWorkHours;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
    borderRadius: 5,
  },
}));
