import moment from "moment";
import { startOfDay } from "date-fns";

export const updateDateRange = (
  direction: "prev" | "next",
  startDate: Date | null,
  dateType: string | null,
  setStartDate: (value: React.SetStateAction<Date>) => void,
  setEndDate: (value: React.SetStateAction<Date>) => void
) => {
  if (!startDate) return;

  const today = startOfDay(new Date());
  const multiplier = direction === "prev" ? -1 : 1;

  let newStartDate = moment(startDate);
  let newEndDate = moment(startDate);

  if (dateType === "Day") {
    newStartDate.add(multiplier, "days");
    newEndDate.add(multiplier, "days");
  } else if (dateType === "Week") {
    newStartDate.add(multiplier, "weeks").startOf("week");
    newEndDate = moment(newStartDate).endOf("week");
  } else if (dateType === "Month") {
    newStartDate.add(multiplier, "months").startOf("month");
    newEndDate = moment(newStartDate).endOf("month");
  } else if (dateType === "Custom") {
    newStartDate.add(multiplier, "days");
    newEndDate.add(multiplier, "days");
  }

  if (newEndDate.toDate() > today) {
    newEndDate = moment(today);
    newStartDate = moment(today).startOf(
      dateType === "Week" ? "week" : "month"
    );
  }

  setStartDate(newStartDate.toDate());
  setEndDate(newEndDate.toDate());
};
