export const statusColor = [
  {
    color: "#B3E5FC",
    id: 46,
    status: "New",
  },
  {
    color: "#C5E1A5",
    id: 47,
    status: "Open",
  },
  {
    color: "#FFCDD2",
    id: 76,
    status: "Code Review",
  },
  {
    color: "#C5CAE9",
    id: 71,
    status: "Developer Done",
  },
  {
    color: "#E1BEE7",
    id: 77,
    status: "Testing",
  },
  {
    color: "#FFE0B2",
    id: 79,
    status: "Ready For Live",
  },
  {
    color: "#DCEDC8",
    id: 49,
    status: "Deployed On Prod",
  },
  {
    color: "#B2DFDB",
    id: 50,
    status: "Closed",
  },
];
