import { Box, createStyles } from "@mantine/core";
import React, { useState } from "react";
import { COLORS } from "../../../assets/colors/index.colors";
import EmpProjectTable from "./components/EmpProjectTable";
import { endOfDay, endOfMonth, startOfMonth } from "date-fns";

const EmpProjectHours = () => {
  const { classes } = useStyles();
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
    const today = endOfDay(new Date());
    const [endDate, setEndDate] = useState<Date>(
      endOfMonth(new Date()) > today ? today : endOfMonth(new Date())
    );
const [selectedStudent, setSelectedStudent] = useState<string>();
  return (
    <Box className={classes.root}>
      <EmpProjectTable
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
      />
    </Box>
  );
};

export default EmpProjectHours;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
    borderRadius: 5,
  },
}));
