import {
  Box,
  createStyles,
  Grid,
  MultiSelect,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import { COLORS } from "../../assets/colors/index.colors";
import { useForm } from "@mantine/form";
import ThemeButton from "../../component/button/ThemeButton";
import { useGetAllStudent } from "../../hooks/students/query/getAllStudent.query";
import { notifications } from "@mantine/notifications";
import { useCreateNotificationSendMutation } from "../../hooks/notificationSend/mutation/CreateNotificationSend.mutation";

const NotificationSend = () => {
  const { classes } = useStyles();

  const [emailType, setEmailType] = useState("all");

  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });
  const { isLoading: createLoading, mutateAsync: createNotificationSend } =
    useCreateNotificationSendMutation();

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"] as string,
        label: student["email"] as string,
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const form = useForm({
    initialValues: {
      title: "",
      message: "",
      selectedUsers: [] as { value: string; label: string }[],
      emailType: emailType,
    },
    validate: {
      title: (value) =>
        value.trim().length === 0 ? "Title is required" : null,
      message: (value) =>
        value.length < 10 ? "Message must have at least 10 characters" : null,
      selectedUsers: (value, values) =>
        values.emailType === "selected" && value.length === 0
          ? "At least one user must be selected"
          : null,
    },
  });

  const handleFormSubmit = useCallback(
    async (values: TNotificationSend) => {
      const res = await createNotificationSend(values);
      if (res.status === "success") {
        form.reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [createNotificationSend, form.reset]
  );

  return (
    <Box className={classes.root}>
      <form onSubmit={form.onSubmit(handleFormSubmit)} autoComplete="off">
        <Grid mb={16}>
          <Grid.Col span={8}>
            <Select
              label="Select Employee Type"
              placeholder="Pick one"
              value={emailType}
              onChange={(value) => {
                setEmailType(value ?? "all");
                form.setFieldValue("emailType", value ?? "all");
              }}
              data={[
                { value: "all", label: "All Users" },
                { value: "selected", label: "Selected Users" },
              ]}
            />
            <Box>
              <TextInput
                placeholder="Please Enter Title"
                label="Title "
                classNames={{ root: classes.inputRoot }}
                {...form.getInputProps("title")}
              />
              <Box>
                <Text size={"sm"} color="#212529" fw={500}>
                  Message
                </Text>
                <Textarea
                  autosize
                  minRows={4}
                  {...form.getInputProps("message")}
                />

                <Box mt={16}>
                  <ThemeButton
                    title="Submit"
                    type="submit"
                    loading={createLoading}
                    disabled={createLoading}
                  />
                </Box>
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col span={4}>
            {emailType === "selected" && (
              <MultiSelect
                data={students}
                label="Choose specific email addresses."
                searchable
                value={form.values.selectedUsers.map((user) => user.value)}
                onChange={(values) => {
                  const selected = students.filter((student) =>
                    values.includes(student.value)
                  );
                  form.setFieldValue("selectedUsers", selected);
                }}
              />
            )}
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  );
};

export default NotificationSend;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
    borderRadius: 8,
  },
  inputRoot: {
    marginBottom: 16,
    marginTop: 16,
  },
}));
