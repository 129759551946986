import { IconBellPlus, IconMailForward } from "@tabler/icons-react";

const navBottomData = [
  { link: "/email-send", label: "Email Send", icon: IconMailForward },
  {
    link: "/notification-send",
    label: "Notification send",
    icon: IconBellPlus,
  },
];

export default navBottomData;
