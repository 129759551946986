import React from "react";
import { Modal, Text, Button, Group, Title, ActionIcon } from "@mantine/core";
import { IconX } from "@tabler/icons-react";

interface IConfirmationModal {
  visible: boolean;
  onClose: () => void;
  onContinue: () => void;
  title: string | null;
}

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  visible,
  onClose,
  onContinue,
  title,
}) => {
  const text = title === "accepted" ? "Accept" : "Reject";
  return (
    <Modal
      opened={visible}
      onClose={onClose}
      centered
      withCloseButton={false}
      radius="md"
    >
      <Group position="apart" mb="sm">
        <Title order={4}>Confirmation</Title>
        <ActionIcon onClick={onClose} size="lg">
          <IconX />
        </ActionIcon>
      </Group>

      <Text mb="md">Are you sure you want to {text} the request?</Text>

      <Group position="right" spacing="md">
        <Button variant="outline" color="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onContinue}
          // styles={{
          //   root: { backgroundColor: "#ff008a", borderColor: "#ff008a" },
          //   label: { color: "#fff" },
          // }}
          color="pink"
        >
          {text}
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmationModal;
