import moment from "moment";

export const calculateTotalOfficeTime = (inTime: string, outTime?: string) => {
    if (!inTime) return { totalTime: "N/A", isBelowThreshold: false };
  
    const inMoment = moment(inTime); 
    const outMoment = outTime ? moment(outTime) : moment(); 
  
    console.log("inMoment:", inMoment.format()); 
    console.log("outMoment:", outMoment.format());
  
    const duration = moment.duration(outMoment.diff(inMoment)); 
    const totalHours = duration.asHours(); 
    const totalTime = `${Math.floor(totalHours)}h ${duration.minutes()}m`;
  
    console.log("Total Hours:", totalHours);
  
    return { totalTime, isBelowThreshold: totalHours < 9 };
  };
