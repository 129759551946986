import React from "react";
import { Box, Chip, Flex, Stack, Text } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import { openProjectLink } from "../helper/openProjectLink";
import { dateFormat } from "../helper/dataFormat";

export const SINGLE_EMP_WORK_COLUMNS: TEmpMappingTableColumns[] = [
  {
    key: "createdAt",
    header: "Created At",
    renderCell: (row: TSingleEmpWork) => {
      const { date, projects } = row;
      const totalSpendTime = projects.reduce((acc, project) => {
        return acc + (project.spend_time || 0);
      }, 0);
      return (
        <div style={{ fontWeight: "600", color: "#333" }}>
          <Text span color="black" size="sm">
            {dateFormat(date)}
          </Text>

          <Box mt={4}>
            <Text span color="gray" size="xs">
              Total Hours :{" "}
            </Text>
            <Text span c="blue" inherit>
              {totalSpendTime.toFixed(2)} h
            </Text>
          </Box>
        </div>
      );
    },
  },

  {
    key: "projectId",
    header: "Projects & Time",
    renderCell: (row: TSingleEmpWork) => {
      const { projects } = row;

      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
          {projects.map((item) => (
            <Box key={item.project_id}>
              <Chip
                checked={false}
                size="lg"
                styles={{ label: { padding: "24px 0px" } }}
              >
                <a
                  href={openProjectLink(Number(item.project_id))}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    padding: "0px 24px",
                  }}
                >
                  <Stack align="flex-start" spacing={0}>
                    <Text size="sm" weight={500} color="#5e5e5e">
                      {item.project_name}
                    </Text>
                    <Flex align="center" gap={4}>
                      <IconClockHour4 stroke={2} size={14} color="#868e96" />
                      <Text
                        size="sm"
                        c="blue"
                        style={{ marginRight: 8 }}
                        weight={500}
                      >
                        {item.spend_time.toFixed(2)} h
                      </Text>
                    </Flex>
                  </Stack>
                </a>
              </Chip>
            </Box>
          ))}
        </Flex>
      );
    },
  },
];
