import React from "react";
import { Box, Chip, Flex, Stack, Text } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import { openProjectLink } from "../helper/openProjectLink";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";


export const PROJECT_WORK_HOURS_COLUMNS: TEmpWorkTableColumns[] = [
  {
    key: "employee",
    header: "Name",
    renderCell: (row: TAttendanceRecord) => {      
      

      const attendanceEntries = Object.entries(row)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([, value]) => value as AttendanceData);

      const employeeName =
        attendanceEntries.length > 0
          ? attendanceEntries[0].employee
          : "No Employee";


      const totalSpendTime = attendanceEntries.reduce((acc, project) => {
        return acc + (parseFloat(project.spend_time || "0") || 0);
      }, 0);
       const url = generateTaskReportUrl(attendanceEntries[0].employeeId ??"");

      return (
        <div>
          <Text color={"#000"} fw={500} size="sm" component={Link} to={url}>
            {employeeName}
          </Text>
          {totalSpendTime !== 0 && (
            <Box mt={4}>
              <Text span color="gray" size="xs">
                Total Hours :{" "}
              </Text>
              <Text span c="blue" inherit>
                {totalSpendTime.toFixed(2)} h
              </Text>
            </Box>
          )}
        </div>
      );
    },
  },
  {
    key: "projectId",
    header: "Projects & Time",
    renderCell: (row: TAttendanceRecord) => {
      const attendanceEntries = Object.entries(row)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([, value]) => value as AttendanceData);

      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
          {attendanceEntries.map((project, index) => (
            <Box key={project._id + index}>
              {project.project_name && (
                <Chip
                  checked={false}
                  size="lg"
                  styles={{ label: { padding: "24px 0px" } }}
                >
                  <a
                    href={openProjectLink(Number(project._id))}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      padding: "0px 24px",
                    }}
                  >
                    <Stack align="flex-start" spacing={0}>
                      <Text size="sm" weight={500} color="#5e5e5e">
                        {project.project_name}
                      </Text>
                      <Flex align="center" gap={4}>
                        <IconClockHour4 stroke={2} size={14} color="#868e96" />
                        <Text
                          size="sm"
                          c="blue"
                          style={{ marginRight: 8 }}
                          weight={500}
                        >
                          {parseFloat(project.spend_time || "0").toFixed(2)} h
                        </Text>
                      </Flex>
                    </Stack>
                  </a>
                </Chip>
              )}
            </Box>
          ))}
        </Flex>
      );
    },
  },
];
