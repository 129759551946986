import React, { FC, memo } from "react";
import {
  Box,
  Text,
  Group,
  Divider,
  Indicator,
  Grid,
  createStyles,
} from "@mantine/core";
import {
  IconBell,
  IconMail,
  IconClock,
  IconMessage,
} from "@tabler/icons-react";
import moment from "moment";

interface INotificationCardItem {
  item: {
    type: string;
    sentAt: string;
    recipientEmail: string;
    message: string;
    status: string;
  };
}

const NotificationCardItem: FC<INotificationCardItem> = ({ item }) => {
  const { classes } = useStyles();

  const getStatusStyle = () => {
    switch (item.status) {
      case "SENT":
        return { color: "#4CAF50" };
      case "FAILED":
        return { color: "#E53935" };
      default:
        return { color: "#FFA000" };
    }
  };

  return (
    <Grid.Col span={4}>
      <Box className={classes.card}>
        <Group position="apart">
          <Group spacing="xs" align="center">
            <IconBell size={20} color="#007BFF" />
            <Text fz="md" fw={600} c="dark">
              {item.type}
            </Text>
          </Group>

          <Group spacing="xs" align="center">
            <Indicator
              inline
              size={10}
              position="middle-start"
              color={getStatusStyle().color}
              zIndex={20}
            >
              <Text ml={10} size="xs" weight={500}>
                {item?.status ?? " No Status"}
              </Text>
            </Indicator>
          </Group>
        </Group>

        <Divider my="sm" />

        <Group spacing="xs" align="center">
          <IconMail size={16} color="#FF9800" />
          <Text fz="sm" c="dark">
            {item.recipientEmail}
          </Text>
        </Group>
        <Group spacing="xs" align="center">
          <IconMessage size={16} color="#7af09d" />
          <Text fz="sm" c="dimmed">
            {item.message}
          </Text>
        </Group>

        <Divider my="sm" />

        <Group spacing="xs" align="center">
          <IconClock size={16} color="#2196F3" />
          <Text fz="xs" c="dimmed">
            {moment(item.sentAt).format("DD-MMM-YYYY, h:mm A")}
          </Text>
        </Group>
      </Box>
    </Grid.Col>
  );
};

const useStyles = createStyles(() => ({
  card: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    padding: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
}));

export default memo(NotificationCardItem);
