import { ActionIcon, Box, Flex, rem } from "@mantine/core";
import React, { FC, useMemo, useState } from "react";
import CustomTable from "../../../../component/table";
import { COLUMNS } from "../../../../columns";
import { useGetAllEmpWorks } from "../../../../hooks/emp-work/query/getAllEmpWork.query";
import moment from "moment";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import { useGetAllLeaveDate } from "../../../../hooks/reports/query/getAllLeaveDate.query";
import { allHolidayHook } from "../../timeline/use-hook/allHolidayHook";
import { useGetAllEmployeeAbsentAttendance } from "../../../../hooks/all-student-attendance/query/useAllEmployeeAbsentAttendance.query";
import { useGetAllEmpTasks } from "../../../../hooks/emp-work/query/getAllEmpTask.query";

interface IEmpWork {
  selectedStudent?: string | undefined;
  startDate: Date;
  endDate: Date;
  setStartDate: (value: React.SetStateAction<Date>) => void;
  setEndDate: (value: React.SetStateAction<Date>) => void;
}

const EmpWorkTable: FC<IEmpWork> = ({
  selectedStudent,
  endDate,
  startDate,
  setEndDate,
  setStartDate,
}) => {
  const inputDate = moment(new Date()).startOf("day").toDate();
  const [date, setDate] = useState<Date | null>(inputDate);
  const [searchText, setSearchText] = useState<string>("");

  const { data, isLoading } = useGetAllEmpWorks({
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const { data: taskData,isLoading:tasksLoading } = useGetAllEmpTasks({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
  });

  const { data: leaveDate } = useGetAllLeaveDate({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const { data: absentData } = useGetAllEmployeeAbsentAttendance({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    studentId: selectedStudent ?? "",
  });
  

  const isHoliday = allHolidayHook();
  const EmpWork = useMemo(() => {
    if (!isLoading && data?.data && taskData?.data && !tasksLoading) {
      return data.data.map((employeeWorkArray) => {
        const employeeWork = employeeWorkArray[0];
        if (!employeeWork) return null;

        const employeeName = employeeWork.employee;
        if (
          searchText &&
          employeeName &&
          !employeeName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return null;
        }
        const matchingTasks =
          taskData?.data?.filter((task) => task[0].employee === employeeName) ??
          [];

        return {
          ...employeeWorkArray,
          leaveDate: leaveDate ?? [],
          isHoliday: isHoliday,
          startDate,
          absentData,
          taskData: matchingTasks,
        };
      }).filter(Boolean);
    }
    return [];
  }, [isLoading, data, taskData, leaveDate, isHoliday, absentData, searchText,tasksLoading]);

  const handleDateChange = (val: Date | null) => {
    if (val) {
      setDate(val);
      setStartDate(moment(val).startOf("day").toDate());
      setEndDate(moment(val).endOf("day").toDate());
    }
  };

  return (
    <Box>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={[...COLUMNS.empWork]}
        data={EmpWork}
        grid={false}
        headerProps={{
          search: true,
          onChangeText: setSearchText,
          rightComponent: (
            <Flex mih={50} gap="md">
              <Flex align={"center"} mr={6}>
                <ActionIcon
                  onClick={() =>
                    handleDateChange(moment(date).subtract(1, "day").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  mr={3}
                >
                  <IconChevronLeft size={16} color="black" />
                </ActionIcon>
                <DatePickerInput
                  maxDate={new Date()}
                  styles={{ input: { border: "none" } }}
                  icon={
                    <IconCalendar
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={1.5}
                    />
                  }
                  placeholder="Pick date"
                  value={date}
                  onChange={handleDateChange}
                />
                <ActionIcon
                  disabled={date ? moment(date).isSame(moment(), "day") : false}
                  onClick={() =>
                    handleDateChange(moment(date).add(1, "day").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  ml={3}
                >
                  <IconChevronRight size={16} color="black" />
                </ActionIcon>
              </Flex>
            </Flex>
          ),
        }}
      />
    </Box>
  );
};

export default EmpWorkTable;
