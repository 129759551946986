import { Box, createStyles, Grid, Text } from "@mantine/core";
import React from "react";
import { COLORS } from "../../assets/colors/index.colors";
import EmployeeCard from "./components/EmployeeCard";
import { useTodayAbsentAttendanceQuery } from "../../hooks/all-student-attendance/query/useTodayAbsentAttendance.query copy";
import moment from "moment";
import { useTodayLateAttendanceQuery } from "../../hooks/all-student-attendance/query/useTodayLateAttendance.query";
import { useTodayLeaveAttendanceQuery } from "../../hooks/all-student-attendance/query/useTodayLeaveAttendance.query";
import { getPreviousUnderNineHoursAttendance } from "../../hooks/all-student-attendance/query/getPreviousUnderNineHoursAttendance.query";
import { getPreviousNotOutAttendance } from "../../hooks/all-student-attendance/query/getPreviousNotOutAttendance.query";
import { useGetLessWorkHourEmployees } from "../../hooks/all-student-attendance/query/getLessWorkHourEmployee.query";
import { convertToHoursAndMinutes } from "../../helper/convertToHoursAndMinutes";

const Dashboard = () => {
  const { classes } = useStyles();
  const startDate = moment(new Date()).startOf("day").toDate();
  const endDate = moment(new Date()).endOf("day").toDate();

  const { data: absentData } = useTodayAbsentAttendanceQuery({
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
  });

  const { data: lateData } = useTodayLateAttendanceQuery({
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
  });

  const { data: leaveData } = useTodayLeaveAttendanceQuery({
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
  });

  const { data: underNineHoursWorkingData } =
    getPreviousUnderNineHoursAttendance();
  const { data: previousNotOutAttendanceData } = getPreviousNotOutAttendance();
  const { data: lessWorkHourData } = useGetLessWorkHourEmployees();
  const previousDate = moment().subtract(1, "days").format("DD MMM YYYY");

  return (
    <Box>
      <Grid>
        <Grid.Col sm={6} md={6} lg={4}>
          <Box className={classes.container}>
            <Text fz="xl" ml={16} pt={6}>
              Leave & Absent
            </Text>
            <Box className={classes.employeeCardContainer}>
              {leaveData?.data?.map((item: TAttendanceData) => (
                <EmployeeCard key={item._id} name={item.name} type="leave" />
              ))}
              {absentData?.data?.map((item, index) => (
                <EmployeeCard
                  key={item.studentID + index}
                  name={item.name}
                  type="absent"
                />
              ))}
            </Box>
          </Box>
        </Grid.Col>
        <Grid.Col sm={6} md={6} lg={4}>
          <Box className={classes.container}>
            <Text fz="xl" ml={16} pt={6}>
              Late Employee
            </Text>
            <Box className={classes.employeeCardContainer}>
              {lateData?.data?.map((item, index) => (
                <EmployeeCard
                  key={item.studentID + index}
                  name={item.name}
                  time={item.inTime}
                  type="late"
                />
              ))}
            </Box>
          </Box>
        </Grid.Col>
        <Grid.Col sm={6} md={6} lg={4}>
          <Box className={classes.container}>
            <Text fz="xl" ml={16} pt={6}>
              Office Hours{" "}
              <Text span size="sm" fw={600}>
                ({previousDate})
              </Text>
            </Text>
            <Box className={classes.employeeCardContainer}>
              {underNineHoursWorkingData?.data?.map((item, index) => (
                <EmployeeCard
                  key={item.studentID + index}
                  name={item.name}
                  workingHours={item.workingHours}
                  type="incomplete"
                />
              ))}
              {previousNotOutAttendanceData?.data?.map((item) => (
                <EmployeeCard
                  key={item._id}
                  name={item.name}
                  workingHours={"------"}
                  type="noLogOut"
                />
              ))}
            </Box>
          </Box>
        </Grid.Col>
        <Grid.Col sm={6} md={6} lg={4}>
          <Box className={classes.container}>
            <Text fz="xl" ml={16} pt={6}>
              Less Work Hours{" "}
              <Text span size="sm" fw={600}>
                ({previousDate})
              </Text>
            </Text>
            <Box className={classes.employeeCardContainer}>
              {lessWorkHourData?.data?.map((item, index) => {
                const { hours, minutes } = convertToHoursAndMinutes(
                  item.totalSpendTime
                );

                return (
                  <EmployeeCard
                    key={item.studentID + index}
                    name={item.name}
                    workingHours={`${hours}h ${minutes}m`}
                    type="noTag"
                  />
                );
              })}
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
export default Dashboard;

const useStyles = createStyles(() => ({
  container: {
    background: COLORS.white,
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",

    borderRadius: 6,
  },
  employeeCardContainer: {
    height: 350,
    overflow: "auto",
    padding: "0px 16px",
  },
}));
