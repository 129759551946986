import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const UpdateLeavePolicy = async (data: TLeavePolicy) => {
  const response = await request({
    url: apiUrls.leavePolicy.UPDATE_LEAVE_POLICY,
    method: "POST",
    data,
  });

  return response;
};

export const useUpdateLeavePolicyMutation = () => {
  return useMutation(UpdateLeavePolicy);
};
