import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllEmployeeAbsentAttendance = async (params: TAttendanceParams) => {
  const response: TServerResponse<TAttendanceData[], unknown> = await request({
    url: apiUrls.ALL_EMPLOYEE_ABSENT_ATTENDANCE,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      studentId: params.studentId,
    },
  });
  return response;
};

export const useGetAllEmployeeAbsentAttendance = (
  params: TAttendanceParams
) => {
  return useQuery(
    ["attendance-absent", params],
    () => getAllEmployeeAbsentAttendance(params),
    { keepPreviousData: true }
  );
};
