import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getWorkingTasks = async () => {
  const response: TServerResponse<IWorkingTasks[], unknown> = await request({
    url: apiUrls.taskStatus.WORKING_TASKS,
    method: "GET",
  });

  return response;
};

export const useGetAllWorkingTasks = () => {
  return useQuery(["admin", "get-working-tasks"], () => getWorkingTasks());
};
