import React from "react";
import { openTaskLink } from "../helper/openTaskLink";
import { openProjectLink } from "../helper/openProjectLink";
import { dateFormatWithTime } from "../helper/dateFormatWithTime";

interface IColumn {
  key: string;
  header: string;
  renderCell?: (row: ITaskReport) => React.ReactNode;
  minWidth?: number;
}

export const TASK_REPORT_COLUMNS: IColumn[] = [
  {
    key: "project_name",
    header: "Project Name",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return (
        <a
          href={openProjectLink(content.project_id)}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div style={{ fontSize: 12, color: "#000", fontWeight: "bold" }}>
            Id: {content.project_id}
          </div>
          <div>{content?.project_name ?? "NA"}</div>
        </a>
      );
    },
  },

  {
    key: "content.task_name",
    header: "Task Name",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return (
        <a
          href={openTaskLink(content.task_id)}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div style={{ fontSize: 12, color: "#000", fontWeight: "bold" }}>
            Id: {content.task_id}
          </div>
          <div>{content?.task_name ?? "NA"}</div>
        </a>
      );
    },
    minWidth: 180,
  },
  {
    key: "content.status_update_by",
    header: "User Name",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.status_update_by ?? "NA"}`;
    },
  },
  {
    key: "spend_time",
    header: "Spend Time",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      const formattedSpendTime = Number(content?.spend_time ?? 0).toFixed(2);
      return `${formattedSpendTime} h`;
    },
  },
  {
    key: "createdAt",
    header: "Create date",
    renderCell: (row: ITaskReport) => {
      const { createdAt } = row;
      console.log("Created at",createdAt);
      const date = new Date(createdAt);
      const formattedDate = dateFormatWithTime(date);
      return <div>{formattedDate}</div>;
    },
    minWidth: 140,
  },
  {
    key: "status_name",
    header: "Status",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.status_name ?? "NA"}`;
    },
  },
];
