import React from "react";
import { Chip, Flex, Stack, Text } from "@mantine/core";
import { IconBrandSpeedtest } from "@tabler/icons-react";
import { TEmpMappingData } from "../types/data/empMapping";
import { openProjectLink } from "../helper/openProjectLink";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";

export const EMPMAPPING_COLUMNS: TEmpMappingTableColumns[] = [
  {
    key: "employee",
    header: "Employee",
    renderCell: (value) => {
      
      const url = generateTaskReportUrl(value?.employee?._id ?? "");
  
      return (
        <span style={{ fontWeight: "600", color: "#333" }}>
          <a href={url} style={{ textDecoration: "none", color: "#333" }}>
            {value?.employee?.name}
          </a>
        </span>
      );
    },
  },  
  {
    key: "projectIds",
    header: "Projects & Bandwidth",
    renderCell: (value: TEmpMappingData) => (
      <Flex justify="flex-start" gap="sm" wrap="wrap">
        {value?.projects?.map((project) =>
          project.projectDetails?.map((detail) => (
            <Chip
              checked={false}
              key={detail._id}
              size="lg"
              styles={{ label: { padding: "24px 0px" } }}
            >
              <a
                href={openProjectLink(detail.projectId)}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  padding: "0px 24px",
                }}
              >
                <Stack align="flex-start" spacing={0}>
                  <Text size="sm" weight={500}>
                    {detail.name}
                  </Text>
                  <Flex align="center" gap={4}>
                    <IconBrandSpeedtest stroke={2} size={14} />
                    <Text size="sm" weight={500} style={{ marginRight: 8 }}>
                      {project.bandwidth || "N/A"} %
                    </Text>
                  </Flex>
                </Stack>
              </a>
            </Chip>
          ))
        )}
      </Flex>
    ),
  },
  {
    key: "bandwidth",
    header: "Remaining Bandwidth",
    renderCell: (value: TEmpMappingData) => {
      // Define total bandwidth (default to 100%)
      const totalBandwidth = 100;

      // Calculate total used bandwidth across all projects
      const totalUsedBandwidth = value?.projects?.reduce(
        (acc, project) => acc + (project.bandwidth || 0),
        0
      );

      // Calculate remaining bandwidth
      const remainingBandwidth = totalBandwidth - totalUsedBandwidth;

      return (
        <div>
          <span
            style={{
              display: "inline-block",
              padding: "4px 8px",
              borderRadius: "8px",
              backgroundColor: remainingBandwidth < 20 ? "#FFCDD2" : "#C8E6C9",
              color: remainingBandwidth < 20 ? "#B71C1C" : "#1B5E20",
              fontWeight: "600",
            }}
          >
            <IconBrandSpeedtest stroke={2} size={14} /> {remainingBandwidth} %
          </span>
        </div>
      );
    },
  },
];
