import { Box, Chip, Flex, Stack, Text, Tooltip } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import { dateFormat } from "../helper/dataFormat";
import React from "react";
import { openTaskLink } from "../helper/openTaskLink";
import { projectHoliday } from "../helper/timeline/projectHoliday";
import { projectHolidayBackgroundColor } from "../helper/timeline/projectHolidayBackgroundColor";

export const MONTH_WISE_TASK_TABLE: TMonthWiseTaskColumns[] = [
  {
    key: "date",
    minWidth: 200,
    header: "Date",
    renderCell: (row: TMonthWiseTask) => {
      const userStatusText = projectHoliday(new Date(row.date), row.isHoliday);
      const color = projectHolidayBackgroundColor(
        new Date(row.date),
        row.isHoliday
      );

      return (
        <Box>
          <Text>{dateFormat(row.date.toString())}</Text>
          {userStatusText && (
            <span
              style={{
                fontWeight: "600",
                color: "white",
                padding: "4px 12px",
                fontSize: 10,
                borderRadius: 8,
                background: color,
              }}
            >
              {userStatusText}
            </span>
          )}
        </Box>
      );
    },
  },
  {
    key: "totalSpendTime",
    minWidth: 200,
    header: "Time Spent",
    renderCell: (row: TMonthWiseTask) => {
      if (row.tasks.length === 0) {
        return <></>;
      }
      return <Text>{row.totalSpendTime.toFixed(2)}</Text>;
    },
  },
  {
    key: "tasks",
    minWidth: 600,
    header: "Tasks",
    renderCell: (row: TMonthWiseTask) => {
      // const [opened, setOpened] = useState(false);
      // const showMore = row.tasks.length > 5;
      const visibleTasks = row.tasks;
      // const hiddenTasks = row.tasks.slice(5);

      return (
        <>
          <Flex justify={"flex-start"} wrap={"wrap"} gap="sm">
            {visibleTasks.map((item, index) => {
              return (
                <Tooltip label={item.taskName} key={index}>
                  <Chip
                    key={index}
                    checked={false}
                    size="md"
                    styles={{ label: { padding: "26px 12px" } }}
                  >
                    <a
                      href={openTaskLink(Number(item.taskId))}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        padding: "0px 24px",
                      }}
                    >
                      <Stack align="flex-start" spacing={0}>
                        <Text size="sm" weight={600} color="#5e5e5e">
                          {item.empName}
                        </Text>
                        <Flex align="center" gap={4}>
                          <IconClockHour4
                            stroke={2}
                            size={14}
                            color="#868e96"
                          />
                          <Text size="sm" c="blue" weight={500}>
                            {parseFloat(item.spendTime.toString()).toFixed(2)} h
                          </Text>
                        </Flex>
                      </Stack>
                    </a>
                  </Chip>
                </Tooltip>
              );
            })}

            {/* {showMore && (
              <Button
                size="xs"
                variant="light"
                radius={"md"}
                leftIcon={<IconPlus size={16} />}
                onClick={() => setOpened(true)}
              >
                More
              </Button>
            )} */}
          </Flex>

          {/* <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title={dateFormat(row.date.toString())}
            centered
            size="md"
          >
            <ScrollArea h={300} scrollHideDelay={0} scrollbarSize={"sm"}>
              {hiddenTasks.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={openTaskLink(Number(item.taskId))}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      marginBottom: "10px",
                    }}
                  >
                    <Flex
                      mb={"md"}
                      direction="column"
                      gap={8}
                      p={12}
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                        background: "#fff",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.2s ease, box-shadow 0.2s ease",
                      }}
                      className="task-card"
                    >
                      <Text lineClamp={1} fw={500} size={"sm"}>
                        {item.taskName}
                      </Text>

                      <Flex justify="space-between" align="center">
                        <Text size="sm" weight={600} color="gray">
                          Task ID: {item.taskId}
                        </Text>

                        <Flex align="center" gap={6}>
                          <IconClockHour4
                            stroke={2}
                            size={14}
                            color="#868e96"
                          />
                          <Text size="sm" weight={600} color="blue">
                            {parseFloat(item.spendTime.toString()).toFixed(2)} h
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex justify="space-between" align="center">
                        <Text size="sm" weight={500}>
                          {item.empName}
                        </Text>
                        <Text size="sm" weight={500} color="gray">
                          {item.empEmail}
                        </Text>
                      </Flex>
                    </Flex>
                  </a>
                );
              })}
            </ScrollArea>
          </Modal> */}
        </>
      );
    },
  },
];
