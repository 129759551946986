import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async () => {
  const response: TServerResponse<TPreviousNotOutAttendance[], unknown> =
    await request({
      url: apiUrls.GET_PREVIOUS_NOT_OUT_ATTENDANCE,
      method: "GET",
    });
  return response;
};

export const getPreviousNotOutAttendance = () => {
  return useQuery(["get-previous-not-out-attendance"], get, {
    keepPreviousData: true,
  });
};
