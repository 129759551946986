import { Box } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { COLUMNS } from "../../../columns";
import CustomTable from "../../../component/table";
import { CONSTANTS } from "../../../constant";
import { useGetSingleProjectSprint } from "../../../hooks/sprint/query/getSingleProjectSprint.query";
import { useParams } from "react-router-dom";

const ProjectSprint = () => {
  const { projectId } = useParams();

  const tableColumns = [...COLUMNS.sprint];
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const [search, setSearch] = useState("");

  const { data, isLoading } = useGetSingleProjectSprint({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    searchParams: {
      search,
    },
    projectId: Number(projectId),
  });

  const sprint: TSprintData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box>
      <CustomTable
        isDashboardTable={false}
        loading={false}
        columns={tableColumns}
        data={sprint}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
        }}
      />
    </Box>
  );
};

export default ProjectSprint;
