import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Flex,
  rem,
  ScrollArea,
} from "@mantine/core";

import React, { useMemo, useState } from "react";
import { useTodayAttendanceQuery } from "../../../hooks/all-student-attendance/query/useTodayAttendance.query";
import { DatePickerInput } from "@mantine/dates";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import moment from "moment";
import CustomTable from "../../../component/table";
import { COLUMNS } from "../../../columns";

const TodayAttendance = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [search, setSearch] = useState("");
  const tableColumns = [...COLUMNS.attendace];

  const { data, isLoading } = useTodayAttendanceQuery({
    paging: {
      itemPerPage: 500,
      page: 1,
    },
    date: date,
    search: search,
  });

  const attendance: TAttendanceData[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isSunday = (date: Date) => date.getDay() === 0;

  const leaveEmployees = attendance.filter((item) => item.status === "leave");
  const absentEmployees = attendance.filter((item) => item.status === "absent");

  return (
    <Box style={{ overflow: "hidden" }}>
      <CustomTable
        loading={isLoading}
        columns={tableColumns}
        data={attendance}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
          rightComponent: (
            <Flex mih={50} gap="md">
              <Flex align={"center"} mr={6}>
                <ActionIcon
                  onClick={() =>
                    setDate(moment(date).subtract(1, "day").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  mr={3}
                >
                  <IconChevronLeft size={16} />
                </ActionIcon>
                <DatePickerInput
                  maxDate={new Date()}
                  styles={{ input: { border: "none" } }}
                  icon={
                    <IconCalendar
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={1.5}
                    />
                  }
                  placeholder="Pick date"
                  value={date}
                  onChange={(val) => {
                    if (val) {
                      setDate(val);
                    }
                  }}
                />
                <ActionIcon
                  disabled={date ? isToday(date) : false}
                  onClick={() => setDate(moment(date).add(1, "day").toDate())}
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  ml={3}
                >
                  <IconChevronRight size={16} />
                </ActionIcon>
              </Flex>
            </Flex>
          ),
        }}
        attendanceProps={
          <>
            {(!isSunday(date) && leaveEmployees.length > 0) ||
            absentEmployees.length > 0 ? (
              <Card padding="xs" radius="md" withBorder mb="sm" mt="sm">
                <Flex direction="column" gap="xs">
                  {/* Leave and Absent Employees Section */}
                  {leaveEmployees.length > 0 && (
                    <Card padding="xs" radius="md">
                      <ScrollArea
                        h={20}
                        scrollbarSize={4}
                        scrollHideDelay={500}
                        style={{ cursor: "pointer" }}
                      >
                        <Flex align="center" gap="md" wrap="nowrap">
                          <Badge
                            size="sm"
                            radius="md"
                            variant="gradient"
                            gradient={{ from: "red", to: "grape", deg: 88 }}
                            style={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                            }}
                          >
                            Leave
                          </Badge>
                          {leaveEmployees.map((employee) => (
                            <Badge
                              key={employee.id}
                              size="sm"
                              radius="md"
                              variant="light" // Light variant for less attention
                              color="teal"
                              style={{ fontWeight: "normal" }}
                            >
                              {employee.name}
                            </Badge>
                          ))}
                        </Flex>
                      </ScrollArea>
                    </Card>
                  )}

                  {absentEmployees.length > 0 && (
                    <Card padding="xs" radius="md">
                      <ScrollArea
                        h={20}
                        scrollbarSize={4}
                        scrollHideDelay={500}
                        style={{ cursor: "pointer" }}
                      >
                        <Flex align="center" gap="sm" wrap="nowrap">
                          <Badge
                            size="sm"
                            radius="md"
                            variant="gradient"
                            gradient={{ from: "red", to: "grape", deg: 88 }}
                            style={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                            }}
                          >
                            Absent
                          </Badge>
                          {absentEmployees.map((employee) => (
                            <Badge
                              key={employee.id}
                              size="sm"
                              radius="md"
                              variant="light"
                              color="teal"
                              style={{ fontWeight: "normal" }}
                            >
                              {employee.name}
                            </Badge>
                          ))}
                        </Flex>
                      </ScrollArea>
                    </Card>
                  )}
                </Flex>
              </Card>
            ) : null}
          </>
        }
        isDashboardTable={true}
      />
    </Box>
  );
};
export default TodayAttendance;
