import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllTaskByProjectId = async (params: TQueryParams) => {
  const resp = await request({
    url: apiUrls.projects.GET_TASKS_FOR_PROJECT,
    method: "GET",
    params: {
      ...params.paging,
      id: params.projectId,
      startDate: params.startDate,
      endDate: params.endDate,
    },
  });
  return resp;
};

export const useGetAllTaskByProjectID = (params: TQueryParams) => {
  return useQuery({
    queryKey: ["admin", "all-projects", params],
    queryFn: () => getAllTaskByProjectId(params),
  });
};
