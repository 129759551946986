import { number, object, string } from "yup";

export const AddStoryValidationSchema = object({
  name: string().required("Project Name can't be empty"),
  status: string().required("Status can't be empty"),
  projectId: number()
    .required("Project Id can't be empty")
    .min(1, "Project Id must be at least 1"),
  storyId: number()
    .required("Sprint Id can't be empty")
    .min(1, "Story Id must be at least 1"),
  progress: number()
    .required("Progress Id can't be empty")
    .min(0, "Progress must be at least 0")
    .max(100, "Progress cannot be more than 100"),
});
