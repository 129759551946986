import React, { memo } from "react";
import { IWorkHourLoss } from "../definiation";
import { Badge, createStyles, Flex } from "@mantine/core";

interface Props {
  attendanceCount: IWorkHourLoss["attendanceCount"];
}

const AttendanceCount: React.FC<Props> = ({
  attendanceCount: { total, absent, holiday, leave },
}) => {
  const { classes } = styles();
  return (
    <Flex gap={4}>
      <Badge className={classes.root}>
        Total
        <Badge
          size="xs"
          radius={"xl"}
          ml={6}
          variant="filled"
          className={classes.count}
        >
          {total}
        </Badge>
      </Badge>
      <Badge className={classes.root} color="red">
        Absent
        <Badge
          size="xs"
          radius={"xl"}
          ml={6}
          variant="filled"
          className={classes.count}
          color="red"
        >
          {absent}
        </Badge>
      </Badge>
      <Badge className={classes.root} color="pink">
        Holiday
        <Badge
          size="xs"
          radius={"xl"}
          ml={6}
          variant="filled"
          className={classes.count}
          color="pink"
        >
          {holiday}
        </Badge>
      </Badge>
      <Badge className={classes.root} color="pink">
        Leave
        <Badge
          size="xs"
          radius={"xl"}
          ml={6}
          variant="filled"
          className={classes.count}
          color="pink"
        >
          {leave}
        </Badge>
      </Badge>
    </Flex>
  );
};

export default memo(AttendanceCount);

const styles = createStyles({
  root: {
    display: "flex",
    padding: "16px 4px 16px 12px",
    alignItems: "center",
  },

  count: {
    aspectRatio: "1 / 1",
    width: 26,
    height: 26,
  },
});
