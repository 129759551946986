import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getEmailSendApi = async () => {
  const response: TServerResponse<TApiDocs[], unknown> = await request({
    url: apiUrls.projectApi.GET_EMAIL_SEND_API,
    method: "GET",
  });
  return response;
};

export const useGetEmailSendApi = () => {
  return useQuery(["apis", "get-email-send-Api"], () => getEmailSendApi());
};
