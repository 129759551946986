import {
  IconHourglassLow,
  IconHourglassHigh,
  IconApiApp,
  IconBrandProducthunt,
  IconCalendarQuestion,
  IconCalendarStats,
  IconChecklist,
  IconClockPlay,
  IconFileAnalytics,
  IconTimelineEventText,
  IconFileText,
  IconClockHour3,
  IconUserEdit,
  IconPresentationAnalytics,
  IconAdjustmentsCode,
  IconBook,
  IconNotes,
  IconHomeMove,
  IconCalendarOff,
  IconUsers,
  IconCalendar,
  IconMessagePlus,
  IconNetwork,
  IconMailSearch,
  IconBellRinging,
  IconMailCode,
  IconAdjustmentsStar,
  IconBriefcase,
  IconHourglass,
  IconUserCode,
  IconListCheck,
  IconFileInfo,
} from "@tabler/icons-react";

export const navLinkData = [
  {
    label: "HR",
    icon: IconHomeMove,
    submenu: [
      { link: "/holidays", label: "Holidays", icon: IconCalendarOff },
      {
        link: "/today-attendance",
        label: "Today Attendance",
        icon: IconChecklist,
      },
      { link: "/employees", label: "Employees", icon: IconUsers },
      { link: "/attendance", label: "Attendance", icon: IconCalendar },
      {
        link: "/employ-feedback",
        label: "Employ Feedback",
        icon: IconMessagePlus,
      },
      {
        link: "/employee-point",
        label: "Employee Point",
        icon: IconAdjustmentsStar,
      },
    ],
  },
  {
    label: "Leave",
    icon: IconCalendarQuestion,
    submenu: [
      {
        link: "/leave",
        label: "Leave Management",
        icon: IconClockHour3,
      },
      { link: "/leave-policy", label: "Leave Policy", icon: IconFileText },
    ],
  },
  {
    label: "Project",
    icon: IconClockPlay,
    submenu: [
      { link: "/projects", label: "Projects ", icon: IconFileAnalytics },
      {
        link: "/project-work",
        label: "Project Work",
        icon: IconPresentationAnalytics,
      },
      {
        link: "/project-info/:id",
        label: "Project Info",
        icon: IconFileInfo,
      },
      {
        link: "/project-task",
        label: "Tasks",
        icon: IconListCheck,
      },
    ],
  },
  {
    label: "Sprint",
    icon: IconAdjustmentsCode,
    submenu: [{ link: "/sprint", label: "Sprints", icon: IconAdjustmentsCode }],
  },
  {
    label: "Story",
    icon: IconBook,
    submenu: [{ link: "/story", label: "Stories", icon: IconBook }],
  },
  {
    label: "Tasks",
    icon: IconCalendarStats,
    submenu: [
      {
        link: "/task-status",
        label: "Task Status",
        icon: IconCalendarStats,
      },
    ],
  },
  {
    label: "Employee",
    icon: IconUserEdit,
    submenu: [
      {
        link: "/employ-mapping",
        label: "Emp Mapping",
        icon: IconCalendarQuestion,
      },
      {
        link: "/employee-work",
        label: "Emp Work Day",
        icon: IconUserEdit,
      },
      {
        link: "/project-work-hours",
        label: "Emp Work Month",
        icon: IconUserCode,
      },
      { link: "/worklog", label: "Work Log", icon: IconNotes },
      { link: "/timeline", label: "Emp Timeline", icon: IconTimelineEventText },
      { link: "/task-report", label: "Emp Task", icon: IconChecklist },
      { link: "/working-tasks", label: "Working Tasks", icon: IconBriefcase },
      {
        link: "/employee-project-hours",
        label: "Project Hours",
        icon: IconHourglassLow,
      },
      {
        link: "/employee/work-hour-loss",
        label: "Work Hour Loss",
        icon: IconHourglass,
      },
      {
        link: "/office-hours-loss",
        label: "Office Hours Loss",
        icon: IconHourglassHigh,
      },
    ],
  },
  {
    label: "API'S",
    icon: IconApiApp,
    submenu: [
      {
        link: "/project-api",
        label: "Project Api",
        icon: IconBrandProducthunt,
      },
      {
        link: "/work-log-api",
        label: "Work Log Api",
        icon: IconNetwork,
      },
      {
        link: "/sprint-api",
        label: "Sprint Api",
        icon: IconAdjustmentsCode,
      },
      {
        link: "/story-api",
        label: "Story Api",
        icon: IconBook,
      },
      {
        link: "/email-send-api",
        label: "Email Send Api",
        icon: IconMailCode,
      },      
    ],
  },
  {
    label: "Logs",
    icon: IconApiApp,
    submenu: [
      {
        link: "/notification-logs",
        label: "Notification Logs",
        icon: IconChecklist,
      },
      {
        link: "/email-send-logs",
        label: "Email Send logs",
        icon: IconMailSearch,
      },
      {
        link: "/notification-send-logs",
        label: "Notification Send logs",
        icon: IconBellRinging,
      },
      {
        link: "/sprint-logs",
        label: "Sprint logs",
        icon: IconAdjustmentsCode,
      },
      {
        link: "/story-logs",
        label: "Story logs",
        icon: IconBook,
      },
    ],
  },
];
