import moment from "moment";
import { IWorkHourLoss } from "./definiation";

export const getFilterDates = (e: [Date | null, Date | null]) => {
  if (e[0] === null || e[1] === null) {
    return [
      moment().startOf("month").toDate().toString(),
      moment().endOf("month").toDate().toString(),
    ];
  }

  return [
    moment(e[0]).startOf("day").toDate().toString(),
    moment(e[1]).endOf("day").toDate().toString(),
  ];
};

export const getHourLoss = (e: IWorkHourLoss) => {
  const { attendanceCount, time_spend } = e;
  const days =
    attendanceCount.total - attendanceCount.holiday - attendanceCount.leave;

  const loss = days * 7 - time_spend;

  return loss > 0 ? loss : 0;
};

export const getHourLossPercentage = (e: IWorkHourLoss) => {
  const { attendanceCount, time_spend } = e;
  const days =
    attendanceCount.total - attendanceCount.holiday - attendanceCount.leave;

  const totalhours = days * 7;

  const loss = ((totalhours - time_spend) / totalhours) * 100;

  return loss > 0 ? loss : 0;
};
