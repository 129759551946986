import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllEmpPoint = async (params: TQueryParams) => {
  const response: TServerResponse<string, unknown> = await request({
    url: apiUrls.empPoint.GET_ALL_EMP_POINT,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      studentId: params.studentId,
    },
  });

  return response;
};

export const useGetAllEmpPoint = (params: TQueryParams) => {
  return useQuery(["admin", "all-epm-point", params], () =>
    getAllEmpPoint(params)
  );
};
