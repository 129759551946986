import React from "react";
import { Box, Chip, Flex, Stack, Text } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import { openProjectLink } from "../helper/openProjectLink";
import { projectHoliday } from "../helper/timeline/projectHoliday";
import { projectHolidayBackgroundColor } from "../helper/timeline/projectHolidayBackgroundColor";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";

export const PROJECT_WORK_COLUMNS: TProjectWorkTableColumns[] = [
  {
    key: "project_name",
    header: "Project Name",
    renderCell: (rows: any) => {
      if (!rows || rows.length === 0) return null;
      const { startDate, isHoliday } = rows;

      const row = rows[0];

      const { project_name, total_spend_time, _id } = row;

      const userStatusText = projectHoliday(startDate, isHoliday);
      const slotBackgroundColor = projectHolidayBackgroundColor(
        startDate,
        isHoliday
      );

      return (
        <div style={{ fontWeight: "600", color: "#333" }}>
          <a
            href={openProjectLink(Number(_id))}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            {project_name}
          </a>

          {total_spend_time > 0 && (
            <Box mt={4}>
              <Text span color="gray" size="xs">
                Total Hours:{" "}
              </Text>
              <Text span c="blue" inherit>
                {total_spend_time.toFixed(2)} h
              </Text>
            </Box>
          )}

          {userStatusText && (
            <Box mt={10}>
              <span
                style={{
                  fontWeight: "600",
                  color: "white",
                  padding: "4px 12px",
                  fontSize: 10,
                  borderRadius: 8,
                  background: slotBackgroundColor,
                }}
              >
                {userStatusText}
              </span>
            </Box>
          )}
        </div>
      );
    },
  },
  {
    key: "employees",
    header: "Employees Name",
    renderCell: (rows: ProjectWorkDetail[]) => {
      if (!rows || rows.length === 0) return null;

      const row = rows[0]; // Extract first element
      const { employees } = row;

      return (
        <Flex justify="flex-start" gap="sm" wrap="wrap">
        {employees.length > 0 ? (
          employees.map((employee, index) => {
            const url = generateTaskReportUrl(employee.employeeId);

            return (
              <Chip
                key={employee.user_email + index}
                checked={false}
                size="lg"
                styles={{ label: { padding: 24 } }}
              >
                <Stack align="flex-start" spacing={0}>
                  <Text size="sm" weight={500} color="#5e5e5e" component={Link} to={url}>
                    {employee.employee || "No Employee"}
                  </Text>
                  <Flex align="center" gap={4}>
                    <IconClockHour4 stroke={2} size={14} color="#868e96" />
                    <Text size="sm" c="blue" weight={500}>
                      {employee.spend_time.toFixed(2)} h
                    </Text>
                  </Flex>
                </Stack>
              </Chip>
            );
          })
        ) : (
          <Text size="sm" color="gray">
            No Employees Working
          </Text>
        )}
      </Flex>
      );
    },
  },
];
