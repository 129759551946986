import { Box, Text } from "@mantine/core";
import React, { memo } from "react";

interface Props {
  attendance?: IWorkingTasks["attendance"];
}

const StatusChip: React.FC<Props> = ({ attendance }) => {
  if (!attendance) {
    return null;
  }
  if (
    (attendance.status === "initiate" && !attendance.inTime) ||
    attendance.status === "absent"
  ) {
    return <Chip title="Absent" bg="#d90303a8" />;
  }

  if (attendance.status === "holiday" || attendance.status === "leave") {
    return (
      <Chip
        title={attendance.status}
        bg={attendance.status === "leave" ? "#ff008a" : "#d90303a8"}
      />
    );
  }

  return null;
};

export default memo(StatusChip);

interface IChip {
  title: string;
  bg: string;
}

const Chip: React.FC<IChip> = ({ title, bg }) => {
  return (
    <Box mt={4}>
      <Text
        fw={600}
        color="white"
        px={12}
        py={4}
        fz={10}
        bg={bg}
        display={"inline-block"}
        style={{ borderRadius: 8 }}
      >
        {title}
      </Text>
    </Box>
  );
};
