import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllTaskStatus = async (params: TQueryParams) => {
  const response: TServerResponse<ITaskStatus[], unknown> = await request({
    url: apiUrls.taskStatus.ALL_TASK_STATUS,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      searchFieldString: [
        "content.task_id",
        "content.project_id",
        "content.project_name",
        "content.status_name",
        "content.status_update_by",
      ],
    },
  });

  return response;
};

export const useGetAllTaskStatus = (params: TQueryParams) => {
  return useQuery(["admin", "all-task-status", params], () =>
    getAllTaskStatus(params)
  );
};
