import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllEmailSend = async (params: TQueryParams) => {
  const response: TServerResponse<TEmailSend[], unknown> = await request({
    url: apiUrls.emailSend.GET_ALL_EMAIL_SEND,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      searchFieldString: ["employeeEmail", "title"],
    },
  });

  return response;
};

export const useGetAllEmailSend = (params: TQueryParams) => {
  return useQuery(["admin", "all-email-send", params], () =>
    getAllEmailSend(params)
  );
};
