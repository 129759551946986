import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllEmpTask = async (params: TQueryParams) => {
  const response: TServerResponse<ITaskData, unknown> = await request({
    url: apiUrls.empWorks.ALL_EMP_TASKS,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
    },
  });
  return response;
};

export const useGetAllEmpTasks = (params: TQueryParams) => {
  return useQuery(["admin", "all-empTasks", params], () =>
    getAllEmpTask(params)
  );
};
