import { Flex, ScrollArea, Text } from "@mantine/core";
import { IconClockHour4 } from "@tabler/icons-react";
import React, { memo } from "react";
import { openTaskLink } from "../../helper/openTaskLink";

interface IProps {
  row: EmployeeWorkData;
  hiddenTasks: TaskEntry[];
}

const TableModal: React.FC<IProps> = ({ hiddenTasks }) => {
  return (
    <ScrollArea h={300} scrollHideDelay={0} scrollbarSize={"sm"}>
      {hiddenTasks.map((item, index) => (
        <a
          key={index}
          href={openTaskLink(Number(item.taskId))}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "#000",
            marginBottom: "10px",
          }}
        >
          <Flex
            mb={"md"}
            direction="column"
            gap={8}
            p={12}
            style={{
              borderRadius: "8px",
              border: "1px solid #ddd",
              background: "#fff",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
            }}
            className="task-card"
          >
            <Text lineClamp={1} fw={500} size={"sm"}>
              {item.taskName}
            </Text>

            <Flex justify="space-between" align="center">
              <Text size="sm" weight={600} color="gray">
                Task ID: {item.taskId}
              </Text>

              <Flex align="center" gap={6}>
                <IconClockHour4 stroke={2} size={14} color="#868e96" />
                <Text size="sm" weight={600} color="blue">
                  {parseFloat(item.spendTime.toString()).toFixed(2)} h
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </a>
      ))}
    </ScrollArea>
  );
};

export default memo(TableModal);
