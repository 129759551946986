import React, { useMemo } from "react";
import ApiDocApiInterface from "../components/api-doc-interface/ApiDocInterface";
import { useGetEmailSendApi } from "../../../hooks/project-api/query/getEmailSendApi.query";

const EmailSendApi = () => {
  const { data, isLoading } = useGetEmailSendApi();

  const emailSend: TApiDocs[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data.map((project: TApiDocs) => ({
        ...project,
      }));
    }
    return [];
  }, [isLoading, data]);

  return <ApiDocApiInterface data={emailSend} />;
};

export default EmailSendApi;
