import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async () => {
  const response: TServerResponse<TAttendanceData[], unknown> = await request({
    url: apiUrls.GET_TODAY_UNDER_NINE_HOURS_ATTENDANCE,
    method: "GET",
  });
  return response;
};

export const getPreviousUnderNineHoursAttendance = () => {
  return useQuery(["get-previous-under-nine-hours-attendance"], get, {
    keepPreviousData: true,
  });
};
