import { Box, Group, Select } from "@mantine/core";
import React, { FC, useMemo, useRef, useState } from "react";
import { useGetAllStudent } from "../../../hooks/students/query/getAllStudent.query";
import ThemeButton from "../../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import FeedbackModal from ".././modal/FeedbackModal";
import CustomTable from "../../../component/table";
import { COLUMNS } from "../../../columns";
import { useGetAllFeedback } from "../../../hooks/feedback/query/getAllFeedback.query";
import { CONSTANTS } from "../../../constant";
import { YearPickerInput } from "@mantine/dates";

interface IFeedback {
  selectedStudent: string | undefined;
  setSelectedStudent: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  endDate: Date | null;
  setYear: React.Dispatch<React.SetStateAction<Date | null>>;
  selectStudent?: boolean;
}
const Feedback: FC<IFeedback> = ({
  endDate,
  selectedStudent,
  setSelectedStudent,
  startDate,
  setYear,
  selectStudent = true,
}) => {
  const modalRef = useRef<any>(null);

  const tableColumns = [...COLUMNS.feedback];
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });

  const { refetch, data, isLoading } = useGetAllFeedback({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search:
        selectedStudent === "All" || selectedStudent === undefined
          ? ""
          : selectedStudent,
    },
  });

  const feedback: TFeedbackData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box>
      {selectStudent && (
        <Group position="apart" style={{ marginBottom: 20 }}>
          <Group>
            <Select
              data={[{ value: "All", label: "All" }, ...students]}
              defaultValue={"All"}
              onChange={(value) => {
                if (value) setSelectedStudent(value);
              }}
              searchable
            />
            <YearPickerInput
              placeholder="Search For Year"
              value={startDate}
              onChange={(val) => {
                if (val) {
                  setYear(val);
                }
              }}
            />
          </Group>

          <Box onClick={() => modalRef.current?.toggleModal()}>
            <ThemeButton
              title="Add Feedback"
              mr={15}
              leftIcon={<IconPlus size={20} />}
            />
          </Box>
        </Group>
      )}

      <Box>
        <FeedbackModal reload={refetch} ref={modalRef} />
      </Box>
      <CustomTable
        header={false}
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={feedback}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
    </Box>
  );
};

export default Feedback;
