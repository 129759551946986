import { useQuery } from "react-query";
import { request } from "../../../../services/axios.service";
import { apiUrls } from "../../../api-urls";

const getUserTimeline = async (params: TQueryParams) => {
  const response: TServerResponse<ITimeline[], unknown> = await request({
    url: apiUrls.reports.GET_USER_TIMELINE,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      studentId: params.studentId,
    },
  });

  return response;
};

export const useGetUserTimeLine = (params: TQueryParams) => {
  return useQuery(["admin", "all-task", params], () => getUserTimeline(params));
};
