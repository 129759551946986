import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { IWorkHourLoss } from "../../../pages/work-hour-loss/definiation";

interface Params {
  search: string;
  startDate: string;
  endDate: string;
  page: number;
  itemPerPage: number;
}

const get = async (params: Params) => {
  const response: TServerResponse<IWorkHourLoss[], unknown> = await request({
    url: apiUrls.GET_EMP_WORK_HRS_LOSS,
    method: "GET",
    params: params,
  });

  return response;
};

export const useGetEmpWorkHourLossQuery = (params: Params) => {
  return useQuery(
    [
      "admin",
      "emp",
      "work-hour-loss",
      params.search,
      params.startDate,
      params.endDate,
      params.itemPerPage,
      params.page,
    ],
    () => get(params)
  );
};
