import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllNotificationSend = async (params: TQueryParams) => {
  const response: TServerResponse<TNotificationSend[], unknown> = await request(
    {
      url: apiUrls.notificationSend.GET_ALL_NOTIFICATION_SEND,
      method: "GET",
      params: {
        ...params.paging,
        search: params.searchParams?.search,
        searchFieldString: ["employeeEmail", "title"],
      },
    }
  );

  return response;
};

export const useGetAllNotificationSend = (params: TQueryParams) => {
  return useQuery(["admin", "all-notification-send", params], () =>
    getAllNotificationSend(params)
  );
};
