import {
  ActionIcon,
  Box,
  Center,
  Flex,
  Group,
  Loader,
  Select,
} from "@mantine/core";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { rem } from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import AttendanceCard from "./component/AttendanceCard";
import AttendanceExport from "./component/data/AttendanceExport";
import InfoCount from "./component/InfoCount";
import React, { useMemo, useState, useEffect } from "react";
import { useGetAllEmployeeAttendance } from "../../hooks/all-student-attendance/query/useAllEmployeeAttendance.query";
import { useGetAllStudent } from "../../hooks/students/query/getAllStudent.query";
import moment from "moment";

const AllStudentAttendance: React.FC = () => {
  const [month, setMonth] = useState<Date>(new Date());
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student: any) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const { data, isLoading } = useGetAllEmployeeAttendance({
    date: month,
    studentId: selectedStudent || "",
  });

  const attendance: TAttendanceData[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data;
    } else {
      return [] as TAttendanceData[];
    }
  }, [isLoading, data, selectedStudent, month]);
  const attendanceCounts = useMemo(() => {
    let workingDays = 0;
    let leaveDays = 0;
    let holidays = 0;
    let absents = 0;

    attendance.forEach((record) => {
      if (record.status === "on time") workingDays++;
      else if (record.status === "leave") leaveDays++;
      else if (record.status === "holiday") holidays++;
      else if (record.status === "absent") absents++;
    });

    // const totalDays = workingDays + leaveDays + holidays + absents || 1;

    return {
      workingDays: workingDays,
      leaveDays: leaveDays,
      holidays: holidays,
      absents: absents,
      // percentageOnTime: (workingDays / totalDays) * 100,
      // percentageLate: (leaveDays / totalDays) * 100,
      // percentageAbsent: (absents / totalDays) * 100,
    };
  }, [attendance, selectedStudent]);

  useEffect(() => {
    if (students.length > 0 && selectedStudent === null) {
      setSelectedStudent(students[0].value);
    }
  }, [students, selectedStudent]);

  const studentName = selectedStudent
    ? students.find((student) => student.value === selectedStudent)?.label
    : "All";

  if (isLoading) {
    return (
      <Center h={"70vh"}>
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      <Flex
        mih={50}
        justify="space-between"
        align="center"
        direction="row"
        gap="md"
      >
        <Group>
          <ActionIcon
            onClick={() =>
              setMonth(moment(month).subtract(1, "month").toDate())
            }
            size={"md"}
            variant="light"
            bg={"white"}
          >
            <IconChevronLeft size={14} />
          </ActionIcon>

          <MonthPickerInput
            maxDate={new Date()} // Prevent selecting future months
            styles={{ input: { border: "none" } }}
            icon={
              <IconCalendar
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            }
            placeholder="Pick month"
            value={month}
            onChange={(val) => {
              if (val) {
                setMonth(val);
              }
            }}
          />

          <ActionIcon
            disabled={moment(month).isSame(new Date(), "month")} // Disable if current month
            onClick={() => setMonth(moment(month).add(1, "month").toDate())}
            size={"md"}
            variant="light"
            bg={"white"}
          >
            <IconChevronRight size={14} />
          </ActionIcon>

          <InfoCount extraData={attendanceCounts} />
        </Group>
        <Group>
          <Select
            data={[{ value: "All", label: "All" }, ...students]}
            value={selectedStudent || "All"}
            onChange={(value) => {
              if (value) setSelectedStudent(value);
            }}
            searchable
          />
          <AttendanceExport
            date={month}
            studentId={selectedStudent || "All"}
            Count={attendanceCounts}
          />
        </Group>
      </Flex>

      <AttendanceCard
        loading={isLoading}
        attendance={attendance}
        month={month}
        selectedStudent={studentName}
      />
    </Box>
  );
};

export default AllStudentAttendance;
