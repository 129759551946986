import { date, object, string } from "yup";

export const settingsValidationSchema = object({
  checkIn: string().required(),
  checkOut: string().required(),
  userName: string().required(),
  password: string(),
  referenceSaturday: date()
    .required()
    .test(
      "is-saturday",
      "The selected date must be a Saturday",
      (value) => !value || value.getDay() === 6
    ),
  screenShotInterval: string()
    .matches(/^\d+$/, "ScreenShot Interval must contain only digits")
    .required("ScreenShot Interval is required"),
});
