import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getLeavePolicy = async () => {
  const response = await request({
    url: apiUrls.leavePolicy.LEAVE_POLICY,
    method: "GET",
  });
  return response;
};

export const useGetLeavePolicy = () => {
  return useQuery(["leave-policy"], () => getLeavePolicy());
};
