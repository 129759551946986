import React from "react";
import { Box, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import TaskCard from "../component/task-card/TaskCard";
import StatusChip from "../component/status-chip/StatusChip";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";

interface IColumn {
  key: string;
  header: string;
  renderCell?: (row: IWorkingTasks) => React.ReactNode;
  minWidth?: number;
}

export const WORKING_TASKS_COLUMNS: IColumn[] = [
  {
    key: "name",
    header: "User Name",
    renderCell: (row: IWorkingTasks) => {
      const { name, hasOpenTask, attendance } = row;
      const url = generateTaskReportUrl(row._id);
      return (
        <Box>
          <Text
            color={hasOpenTask ? "green" : "red"}
            weight={"500"}
            component={Link}
            to={url}
          >
            {name}
          </Text>
          <StatusChip attendance={attendance} />
        </Box>
      );
    },
  },
  {
    key: "openTask",
    header: "Opened Task",
    renderCell: (row: IWorkingTasks) => {
      const { openTask } = row;
      const latestTask = openTask?.[0];
      if (!latestTask) {
        return null;
      }
      return <TaskCard task={latestTask} />;
    },
  },
  {
    key: "latestOpenTask",
    header: "Last Updated",
    renderCell: (row: IWorkingTasks) => {
      const { lastUpdatedTask } = row;
      if (!lastUpdatedTask) {
        return null;
      }
      return <TaskCard task={lastUpdatedTask} />;
    },
  },
];

