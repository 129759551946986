import React from "react";
import { dateFormatWithTime } from "../helper/dateFormatWithTime";
import { Text } from "@mantine/core";

export const EMAIL_SEND_COLUMNS: TEmailSendTableColumns[] = [
  {
    key: "employeeEmail",
    header: "Employee Email",
    renderCell: (row: TEmailSend) => {
      const { employeeEmail } = row;
      return `${employeeEmail}`;
    },
  },
  {
    key: "title",
    header: "Title",
    renderCell: (row: TEmailSend) => {
      const { title } = row;
      return `${title}`;
    },
  },
  {
    key: "message",
    header: "Message",
    renderCell: (row: TEmailSend) => {
      const { message } = row;
      return <Text lineClamp={2}>{message}</Text>;
    },
  },
  {
    key: "emailType",
    header: "Email Type",
    renderCell: (row: TEmailSend) => {
      const { emailType } = row;
      return `${emailType}`;
    },
  },
  {
    key: "status",
    header: "Status",
    renderCell: (row: TEmailSend) => {
      const { status } = row;
      return `${status}`;
    },
  },
  {
    key: "createdAt",
    header: "Create date",
    renderCell: (params) => {
      const date = new Date(params.createdAt),
        formattedDate = dateFormatWithTime(date);
      return <div style={{ width: 80 }}>{formattedDate}</div>;
    },
  },
];
