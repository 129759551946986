import { Box } from "@mantine/core";
import React, { useMemo, useRef, useState } from "react";
import CustomTable from "../../../component/table";
import ThemeButton from "../../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import { COLUMNS } from "../../../columns";
import { CONSTANTS } from "../../../constant";
import StoryModal, { IStoryModalRef } from "./modal/StoryModal";
import { useGetAllStory } from "../../../hooks/story/query/getAllStory.query";

const Story = () => {
  const modalRef = useRef<IStoryModalRef>(null);
  const tableColumns = [...COLUMNS.story];

  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const [search, setSearch] = useState("");

  const { refetch, data, isLoading } = useGetAllStory({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    searchParams: {
      search,
    },
  });

  const story: TStoryData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box>
      <StoryModal reload={refetch} ref={modalRef} />
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={story}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
          rightComponent: (
            <Box onClick={() => modalRef.current?.toggleModal()}>
              <ThemeButton
                title="Add Story"
                mr={15}
                leftIcon={<IconPlus size={20} />}
              />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default Story;
