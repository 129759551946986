import { Box, Modal, NumberInput, TextInput } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { notifications } from "@mantine/notifications";
import { useGetCategories } from "../../../../hooks/leave/query/getCategory";
import ThemeButton from "../../../../component/button/ThemeButton";
import { useCreateLeavePolicyMutation } from "../../../../hooks/leave-policy/mutation/CreateLeavePolicy";
import { useUpdateLeavePolicyMutation } from "../../../../hooks/leave-policy/mutation/updateLeavePolicy";

export interface ILeavePolicyModelRef {
  toggleModal: () => void;
  updateData: (leavePolicy: TLeavePolicy) => void;
}
interface ILeavePolicyModalProps {
  reload: () => void;
}

const LeavePolicyModel = (
  props: ILeavePolicyModalProps,
  ref: ForwardedRef<ILeavePolicyModelRef>
) => {
  const [data, setData] = useState<TLeavePolicy>();
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { isLoading: addLoading, mutateAsync: addLeavePolicy } =
    useCreateLeavePolicyMutation();
  const { isLoading: updateLoading, mutateAsync: updateLeavePolicy } =
    useUpdateLeavePolicyMutation();
  const { data: leaveCategories, isLoading } = useGetCategories();

  const categories = useMemo(() => {
    return !isLoading && leaveCategories?.data ? leaveCategories.data : [];
  }, [leaveCategories, isLoading]);

  const { getInputProps, onSubmit, reset, setValues } = useForm({
    initialValues: {
      _id: "",
      title: "",
      policies: {},
    },
    validate: {
      title: (value) =>
        value.length < 2 ? "Title must have at least 2 letters" : null,
      policies: categories.reduce(
        (
          acc: Record<string, (value: string | number) => string | null>,
          category: TCategory
        ) => {
          acc[category.name] = (value) => {
            const numValue = Number(value);
            return isNaN(numValue) || numValue < 1
              ? `${category.name} value must be at least 1`
              : null;
          };
          return acc;
        },
        {}
      ),
    },
  });

  useEffect(() => {
    if (data) {
      const filteredPolicies = Object.keys(data.policies || {}).reduce(
        (acc: Record<string, number>, key) => {
          if (
            categories.some(
              (category: { name: string }) => category.name === key
            )
          ) {
            acc[key] = data.policies[key];
          }
          return acc;
        },
        {} as Record<string, number>
      );

      setValues({
        _id: data._id,
        title: data.title,
        policies: filteredPolicies,
      });
    } else {
      setValues({
        title: "",
        policies: categories.reduce(
          (
            acc: { [x: string]: number },
            category: { name: string | number }
          ) => {
            acc[category.name] = 0;
            return acc;
          },
          {}
        ),
      });
    }
  }, [setValues, data, categories]);

  const handleFormSubmit = useCallback(
    async (values: TLeavePolicy) => {
      const filteredPolicies = Object.keys(values.policies || {}).reduce(
        (acc: Record<string, number>, key) => {
          if (
            categories.some(
              (category: { name: string }) => category.name === key
            )
          ) {
            acc[key] = values.policies[key];
          }
          return acc;
        },
        {} as Record<string, number>
      );

      const updatedValues = { ...values, policies: filteredPolicies };

      const res = data
        ? await updateLeavePolicy(updatedValues)
        : await addLeavePolicy(updatedValues);

      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addLeavePolicy, toggle, reload, reset, updateLeavePolicy, data, categories]
  );

  const handelCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleModal: handelCloseModal,
        updateData: (d) => setData(d),
      };
    },
    [handelCloseModal]
  );

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: " all 0.2s ease-in-out 0s;",
          },
        },
        header: {
          zIndex: 1,
        },
      }}
      opened={opened}
      onClose={handelCloseModal}
      title={data ? "Edit Leave Policy" : "Add Leave Policy"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <Box>
          <TextInput
            label="Name"
            placeholder="Name"
            {...getInputProps("title")}
          />
          {categories.length > 0 ? (
            categories.map((category: TCategory) => (
              <Box key={category["_id"]} mt={6}>
                <NumberInput
                  {...getInputProps(`policies.${category.name}`)}
                  placeholder={`Enter total ${category["name"]} number`}
                  label={`${category["name"]}`}
                  withAsterisk
                  min={1}
                  styles={{
                    label: {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </Box>
            ))
          ) : (
            <div style={{ textAlign: "center", padding: "1rem" }}>
              No categories found
            </div>
          )}
        </Box>
        <Box mt={20}>
          <ThemeButton
            title="Submit"
            type="submit"
            loading={addLoading || updateLoading}
            disabled={addLoading || updateLoading}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default memo(forwardRef(LeavePolicyModel));
