import {
  Button,
  Modal,
  Select,
  TextInput,
  createStyles,
  Grid,
} from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
  ForwardedRef,
} from "react";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../../form/initial-value";

import { useUpdateEmpMappingMutation } from "../../../../hooks/empMapping/mutation/updateEmpMapping.mutation";
import { useGetAllProjects } from "../../../../hooks/projects/query/getAllProjects.query";
import { useGetAllStudent } from "../../../../hooks/students/query/getAllStudent.query";

import { TEmpMappingValues } from "../../../../form/initial-value/addEmpMapping.values";
import { TEmpMappingData } from "../../../../types/data/empMapping";
import { IconTrash } from "@tabler/icons-react";

export interface IEmpMappingModalRef {
  toggleModal: () => void;
  updateData: (Emp: TEmpMappingData) => void;
}
interface IEmpMappingModalProps {
  reload: () => void;
}

const EmpMappingModal = (
  props: IEmpMappingModalProps,
  ref: ForwardedRef<IEmpMappingModalRef>
) => {
  const { data: projectData } = useGetAllProjects({
    paging: { itemPerPage: 999, page: 1 },
  });
  const { data: empData } = useGetAllStudent({
    paging: { page: 1, itemPerPage: 999 },
    blocked: false,
  });
  const [projectBandwidth, setProjectBandwidth] = useState<
    { projectId: string; bandwidth: number }[]
  >([]);
  const [data, setData] = useState<TEmpMappingData | undefined>({
    employeeId: "",
    projects: projectBandwidth,
  });

  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: updateLoading, mutateAsync: updateEmpMapping } =
    useUpdateEmpMappingMutation();

  const { getInputProps, onSubmit, reset, setValues } = useForm({
    initialValues: initialValue.AddEmpMappingValues,
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (data) {
      setValues({
        ...data,
        employeeId: data?.employee?._id,
        projects: projectBandwidth,
      });
      setProjectBandwidth(
        data.projects?.map((item) => ({
          projectId: item.projectId,
          bandwidth: item.bandwidth || 0,
        })) || []
      );
    } else {
      setValues(initialValue.AddEmpMappingValues);
      setProjectBandwidth([]);
    }
  }, [data, setValues]);

  const handleProjectChange = (projectId: string, index: number) => {
    setProjectBandwidth((prev) =>
      prev.map((item, idx) => (idx === index ? { ...item, projectId } : item))
    );
  };

  const handleBandwidthChange = (projectId: string, value: number) => {
    setProjectBandwidth((prev) =>
      prev.map((item) =>
        item.projectId === projectId
          ? { projectId: item.projectId, bandwidth: value }
          : item
      )
    );
  };

  const handleFormSubmit = useCallback(
    async (values: TEmpMappingValues) => {
      const validProjects = projectBandwidth.filter(
        (item) => item.projectId && item.bandwidth > 0
      );
      if (validProjects.length === 0) {
        notifications.show({
          color: "red",
          message:
            "Please select a valid project and set a positive bandwidth.",
        });
        return;
      }

      const formData = {
        ...values,
        projects: validProjects,
      };

      const res = await updateEmpMapping(formData);

      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [toggle, reload, reset, updateEmpMapping, projectBandwidth, data]
  );

  const handleCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
    reset();
    setProjectBandwidth([]);
  }, [toggle, reset]);

  const handleAddProject = () => {
    setProjectBandwidth((prev) => [...prev, { projectId: "", bandwidth: NaN }]);
  };

  const handleRemoveProject = (index: number) => {
    setProjectBandwidth((prev) => prev.filter((_, i) => i !== index));
  };

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: handleCloseModal,
      updateData: (d) => setData(d),
    }),
    [handleCloseModal]
  );

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: "all 0.2s ease-in-out 0s",
          },
        },
        header: { zIndex: 1 },
      }}
      opened={opened}
      onClose={handleCloseModal}
      title={data ? "Edit EmpMapping" : "Add EmpMapping"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <Select
          label="Employee"
          name="employeeId"
          placeholder="Select Employee Name"
          data={
            empData?.data?.map((item) => ({
              label: item?.name ?? "",
              value: item?._id ?? "",
            })) ?? []
          }
          {...getInputProps("employeeId")}
          disabled={data && true}
          searchable={true}
        />

        {projectBandwidth.map((item, index) => {
          return (
            <Grid
              key={index}
              gutter="md"
              align="center"
              justify="space-between"
              style={{ minWidth: "100%" }}
            >
              <Grid.Col span={6}>
                <Select
                  label="Project"
                  placeholder="Select Project Name"
                  value={item.projectId}
                  data={
                    projectData?.data?.map((item) => ({
                      label: item?.name ?? "",
                      value: item?._id ?? "",
                    })) ?? []
                  }
                  onChange={(value) =>
                    handleProjectChange(value as string, index)
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextInput
                  label="Bandwidth"
                  placeholder="limit(1-100)"
                  value={item.bandwidth}
                  type="number"
                  onChange={(e) => {
                    handleBandwidthChange(
                      item.projectId,
                      Number(e.currentTarget.value)
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={2}
                style={{ alignSelf: "end", justifySelf: "stretch" }}
              >
                <Button
                  variant="outline"
                  color="red"
                  fullWidth
                  onClick={() => handleRemoveProject(index)}
                >
                  <IconTrash stroke={2} size={14} />
                </Button>
              </Grid.Col>
            </Grid>
          );
        })}

        <Button onClick={handleAddProject} variant="outline" fullWidth mt="md">
          Add Project
        </Button>
        <Button
          disabled={updateLoading}
          loading={updateLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          {data ? "Update EmpMapping" : "Add EmpMapping"}
        </Button>
      </form>
    </Modal>
  );
};

const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(EmpMappingModal));
