import { format } from "date-fns";

export const projectHolidayBackgroundColor = (
  date: Date,
  isHoliday: (date: string) => boolean
): string => {
  const formattedDate = format(date, "yyyy-MM-dd");

  if (isHoliday(formattedDate)) {
    return "#d90303a8";
  }

  return "";
};
