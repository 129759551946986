import { Box } from "@mantine/core";
import React, { FC, useMemo } from "react";
import { COLUMNS } from "../../../columns";
import CustomTable from "../../../component/table";
import { useGetSingleEmpWorks } from "../../../hooks/emp-work/query/getSingleEmpWork.query";

interface IEmpWork {
  selectedStudent: string | undefined;
  startDate: Date;
  endDate: Date;
}

const SingleEmpWorkDetail: FC<IEmpWork> = ({
  selectedStudent,
  endDate,
  startDate,
}) => {
  const { data, isLoading } = useGetSingleEmpWorks({
    startDate: startDate,
    endDate: endDate,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const tableColumns = [...COLUMNS.singleEmpWorkingDetail];

  const EmpWork: TSingleEmpWork[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box>
      <CustomTable
        isDashboardTable={false}
        loading={false}
        columns={tableColumns}
        data={EmpWork}
        grid={false}
        header={false}
      />
    </Box>
  );
};

export default SingleEmpWorkDetail;
