import { ActionIcon, Box, createStyles, Flex, rem } from "@mantine/core";
import React, { useMemo, useState } from "react";
import CustomTable from "../../../component/table";
import { COLUMNS } from "../../../columns";
import moment from "moment";
import { useGetAllProjectWorks } from "../../../hooks/project-work/query/getAllProjectWork.query";
import { COLORS } from "../../../assets/colors/index.colors";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import { allHolidayHook } from "../timeline/use-hook/allHolidayHook";

const ProjectWork = () => {
  const { classes } = useStyles();
  const inputDate = moment(new Date()).startOf("day").toDate();
  const endDates = moment(new Date()).endOf("day").toDate();
  const [date, setDate] = useState<Date | null>(inputDate);
  const [startDate, setStartDate] = useState<Date | null>(inputDate);
  const [endDate, setEndDate] = useState<Date | null>(endDates);

  const { data, isLoading } = useGetAllProjectWorks({
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
  });  

  const isHoliday = allHolidayHook();

  const tableColumns = [...COLUMNS.projectWork];

  const ProjectWork: TProjectWorkDetails = useMemo(() => {
    if (!isLoading && data && data.data) {
      return data.data.map((projectWork) => ({
        ...projectWork,
        isHoliday,
        startDate: startDate ?? new Date(),
      }));
    }
    return [];
  }, [isLoading, data, isHoliday, startDate]);

  const handleDateChange = (val: Date | null) => {
    if (val) {
      setDate(val);
      setStartDate(moment(val).startOf("day").toDate());
      setEndDate(moment(val).endOf("day").toDate());
    }
  };

  return (
    <Box className={classes.root}>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={ProjectWork}
        grid={false}
        headerProps={{
          search: false,
          rightComponent: (
            <Flex mih={50} gap="md">
              <Flex align={"center"} mr={6}>
                <ActionIcon
                  onClick={() =>
                    handleDateChange(moment(date).subtract(1, "day").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  mr={3}
                >
                  <IconChevronLeft size={16} color="black" />
                </ActionIcon>
                <DatePickerInput
                  maxDate={new Date()}
                  styles={{ input: { border: "none" } }}
                  icon={
                    <IconCalendar
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={1.5}
                    />
                  }
                  placeholder="Pick date"
                  value={date}
                  onChange={handleDateChange}
                />
                <ActionIcon
                  disabled={date ? moment(date).isSame(moment(), "day") : false}
                  onClick={() =>
                    handleDateChange(moment(date).add(1, "day").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  ml={3}
                >
                  <IconChevronRight size={16} color="black" />
                </ActionIcon>
              </Flex>
            </Flex>
          ),
        }}
      />
    </Box>
  );
};

export default ProjectWork;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
    borderRadius: 5,
  },
}));
