export type TEmpPointValues = {
  _id?: string;
  studentID: string;
  employee: string;
  email: string;
  totalPoints: number;
  date: Date;
  points: {
    point: number;
    title: string;
    description: string;
    extraData?: object;
  }[];
};

export const AddEmpPointValues: TEmpPointValues = {
  _id: "",
  studentID: "",
  employee: "",
  email: "",
  totalPoints: 0,
  date: new Date(),
  points: [],
};