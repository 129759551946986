import { Box, Group, Modal, Text } from "@mantine/core";
import React, { FC } from "react";
import classes from "./index.module.css";

interface IProps {
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
}

const TimeLineInfoModel: FC<IProps> = ({ modalOpen, setModalOpen }) => {
  return (
    <Modal
      opened={modalOpen}
      title="Timeline Info "
      onClose={() => setModalOpen(false)}
      centered
      transitionProps={{
        transition: "fade",
        duration: 600,
        timingFunction: "linear",
      }}
      classNames={{
        header: classes.header,
        title: classes.title,
        close: classes.close,
      }}
      closeOnClickOutside={false}
    >
      <Box my={20} mx={12}>
        <Group pb={16}>
          <Box className={classes.bgContainer}></Box>
          <Text fw={500}>User Added</Text>
        </Group>
        <Group pb={16}>
          <Box
            className={`${classes.bgContainer} ${classes.secondBgColor}`}
          ></Box>
          <Text fw={500}>Auto Generated </Text>
        </Group>
        <Group pb={16}>
          <Box
            className={`${classes.bgContainer} ${classes.holidayBgColor}`}
          ></Box>
          <Text fw={500}>Holiday </Text>
        </Group>
        <Group pb={16}>
          <Box
            className={`${classes.bgContainer} ${classes.leaveBgColor}`}
          ></Box>
          <Text fw={500}>Leave </Text>
        </Group>
        <Group pb={16}>
          <Box
            className={`${classes.bgContainer} ${classes.absentBgColor}`}
          ></Box>
          <Text fw={500}>Absent</Text>
        </Group>
      </Box>
    </Modal>
  );
};

export default TimeLineInfoModel;
