import React, { FC, memo } from "react";
import {
  Box,
  Text,
  Group,
  Divider,
  Center,
  Indicator,
  Tooltip,
  Grid,
  createStyles,
} from "@mantine/core";
import {
  IconCalendarEvent,
  IconClockPin,
  IconClockMinus,
  IconClockHour10,
  IconHelpHexagonFilled,
} from "@tabler/icons-react";
import moment from "moment";
import { totalTime } from "../../utils/attendance/totalTime";
import { generateTaskReportUrl } from "../../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";

interface IAttendanceCardItem {
  item: TAttendanceData | null;
  disabled: boolean;
  selectedStudent: string | null;
  formattedDate: string;
}

const AttendanceCardItem: FC<IAttendanceCardItem> = ({
  item,
  disabled,
  selectedStudent,
  formattedDate,
}) => {
  const { classes, cx } = useStyles();
  const getStatusStyle = () => {
    if (disabled) {
      return { color: "#cccccc", cardStatus: classes.disabled };
    }
    switch (item?.status) {
      case "on time":
        return { color: "#009900", cardStatus: classes.unblocked };
      case "late":
        return { color: "#f79009", cardStatus: classes.statusBoxLate };
      case "absent":
        return { color: "#FF0000", cardStatus: classes.blocked };
      default:
        return { color: "#008080", cardStatus: classes.statusBoxInitiate };
    }
  };

   const url = generateTaskReportUrl(item?.studentID ??"");

  return (
    <Grid.Col span={4}>
      <Box
        className={cx(classes.card, {
          [classes.disabled]: disabled,
        })}
        p="lg"
      >
        <Group position="apart" align="flex-start">
          <Box>
            <Group spacing="xs" align="center">
              <IconCalendarEvent stroke={1.75} size={16} />
              <Text fz="md" fw={500} c="dark" >
                {/* {item?.createdAt
                  ? moment(item?.createdAt).format("DD-MMM-YYYY")
                  : "no date"} */}
                {formattedDate}
              </Text>
            </Group>
            <Text fz="sm" fw={400} c="dimmed" component={Link} to={url}>
              {item?.name ?? selectedStudent}
            </Text>
          </Box>
          <Box className={classes.statusBox}>
            <Center>
              <Indicator
                inline
                size={10}
                position="middle-start"
                color={getStatusStyle().color}
                zIndex={20}
              >
                <Text ml={10} size="xs" weight={500}>
                  {item?.status ?? " No Status"}
                </Text>
              </Indicator>
            </Center>
            <Center>
              <Group spacing="xs" align="center" mr={12}>
                <IconClockHour10 stroke={2} size={12} />
                <Text fz="sm" fw={400} c="dimmed">
                  {item?.outTime ? totalTime(item.inTime, item.outTime) : "---"}
                </Text>
              </Group>
            </Center>
          </Box>
        </Group>

        <Divider my="sm" size="sm" />
        <Group position="apart" align="flex-start">
          <Group spacing="xs" align="center">
            <IconClockPin stroke={2} size={12} />
            <Text fz="sm" fw={400} c="dimmed" pos="relative">
              In Time:
              <Box pos="absolute" top="0" right="-18">
                {item?.inNote && (
                  <Tooltip
                    multiline
                    w={220}
                    withArrow
                    transitionProps={{ duration: 200 }}
                    label={item.inNote}
                    styles={{
                      tooltip: {
                        minWidth: "150px",
                        maxWidth: "300px",
                        wordWrap: "break-word",
                      },
                    }}
                  >
                    <IconHelpHexagonFilled
                      stroke={2}
                      size={16}
                      style={{ cursor: "pointer", color: "#007BFF" }}
                    />
                  </Tooltip>
                )}
              </Box>
            </Text>
          </Group>

          <Text ml={10} size="xs" mr={17} weight={500}>
            {item?.inTime ? moment(item.inTime).format("LT") : "--------"}
          </Text>
        </Group>
        <Group position="apart" align="center">
          <Group spacing="xs" align="center">
            <IconClockMinus stroke={2} size={12} />
            <Text fz="sm" fw={400} c="dimmed" pos="relative">
              Out Time:
              <Box pos="absolute" top={"0"} right={"-18"}>
                {item?.outNote && (
                  <Tooltip
                    multiline
                    w={220}
                    withArrow
                    transitionProps={{ duration: 200 }}
                    label={item.outNote}
                    styles={{
                      tooltip: {
                        minWidth: "150px",
                        maxWidth: "300px",
                        wordWrap: "break-word",
                      },
                    }}
                  >
                    <IconHelpHexagonFilled
                      stroke={2}
                      size={16}
                      style={{ cursor: "pointer", color: "#007BFF" }}
                    />
                  </Tooltip>
                )}
              </Box>
            </Text>
          </Group>

          <Text ml={10} size="xs" mr={17} weight={500}>
            {item?.outTime ? moment(item.outTime).format("LT") : "--------"}
          </Text>
        </Group>
      </Box>
    </Grid.Col>
  );
};

const useStyles = createStyles(() => ({
  card: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#E0E0E0",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    // borderTop: "4px solid  #0097ED",
  },
  unblocked: {
    borderColor: "#009900",
  },
  blocked: {
    borderColor: "#FF0000",
  },
  statusBox: {
    padding: "0.3rem 0.7rem",
    borderRadius: "4px",
  },
  statusBoxLate: {
    backgroundColor: "#fffaeb",
    color: "#f79009",
  },
  statusBoxInitiate: {
    backgroundColor: "#94ffff21",
    color: "#008080",
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

export default memo(AttendanceCardItem);
