import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getSingleProjectSprint = async (params: TQueryParams) => {
  const response: TServerResponse<TSprintData[], unknown> = await request({
    url: apiUrls.sprint.GET_SINGLE_PROJECT_SPRINT,
    method: "GET",
    params: {
      ...params.paging,
      projectId: params.projectId,
      search: params.searchParams?.search,
      searchFieldNumber: ["sprintId"],
      searchFieldString: ["name", "status", "projectId"],
    },
  });
  return response;
};

export const useGetSingleProjectSprint = (params: TQueryParams) => {
  return useQuery(["admin", "single-project-sprint", params], () =>
    getSingleProjectSprint(params)
  );
};
