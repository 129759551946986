import React, { useMemo } from "react";
import WorkLogApiInterface from "./components/WorkLogApiInterface";
import { useGetWorkLogApi } from "../../../hooks/project-api/query/getWorkLogApi.query";

const WorkLogApi = () => {
  const { data, isLoading } = useGetWorkLogApi();

  const workLog: TApiDocs[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data.map((project: TApiDocs) => ({
        ...project,
      }));
    }
    return [];
  }, [isLoading, data]);

  return <WorkLogApiInterface data={workLog} />;
};

export default WorkLogApi;
