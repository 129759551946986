import {
  Box,
  Card,
  Center,
  createStyles,
  Divider,
  Group,
  Indicator,
  Text,
} from "@mantine/core";
import moment from "moment";
import React from "react";
import { totalTime } from "../../../utils/attendance/totalTime";

interface IAttendanceCard {
  attendance: TAttendanceData[];
}

const AttendanceCard = ({ attendance }: IAttendanceCard) => {
  const { classes } = useStyles();
  return (
    <>
      {attendance.map((item) => (
        <Card
          key={item._id}
          shadow="md"
          radius="md"
          className={
            item.status === "absent"
              ? `${classes.card} ${classes.cardAbsent}`
              : item.status === "on time"
              ? `${classes.card} ${classes.cardOnTime}`
              : item.status === "leave"
              ? `${classes.card} ${classes.cardLeave}`
              : `${classes.card} ${classes.cardLate}`
          }
          padding="lg"
        >
          <Group style={{ display: "flex", justifyContent: "space-between" }}>
            <Text fz="sm" fw={500} color="#667085">
              {moment(item.createdAt).format("ddd, MMM DD, YYYY ")}
            </Text>
            <Box
              className={
                item.status === "absent"
                  ? `${classes.statusBox} ${classes.statusBoxAbsent}`
                  : item.status === "on time"
                  ? `${classes.statusBox} ${classes.statusBoxOnTime}`
                  : item.status === "leave"
                  ? `${classes.statusBox} ${classes.statusBoxLeave}`
                  : `${classes.statusBox} ${classes.statusBoxLate}`
              }
            >
              <Center>
                <Indicator
                  inline
                  size={8}
                  position="middle-start"
                  {...(item.status === "absent"
                    ? { color: "#FF0000" }
                    : item.status === "on time"
                    ? { color: "#53b1fd" }
                    : item.status === "leave"
                    ? { color: "#e412a0" }
                    : { color: "#f79009" })}
                >
                  <Text ml={10} size="xs" weight={500}>
                    {item.status}
                  </Text>
                </Indicator>
              </Center>
            </Box>
          </Group>
          <Divider my="sm" />
          <Group
            spacing="xl"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Text c="dimmed" weight={500} size="xs">
                Check In
              </Text>
              <Text fz="sm" weight={500}>
                {item.inTime ? moment(item.inTime).format("LT") : "---"}
              </Text>
            </Box>
            <Box>
              <Text c="dimmed" weight={500} size="xs">
                Check Out
              </Text>
              <Text fz="sm" weight={500}>
                {item.outTime ? moment(item.outTime).format("LT") : "---"}
              </Text>
            </Box>
            <Box>
              <Text c="dimmed" weight={500} size="xs">
                Total
              </Text>
              <Text fz="sm" weight={500}>
                {item.outTime ? totalTime(item.inTime, item.outTime) : "---"}
              </Text>
            </Box>
          </Group>
          <Divider my="sm" />
          <Group style={{ display: "flex", justifyContent: "space-between" }}>
            <Text fz="sm" fw={400} c="dimmed">
              In Note:
            </Text>
            <Text ml={10} size="xs" weight={500}>
              {item?.inNote}
            </Text>
          </Group>
          <Group style={{ display: "flex", justifyContent: "space-between" }}>
            <Text fz="sm" fw={400} c="dimmed">
              Out Note:
            </Text>
            <Text ml={10} size="xs" weight={500}>
              {item?.outNote}
            </Text>
          </Group>
        </Card>
      ))}
    </>
  );
};

export default AttendanceCard;

const useStyles = createStyles(() => ({
  card: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: "4px",
    borderRadius: 0,
  },
  cardOnTime: {
    borderColor: "#53b1fd",
  },
  cardLate: {
    borderColor: "#f79009",
  },
  cardLeave: {
    borderColor: "#e412a0",
  },
  cardAbsent: {
    borderColor: "#FF0000",
    color: "#868e96",
  },
  statusBox: {
    paddingBlock: "0.3rem",
    paddingInline: "0.7rem",
    borderRadius: "0.4rem",
  },

  statusBoxLeave: {
    backgroundColor: "#fbedf6",
    color: "#e412a0",
  },
  statusBoxAbsent: {
    backgroundColor: "#FFE5E5 ",
    color: "#FF0000",
  },
  statusBoxOnTime: {
    backgroundColor: "#eff8ff",
    color: "#53b1fd",
  },
  statusBoxLate: {
    backgroundColor: "#fffaeb",
    color: "#f79009",
  },
}));
