import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getSingleEmpWork = async (params: TQueryParams) => {
  const response: TServerResponse<TMonthlyWorkHourRecord[], unknown> = await request({
    url: apiUrls.empWorks.SINGLE_EMP_PROJECT_WORK,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.searchParams?.search,
      searchFieldObjectKey: ["studentID"],
    },
  });
  return response;
};

export const useGetSingleEmpProjectWork = (params: TQueryParams) => {
  return useQuery(["admin", "Single-emp-project-work", params], () =>
    getSingleEmpWork(params)
  );
};
