import React from "react";

export const LEAVE_POLICY_COLUMNS: TLeavePolicyTableColumns[] = [
  {
    key: "title",
    header: "Title",
    renderCell: (row: TLeavePolicy) => {
      const { title } = row;
      return `${title}`;
    },
  },
  {
    key: "policies",
    header: "Policies",
    renderCell: (row: TLeavePolicy) => {
      const { policies } = row;
      return (
        <div>
          {Object.entries(policies).map(([policyName, value]) => (
            <div key={policyName}>
              {policyName}: <span style={{ fontWeight: "600" }}>{value}</span>
            </div>
          ))}
        </div>
      );
    },
  },
];
