import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { TLossHourData } from "../../../types/data/workingLossHour";

const getWorkingLossHour = async (params: TAttendanceParams) => {
  const response: TServerResponse<TLossHourData[], unknown> = await request({
    url: apiUrls.workingHourLoss.WORKING_HOUR_LOSS,
    method: "GET",
    params: {
      date: params.date,
     
    },
  });
  return response;
};

export const useGetWorkingLossHour = (params: TAttendanceParams) => {
  return useQuery(
    ["workingHour", params],
    () => getWorkingLossHour(params),
    { keepPreviousData: true }
  );
};