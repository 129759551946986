import React, { useMemo } from "react";
import { useGetStoryApi } from "../../../hooks/project-api/query/getStoryApi.query";
import StoryApiInterface from "./components/SprintApiInterface";

const StoryApi = () => {
  const { data, isLoading } = useGetStoryApi();

  const Story: TApiDocs[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data.map((project: TApiDocs) => ({
        ...project,
      }));
    }
    return [];
  }, [isLoading, data]);

  return <StoryApiInterface data={Story} />;
};

export default StoryApi;
