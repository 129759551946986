interface SortableColumn {
    key: string;
    sortMethod?: (a: unknown, b: unknown) => number; 
  }
  
  export const hasKey = <T extends object>(
    obj: T,
    key: string
  ): key is Extract<keyof T, string> => {
    return key in obj;
  };
  
  export const sortDataForTable = (
    data: any[],
    columns: SortableColumn[],
    sortConfig: { key: string; direction: "asc" | "desc" }
  ) => {
    const { key, direction } = sortConfig;
    if (!key) return data;
  
    return [...data].sort((a, b) => {
      const column = columns.find(
        (col) => col.key === key && typeof col.sortMethod === "function"
      );
  
      if (hasKey(a, key) && hasKey(b, key)) {
        if (column?.sortMethod) {
          return direction === "asc"
            ? column.sortMethod(a[key], b[key])
            : column.sortMethod(b[key], a[key]);
        }
  
        const aValue = a[key];
        const bValue = b[key];
  
        if (typeof aValue === "number" && typeof bValue === "number") {
          return direction === "asc" ? aValue - bValue : bValue - aValue;
        }
        if (typeof aValue === "string" && typeof bValue === "string") {
          return direction === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
      }
      return 0;
    });
  };
  