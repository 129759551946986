import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getSingleProjectStory = async (params: TQueryParams) => {
  const response: TServerResponse<TStoryData[], unknown> = await request({
    url: apiUrls.story.GET_SINGLE_PROJECT_STORY,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      searchFieldNumber: ["storyId", "projectId"],
      searchFieldString: ["name", "status", "projectId"],
      projectId: params.projectId,
    },
  });
  return response;
};

export const useGetSingleProjectStory = (params: TQueryParams) => {
  return useQuery(["admin", "single-project-story", params], () =>
    getSingleProjectStory(params)
  );
};
