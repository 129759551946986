import { Accordion, Box, createStyles, getStylesRef } from "@mantine/core";
import { TablerIconsProps } from "@tabler/icons-react";
import React, { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
  burgerOpen: boolean;
  activeAccordion: string | null;
  setActiveAccordion: (value: string | null) => void;
  topNavLinkData: {
    label: string;
    icon: (props: TablerIconsProps) => JSX.Element;
    submenu: {
      link: string;
      label: string;
      icon: (props: TablerIconsProps) => JSX.Element;
    }[];
  }[];
}

const AccordionLink: FC<IProps> = ({
  burgerOpen,
  topNavLinkData,
  activeAccordion,
  setActiveAccordion,
}) => {
  const { classes, cx } = useStyles();
  return (
    <Box>
      <Accordion
        chevronPosition="right"
        variant="filled"
        value={activeAccordion}
        onChange={setActiveAccordion}
        classNames={
          burgerOpen
            ? { item: classes.accordionItem }
            : { item: classes.accordionActiveItem }
        }
      >
        {burgerOpen === false
          ? topNavLinkData.map((item, index) => (
              <Accordion.Item key={item.label + index} value={item.label}>
                <Accordion.Control
                  icon={
                    <item.icon stroke={2} className={classes.navLinkIcon} />
                  }
                >
                  {item.label}
                </Accordion.Control>
                <Accordion.Panel>
                  {item.submenu.map((submenu, i) => (
                    <Link
                      key={submenu.label + i}
                      className={cx(classes.link, {
                        [classes.linkActive]:
                          submenu.link === location.pathname,
                      })}
                      to={submenu.link}
                    >
                      {submenu.link === location.pathname && (
                        <Box className={classes.activeIcon}></Box>
                      )}
                      <submenu.icon
                        className={
                          submenu.link === location.pathname
                            ? classes.linkIconActive
                            : classes.linkIcon
                        }
                        stroke={1.5}
                      />
                      <span>{submenu.label}</span>
                    </Link>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            ))
          : topNavLinkData.map((item, index) => (
              <Accordion.Item key={item.label + index} value={item.label}>
                <Accordion.Control
                  icon={
                    <item.icon
                      stroke={2}
                      className={classes.navLinkIcon}
                      style={{ margin: 0 }}
                    />
                  }
                >
                  {item.label}
                </Accordion.Control>
                <Accordion.Panel>
                  {item.submenu.map((submenu, i) => (
                    <Link
                      key={submenu.label + i}
                      className={cx(classes.burgerCloseLink, {
                        [classes.burgerCloseLinkActive]:
                          submenu.link === location.pathname,
                      })}
                      to={submenu.link}
                    >
                      {submenu.link === location.pathname && (
                        <Box className={classes.activeIcon}></Box>
                      )}
                      <submenu.icon
                        className={
                          submenu.link === location.pathname
                            ? classes.burgerCloseIconActive
                            : classes.burgerCloseIcon
                        }
                        stroke={1.5}
                      />
                    </Link>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            ))}
      </Accordion>
    </Box>
  );
};

export default AccordionLink;

const useStyles = createStyles((theme) => ({
  accordionActiveItem: {
    "& .mantine-gm39d": {
      fontSize: "0.875rem",
      color: "#5b5e65",
      fontWeight: 700,
    },
  },
  accordionItem: {
    "& .mantine-gm39d": {
      display: "none !important",
    },
    "& .mantine-p2utts": {
      marginLeft: "0px !important",
    },
    "& .mantine-ecft5f": {
      padding: "0.8rem 0px !important",
      margin: "0.1rem 0rem !important",
    },
    "& .mantine-x6jxij": {
      margin: "0rem !important",
    },
    "& .mantine-731ldn ": {
      padding: "0.5rem !important",
    },
  },
  burgerCloseLink: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: "#5b5e65",
    marginBottom: ".2rem",
    height: 45,
    padding: 0,
    fontWeight: 700,
    textAlign: "center",

    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      transition: " all 0.2s ease-in-out 0s;",

      [`& .${getStylesRef("burgerCloseIcon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeBurgerCloseIcon")}`]: {
        color: "#ff008a",
        backgroundColor: "#ff008a",
      },
    },
  },

  burgerCloseIcon: {
    ref: getStylesRef("burgerCloseIcon"),
    color: "#ff008a ",
    marginLeft: "1.6rem",
  },

  burgerCloseLinkActive: {
    "&, &:hover": {
      backgroundColor: "#ff008a",
      paddingLeft: 4,
      color: "white",
      [`& .${getStylesRef("burgerCloseIcon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("burgerCloseIconActive")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "white",
        backgroundColor: "white",
      },
    },
  },

  burgerCloseIconActive: {
    ref: getStylesRef("burgerCloseIconActive"),
    color: "#ff008a",
    marginLeft: "0.9rem",
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: "#5b5e65",
    marginBottom: ".2rem",
    height: 45,
    padding: `${theme.spacing.sm} ${theme.spacing.xs}`,
    fontWeight: 700,

    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      transition: " all 0.2s ease-in-out 0s;",

      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "#ff008a",
        backgroundColor: "#ff008a",
      },
    },
  },
  activeIcon: {
    ref: getStylesRef("activeIcon"),
    width: "0.2rem",
    backgroundColor: "white",
    height: "2rem",
  },

  navLinkIcon: {
    ref: getStylesRef("icon"),
    color: "#ff008a",
    marginRight: theme.spacing.xs,
    marginLeft: "0.7rem",
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: "#ff008a ",
    marginRight: theme.spacing.xl,
    marginLeft: "1rem ",
  },

  linkIconActive: {
    ref: getStylesRef("linkIconActive"),
    color: "#ff008a",
    marginRight: theme.spacing.xl,
    marginLeft: "1.2rem ",
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("linkIconActive")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "white",
        backgroundColor: "white",
      },
    },
  },
}));
