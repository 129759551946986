import { number, object, string, array, date } from "yup";

export const AddEmpPointValidationSchema = object({
  studentID: string().required("Student ID is required"),
  employee: string().required("Employee name is required"),
  email: string().email("Invalid email format").required("Email is required"),
  totalPoints: number()
    .required("Total Points are required")
    .notOneOf([0], "Total Points cannot be 0"),
  date: date().required("Date is required"),
  points: array()
    .of(
      object({
        point:number()
        .required("Total Points are required")
        .notOneOf([0], "Total Points cannot be 0"),
        title: string().required("Point title is required"),
        description: string().required("Point description is required"),
      })
    )
    .required("Points array is required"),
});
