import { Button, Modal, createStyles } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
} from "react";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../../form/initial-value";
import { validations } from "../../../../form/validation";
import InputField from "../../../../component/form/input-field/InputField";
import SelectField from "../../../../component/form/select-field/SelectField";
import { TStoryValues } from "../../../../form/initial-value/story.values";
import { useAddStoryMutation } from "../../../../hooks/story/mutation/addStory.mutation";

export interface IStoryModalRef {
  toggleModal: () => void;
  updateData: (student: TStoryData) => void;
}
interface IStoryModalProps {
  reload: () => void;
}

const StoryModal = (
  props: IStoryModalProps,
  ref: ForwardedRef<IStoryModalRef>
) => {
  const [data, setData] = useState<TStoryData>();
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: addLoading, mutateAsync: addStory } =
    useAddStoryMutation();
  const { getInputProps, onSubmit, reset } = useForm({
    initialValues: initialValue.AddStoryValues,
    validate: yupResolver(validations.story),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleFormSubmit = useCallback(
    async (values: TStoryValues) => {
      const res = await addStory(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addStory, toggle, reload, reset, data]
  );
  const handelCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleModal: handelCloseModal,
        updateData: (d) => setData(d),
      };
    },
    [handelCloseModal]
  );
  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: " all 0.2s ease-in-out 0s;",
          },
        },
      }}
      opened={opened}
      onClose={handelCloseModal}
      title={"Add Story"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <InputField
          label="Story ID"
          name="storyId"
          getInputProps={getInputProps}
        />
        <InputField label="Name" name="name" getInputProps={getInputProps} />
        <InputField
          label="Project Id"
          name="projectId"
          getInputProps={getInputProps}
        />
        <InputField
          label="Progress"
          name="progress"
          getInputProps={getInputProps}
        />

        <SelectField
          label="Select Story Status"
          name="status"
          getInputProps={getInputProps}
        />

        <Button
          disabled={addLoading}
          loading={addLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          Add Story
        </Button>
      </form>
    </Modal>
  );
};
const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(StoryModal));
