import {
  Button,
  Modal,
  NumberInput,
  Select,
  createStyles,
} from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useMemo,
  useEffect,
} from "react";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../../form/initial-value";
import { validations } from "../../../../form/validation";
import InputField from "../../../../component/form/input-field/InputField";
import { TEmpPointValues } from "../../../../form/initial-value/empPoint.values";
import { useAddEmpPointMutation } from "../../../../hooks/empPoint/mutation/addEmpPoint.mutation";
import { DateInput } from "@mantine/dates";
import { useGetAllStudent } from "../../../../hooks/students/query/getAllStudent.query";

export interface IEmpPointModalRef {
  toggleModal: () => void;
}

interface IEmpPointModalProps {
  reload: () => void;
}

const EmpPointModal = (
  props: IEmpPointModalProps,
  ref: ForwardedRef<IEmpPointModalRef>
) => {
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: addLoading, mutateAsync: addEmpPoint } =
    useAddEmpPointMutation();

  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"],
        label: student["email"],
        employeeName: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const { getInputProps, onSubmit, reset, values, setValues } = useForm({
    initialValues: {
      ...initialValue.AddEmpPointValues,
      points: [{ point: 1, title: "", description: "" }],
    },

    validate: yupResolver(validations.empPoint),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleFormSubmit = useCallback(
    async (values: TEmpPointValues) => {
      const res = await addEmpPoint(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addEmpPoint, toggle, reload, reset]
  );

  const handleCloseModal = useCallback(() => {
    toggle();
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: handleCloseModal,
    }),
    [handleCloseModal]
  );

  const addPoint = () => {
    setValues({
      ...values,
      points: [...values.points, { point: 1, title: "", description: "" }],
    });
  };

  const removePoint = (index: number) => {
    if (values.points.length > 1) {
      setValues({
        ...values,
        points: values.points.filter((_, i) => i !== index),
      });
    }
  };

  const handleStudentChange = (selectedEmail: string) => {
    const selectedStudent = students.find((s) => s.value === selectedEmail);
    if (selectedStudent) {
      setValues({
        ...values,
        studentID: selectedEmail,
        employee: selectedStudent.employeeName,
        email: selectedStudent.label,
      });
    }
  };

  useEffect(() => {
    const totalPointsSum = values.points.reduce(
      (sum, item) => sum + (item.point || 0),
      0
    );

    setValues((prev) => ({
      ...prev,
      totalPoints: totalPointsSum,
    }));
  }, [values.points]);

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: "all 0.2s ease-in-out 0s",
          },
        },
      }}
      opened={opened}
      onClose={handleCloseModal}
      title={"Add Employee Points"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <Select
          label="Student ID"
          data={students}
          searchable
          {...getInputProps("studentID")}
          onChange={handleStudentChange}
        />

        <NumberInput
          disabled
          defaultValue={1}
          label="Total Points"
          precision={1}
          {...getInputProps("totalPoints")}
          mb={8}
          mt={8}
        />
        <DateInput
          label="Date input"
          maw={400}
          mx="auto"
          {...getInputProps("date")}
          mb={8}
        />
        <div>
          {values.points.map((_, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <NumberInput
                label="Point Value"
                precision={1}
                {...getInputProps(`points.${index}.point`)}
              />
              <InputField
                label="Point Title"
                name={`points.${index}.title`}
                getInputProps={getInputProps}
              />
              <InputField
                label="Point Description"
                name={`points.${index}.description`}
                getInputProps={getInputProps}
              />
              {values.points.length > 1 && (
                <Button
                  color="red"
                  onClick={() => removePoint(index)}
                  mt={8}
                  size="xs"
                >
                  Remove
                </Button>
              )}
            </div>
          ))}
          <Button onClick={addPoint} variant="outline" fullWidth mt="sm">
            + Add Point
          </Button>
        </div>

        <Button
          disabled={addLoading}
          loading={addLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          {"Add Employee Points"}
        </Button>
      </form>
    </Modal>
  );
};

const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(EmpPointModal));
