import React, { memo } from "react";
import { openTaskLink } from "../../helper/openTaskLink";
import { Badge, Flex } from "@mantine/core";
import { dateFormatWithTime } from "../../helper/dateFormatWithTime";

interface Props {
  task: IWorkingTaskContent;
}

const TaskCard: React.FC<Props> = ({ task }) => {
  return (
    <a
      href={openTaskLink(task?.task_id as number)}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none", color: "#000" }}
    >
      <Flex align={"center"}>
        <div style={{ fontSize: 12, color: "#000", fontWeight: "bold" }}>
          Task Id: {task?.content.task_id ?? "NA "}
        </div>
        <Badge ml={8}>{dateFormatWithTime(task.updatedAt)}</Badge>
      </Flex>
      <div>{task?.content.task_name ?? "NA"}</div>
    </a>
  );
};

export default memo(TaskCard);
