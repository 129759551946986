import {
  Box,
  Flex,
  Group,
  Input,
  Select,
  Text,
  createStyles,
} from "@mantine/core";
import {
  IconCaretLeftFilled,
  IconCaretRightFilled,
  IconSearch,
} from "@tabler/icons-react";
import { startOfDay } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "../../assets/colors/index.colors";
import { COLUMNS } from "../../columns";
import CustomTable from "../../component/table";
import { useGetAllProjects } from "../../hooks/projects/query/getAllProjects.query";
import { useGetAllTaskByProjectID } from "../../hooks/projects/query/getProjectSpecificTask.query";
import { handleDateTypeChange } from "../works/task-report/helper/handleDateTypeChange";
import { updateDateRange } from "../works/task-report/helper/updateDateRange";
import { useDebouncedValue } from "@mantine/hooks";

interface IProps {
  name?: string;
}

const ProjectInfo: React.FC<IProps> = () => {
  const { id } = useParams();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const tableColumns = [...COLUMNS.taskColumns];

  // State management
  const [dateType, setDateType] = useState<string | null>("Month");
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [search, setSearch] = useState<string>("");
  const [filteredData, setFilteredData] = useState<EmployeeWorkData[]>([]);
  const [debounced] = useDebouncedValue(search, 200);

  // Date range state
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf("month").toDate()
  );

  const { data: projects, isLoading: isProjectsLoading } = useGetAllProjects({
    paging: { itemPerPage: 30, page: 1 },
  });

  const [project, setSelectedProject] = useState<string>("");

  const { data: taskData, isLoading: isTasksLoading } =
    useGetAllTaskByProjectID({
      projectId: Number(id),
      startDate,
      endDate,
      paging: { itemPerPage: 30, page: activePage },
    });

  useEffect(() => {
    if (!projects?.data?.length) return;

    if (!id || id === ":id") {
      const firstProjectId = projects.data[0].projectId;
      navigate(`/project-info/${firstProjectId}`, { replace: true });
    } else {
      const projectName = projects.data.find((proj) => proj.projectId === id);
      setSelectedProject(projectName?.name ?? "");
    }
  }, [id, projects, navigate]);


  const handleProjectChange = (value: string | null) => {
    if (!value) return;
    setSelectedProject(value);

    const selectedProject = projects?.data?.find((proj) => proj.name === value);
    if (selectedProject) {
      navigate(`/project-info/${selectedProject.projectId}`, { replace: true });
    }
  };


  useEffect(() => {
    if (!taskData?.data) return;
    setPagedData(taskData.pageData);
    setFilteredData(taskData.data);
  }, [taskData]);

  useEffect(() => {
    if (!debounced) {
      setFilteredData(taskData?.data || []);
      return;
    }

    setFilteredData(
      (prev) =>
        prev.filter(
          (e) =>
            e.empEmail?.toLowerCase().includes(debounced.toLowerCase()) ||
            e.empName?.toLowerCase().includes(debounced.toLowerCase())
        ) || []
    );
  }, [debounced, taskData]);

  return (
    <div>
      <Group mb="100" position="apart">
        <Group>
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap="sm"
            align="center"
          >
            <Input
              value={search}
              placeholder="Search by name"
              onChange={(e) => setSearch(e.target.value)}
              icon={<IconSearch size={18} />}
            />
          </Flex>

          {projects && !isProjectsLoading && (
            <Select
              data={projects?.data?.map((item) => item.name) || []}
              value={project}
              onChange={handleProjectChange}
              searchable
            />
          )}
        </Group>

        <Group>
          <Box w={100}>
            <Select
              defaultValue="Month"
              data={["Day", "Month"]}
              value={dateType}
              onChange={(value) => {
                if (value) {
                  handleDateTypeChange(
                    value,
                    setDateType,
                    setStartDate,
                    setEndDate
                  );
                }
              }}
            />
          </Box>

          <Group>
            <button
              className={classes.arrowContainer}
              onClick={() =>
                updateDateRange(
                  "prev",
                  startDate,
                  dateType,
                  setStartDate,
                  setEndDate
                )
              }
            >
              <IconCaretLeftFilled
                className={classes.linkIconActive}
                stroke={1.5}
              />
            </button>

            <Box className={classes.dateContainer}>
              <Text size="sm" fw={500}>
                {moment(startDate).format("DD MMM YYYY")} -{" "}
                {moment(endDate).format("DD MMM YYYY")}
              </Text>
            </Box>

            <button
              className={classes.arrowContainer}
              onClick={() =>
                updateDateRange(
                  "next",
                  startDate,
                  dateType,
                  setStartDate,
                  setEndDate
                )
              }
              disabled={endDate >= startOfDay(new Date())}
            >
              <IconCaretRightFilled
                className={classes.linkIconActive}
                stroke={1.5}
              />
            </button>
          </Group>
        </Group>
      </Group>

      <Box mt="200">
        <CustomTable
          isDashboardTable={false}
          loading={isProjectsLoading || isTasksLoading}
          columns={tableColumns}
          data={filteredData}
          grid={false}
          paginationProps={{
            setPage: setActivePage,
            totalPages: pagedData.total,
          }}
          header={false}
        />
      </Box>
    </div>
  );
};

export default ProjectInfo;

// Styles
const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    display: "flex",
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
  },
}));
