import React from "react";
import { openProjectLink } from "../helper/openProjectLink";
import { openTaskLink } from "../helper/openTaskLink";

export const STORY_COLUMNS: TStoryTableColumns[] = [
  {
    key: "storyId",
    header: "Story Id",
    renderCell: (row: TStoryData) => {
      const { storyId } = row;
      return (
        <div>
          <a
            href={openTaskLink(Number(storyId))}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            {storyId}
          </a>
        </div>
      );
    },
  },
  {
    key: "name",
    header: "Name",
    renderCell: (row: TStoryData) => {
      const { name, storyId } = row;
      return (
        <div>
          <a
            href={openTaskLink(Number(storyId))}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            {name}
          </a>
        </div>
      );
    },
  },
  {
    key: "projectId",
    header: "Project ID",
    renderCell: (row: TStoryData) => {
      const { projectId } = row;
      return (
        <div>
          <a
            href={openProjectLink(Number(projectId))}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            {projectId}
          </a>
        </div>
      );
    },
  },
  {
    key: "progress",
    header: "Progress",
    renderCell: (row: TStoryData) => {
      const { progress } = row;
      return `${progress} %`;
    },
  },
  { key: "status", header: "Status" },
];
