import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { TSprintValues } from "../../../form/initial-value/sprint.values";

const AddSprint = async (data: TSprintValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.sprint.CREATE_Sprint,
    method: "POST",
    data: data,
  });
  return response;
};

export const useAddSprintMutation = () => {
  return useMutation(AddSprint);
};
