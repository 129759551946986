import React from "react";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";
import { Text } from "@mantine/core";
import { Link } from "react-router-dom";

export const WORKING_HOUR_COLUMNS: TWorkingLossHourTableColumns[] = [
  {
    key: "name",
    header: "Employees",

     renderCell: (row) => {
          const { name, _id } = row;
          const url = generateTaskReportUrl(_id ?? "");
          return (
            <Text color={"#000"} weight={"500"} component={Link} to={url}>
              {name}
            </Text>
          );
        },
    
  },
  {
    key: "totalLossHours",
    header: "Working Loss Hour",
    renderCell: (value) => {
      const hours = parseFloat(value?.totalLossHours);
      const isPositive = hours > 0;
      const displayHours = Math.abs(hours).toFixed(2);

      return (
        <span
          style={{
            fontWeight: "600",
            color: isPositive ? "red" : "blue",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {isPositive ? (
            <IconArrowDown size={16} />
          ) : (
            <IconArrowUp size={16} />
          )}
          {displayHours} hrs
        </span>
      );
    },
  },
];
