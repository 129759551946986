import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async () => {
  const response: TServerResponse<TLessWorkHourEmployee[], unknown> = await request({
    url: apiUrls.GET_LESS_WORK_HOUR_EMPLOYEES,
    method: "GET",
  });
  return response;
};

export const useGetLessWorkHourEmployees = () => {
  return useQuery(["get-less-work-hour-employee"],()=> get(), {
    keepPreviousData: true,
  });
};




