import React from "react";
import { Badge, Box, Text } from "@mantine/core";
import { dateFormat } from "../helper/dataFormat";
import { handleEmailClick } from "../helper/handleEmailClick";
import { Link, useNavigate } from "react-router-dom";
import { generateTaskReportUrl } from "../helper/generateTaskReportUrl";

export const STUDENTS_COLUMNS = (): TTableColumns[] => {
  const navigate = useNavigate();

  return [
    { key: "rollNumber", header: "Emp Id" },
    {
      key: "name",
      header: "Name",
      renderCell(values) {
        const url = generateTaskReportUrl(values._id ?? "");
        return (
          <Box>
            <Text
              fw={values.remoteEmployee ? "bold" : "normal"}
              component={Link}
              to={url}
            >
              {values.name}
            </Text>

            <Text
              size="sm"
              onClick={() => handleEmailClick(values.email, navigate)}
              style={{ cursor: "pointer" }}
            >
              {values.email}
            </Text>
            <Text fw={"light"} size="sm" style={{ opacity: 0.5 }}>
              {values.mobile}
            </Text>
          </Box>
        );
      },
    },
    {
      key: "remote",
      header: "Remote Employee",
      renderCell(values) {
        return values.remoteEmployee ? (
          <Badge size="xs" style={{ color: "white" }} bg="#ff008a">
            Remote Employee
          </Badge>
        ) : (
          <Text size="xs" color="dimmed">
            No
          </Text>
        );
      },
    },
    {
      key: "dob",
      header: "DOB",
      renderCell(values) {
        return <Text>{dateFormat(values.dob)}</Text>;
      },
    },
    { key: "role", header: "Role" },
    {
      key: "leaves",
      header: "Leaves & Absent",
      renderCell(values) {
        const totalAbsent = values.employAttendance?.length ?? 0;
        if (!values.leaves?.length) {
          return (
            <Box>
              <Text size="sm" color="dimmed">
                No leaves
              </Text>
              <Text size="sm">
                {totalAbsent > 0 ? `Absent (${totalAbsent})` : "No Absent"}
              </Text>
            </Box>
          );
        }

        const acceptedLeaves = values.leaves.filter(
          (leave) => leave.status === "accepted"
        );

        const leaveCounts = acceptedLeaves.reduce((acc, leave) => {
          const leaveValue =
            leave.type === "fullday"
              ? leave.dates.length
              : leave.dates.length * 0.5;
          return {
            ...acc,
            [leave.categoryId]: (acc[leave.categoryId] || 0) + leaveValue,
          };
        }, {} as Record<string, number>);

        return (
          <Box>
            {Object.entries(leaveCounts).map(([categoryId, count]) => {
              const leaveCategory = values.leaveCategoriesName?.find(
                (category) => category._id === categoryId
              );
              return (
                <Box key={categoryId}>
                  <Text fw="normal">
                    {leaveCategory ? leaveCategory.name : "Unknown Leave"} (
                    {count})
                  </Text>
                </Box>
              );
            })}
            <Text size="sm">
              {totalAbsent > 0 ? `Absent (${totalAbsent})` : "No Absent"}
            </Text>
          </Box>
        );
      },
    },
  ];
};
