import React from "react";
import {  Text } from "@mantine/core";
import { IWorkHourLoss } from "./definiation";
import { getHourLoss, getHourLossPercentage } from "./utils";
import AttendanceCount from "./components/AttendanceCount";
import { generateTaskReportUrl } from "../../helper/generateTaskReportUrl";
import { Link } from "react-router-dom";

interface IWorkHourColumns {
  key: string;
  header: string;
  renderCell?: (row: IWorkHourLoss) => void;
  minWidth?: number;
}

export const workHourLossColumn: IWorkHourColumns[] = [
  {
    key: "name",
    header: "Name",
    renderCell: (e: IWorkHourLoss) => {
      const url = generateTaskReportUrl(e._id ?? "");
      return (
        <Text tt={"capitalize"} component={Link} to={url}>
          {e.name}
        </Text>
      );
    },
  },
  {
    key: "time_spend",
    header: "Time Spend",
    renderCell: (e: IWorkHourLoss) => <Text>{e.time_spend.toFixed(2)}</Text>,
  },
  {
    key: "Loss_Hours",
    header: "Loss Hour",
    renderCell: (e) => <Text>{getHourLoss(e).toFixed(2)}</Text>,
  },
  {
    key: "Percentage",
    header: "Loss Percentage",
    renderCell: (e) => {
      const percentage = getHourLossPercentage(e);
      return (
        <Text fw={500} c={percentage > 10 ? "red" : "green"}>
          {percentage.toFixed(2)}%
        </Text>
      );
    },
  },
  {
    key: "att",
    header: "Attendance",
    renderCell: (e) => <AttendanceCount attendanceCount={e.attendanceCount} />,
  },
];
