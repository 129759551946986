import { Box, Grid } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { CONSTANTS } from "../../../constant";

import NotificationCardItem from "./components/NotificationCardItem";
import { useGetAllNotifications } from "../../../hooks/notification/query/useGetAllNotifications.query";
import ThemePagination from "../../../component/table/pagination/ThemePagination";

const NotificationLogs = () => {
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });

  const { data, isLoading } = useGetAllNotifications({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
  });

  const notifications: TNotificationData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }
      return data.data;
    }
    return [];
  }, [isLoading, data]);

  return (
    <>
      <Grid>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationCardItem key={notification._id} item={notification} />
          ))
        ) : (
          <Box>No notifications found.</Box>
        )}
      </Grid>
      <Box>
        <ThemePagination setPage={setActivePage} totalPages={pagedData.total} />
      </Box>
    </>
  );
};

export default NotificationLogs;
