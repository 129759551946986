import { ActionIcon, Box, Flex, rem } from "@mantine/core";
import React, { useState } from "react";
import CustomTable from "../../component/table";
import { COLUMNS } from "../../columns";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { MonthPickerInput } from "@mantine/dates";
import moment from "moment";
import { useGetWorkingLossHour } from "../../hooks/workingLossHour/query/workingLossHour.query"; // adjust path if needed

const OfficeLoss = () => {
  const [month, setMonth] = useState<Date | null>(new Date());

  const { data, isLoading } = useGetWorkingLossHour({
  date: month ?? undefined
  });

  const handleMonthChange = (val: Date | null) => {
    if (val) {
      setMonth(val);
    }
  };
  

  return (
    <Box>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={COLUMNS.workingloss}
        data={data?.data || []}
        grid={false}
        headerProps={{
          search: false,
          rightComponent: (
            <Flex mih={50} gap="md">
              <Flex align={"center"} mr={6}>
                <ActionIcon
                  onClick={() =>
                    handleMonthChange(moment(month).subtract(1, "month").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  mr={3}
                >
                  <IconChevronLeft size={16} color="black" />
                </ActionIcon>

                <MonthPickerInput
                  maxDate={new Date()}
                  styles={{ input: { border: "none" } }}
                  icon={
                    <IconCalendar
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={1.5}
                    />
                  }
                  placeholder="Pick month"
                  value={month}
                  onChange={handleMonthChange}
                />

                <ActionIcon
                  disabled={moment(month).isSame(moment(), "month")}
                  onClick={() =>
                    handleMonthChange(moment(month).add(1, "month").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  ml={3}
                >
                  <IconChevronRight size={16} color="black" />
                </ActionIcon>
              </Flex>
            </Flex>
          ),
        }}
      />
    </Box>
  );
};

export default OfficeLoss;
