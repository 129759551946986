import { Box, Stack, Grid } from "@mantine/core";
import React, { FC, memo, useMemo } from "react";
import ThemeLoader from "../../../component/loader/ThemeLoader";
import NoData from "../../../component/no-data/NoData";
import AttendanceCardItem from "../../../component/attendance-card-item/AttendenceCardItem";
import moment from "moment";
import { dateFormat } from "../../../helper/dataFormat";
interface IAttendanceCard {
  loading: boolean;
  attendance: TAttendanceData[];
  month: Date;
  selectedStudent: string | null;
}
const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};
const AttendanceCard: FC<IAttendanceCard> = ({
  loading,
  attendance,
  month,
  selectedStudent,
}) => {
  const today = new Date();
  const totalDays = getDaysInMonth(month.getFullYear(), month.getMonth());
  const currentDay =
    month.getMonth() === today.getMonth() ? today.getDate() : totalDays;
  const dayCards = useMemo(() => {
    return Array.from({ length: totalDays }, (_, index) => {
      const day = index + 1;
      const isDisabled = day > currentDay;

      const attendanceRecord =
        attendance.find(
          (record) => new Date(record.createdAt).getDate() === day
        ) || null;

      const formattedDate = attendanceRecord
        ? dateFormat(attendanceRecord.createdAt)
        : dateFormat(new Date(month.getFullYear(), month.getMonth(), day));

      return (
        <AttendanceCardItem
          key={`${index}`}
          item={attendanceRecord}
          disabled={isDisabled}
          selectedStudent={selectedStudent}
          formattedDate={formattedDate}
        />
      );
    });
  }, [totalDays, currentDay, attendance]);
  return (
    <Box>
      <Box mt={20}>
        <Stack mt={20} bg="var(--mantine-color-blue-light)">
          {loading ? (
            <ThemeLoader loading={loading} />
          ) : (
            <Grid gutter="lg">{dayCards}</Grid>
          )}

          {!loading && attendance.length === 0 && (
            <NoData title="No Attendance Available" />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default memo(AttendanceCard);
