const priorityOrder = ["absent", "leave", "holiday"];

const getPriority = (entry: IWorkingTasks) => {
  const status = entry.attendance?.status || "";
  const inTime = entry.attendance?.inTime;

  if (priorityOrder.includes(status)) return 1;
  if (status === "initiate" && !inTime) return 1;
  return 0;
};

export const filterAndSortWorkingTaskData = (
  data: IWorkingTasks[],
  search: string
) => {
  return data
    .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      return getPriority(a) - getPriority(b);
    });
};
