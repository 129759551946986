import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllProjectWork = async (params: TQueryParams) => {
  const response: TServerResponse<TProjectWorkDetails, unknown> = await request(
    {
      url: apiUrls.projectWorks.ALL_PROJECT_WORK,
      method: "GET",
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    }
  );
  return response;
};

export const useGetAllProjectWorks = (params: TQueryParams) => {
  return useQuery(["admin", "all-projectWork", params], () =>
    getAllProjectWork(params)
  );
};
