import React from "react";
import { openTaskLink } from "../helper/openTaskLink";
import { statusColor } from "../helper/statusColor";
import { dateFormatWithTime } from "../helper/dateFormatWithTime";
import { openProjectLink } from "../helper/openProjectLink";

interface IColumn {
  key: string;
  header: string;
  renderCell?: (row: ITaskStatus) => React.ReactNode;
  minWidth?: number;
}

export const TASK_STATUS_COLUMNS: IColumn[] = [
  {
    key: "project_name",
    header: "Project Name",
    renderCell: (row: ITaskStatus) => {
      const { content } = row;
      return (
        <a
          href={openProjectLink(content.project_id)}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div style={{ fontSize: 12, color: "#000", fontWeight: "bold" }}>
            Id: {content.project_id}
          </div>
          <div>{content?.project_name ?? "NA"}</div>
        </a>
      );
    },
  },

  {
    key: "content.task_name",
    header: "Task Name",
    renderCell: (row: ITaskStatus) => {
      const { content } = row;
      return (
        <a
          href={openTaskLink(content.task_id)}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div style={{ fontSize: 12, color: "#000", fontWeight: "bold" }}>
            Id: {content.task_id}
          </div>
          <div>{content?.task_name ?? "NA"}</div>
        </a>
      );
    },
    minWidth: 180,
  },
  {
    key: "content.status_update_by",
    header: "User Name",
    renderCell: (row: ITaskStatus) => {
      const { content } = row;
      return `${content?.status_update_by ?? "NA"}`;
    },
  },
  {
    key: "work_hours",
    header: "Work Hours",
    renderCell: (row: ITaskStatus) => {
      const { content } = row;
      const formattedSpendTime = Number(content?.work_hours ?? 0).toFixed(2);
      return `${formattedSpendTime} h`;
    },
  },
  {
    key: "createdAt",
    header: "Create At",
    renderCell: (row: ITaskStatus) => {
      const { createdAt } = row;
      const date = new Date(createdAt);
      const formattedDate = dateFormatWithTime(date);
      return <div>{formattedDate}</div>;
    },
    minWidth: 140,
  },
  {
    key: "updatedAt",
    header: "Updated At",
    renderCell: (row: ITaskStatus) => {
      const { updatedAt } = row;
      const date = new Date(updatedAt);
      const formattedDate = dateFormatWithTime(date);
      return <div>{formattedDate}</div>;
    },
    minWidth: 140,
  },
  {
    key: "status_name",
    header: "Status",
    renderCell: (row: ITaskStatus) => {
      const { content } = row;
      const statusColorObj = statusColor.find(
        (item) => item.status === content?.status_name
      );
      const bgColor = statusColorObj ? statusColorObj.color : "#ebe868";

      return (
        <div
          style={{
            backgroundColor: bgColor,
            padding: "5px",
            borderRadius: "4px",
            textAlign: "center",
            color: "#000",
            fontWeight: "500",
          }}
        >
          {content?.status_name ?? "NA"}
        </div>
      );
    },
    minWidth: 140,
  },
];
