import { Button, Modal, createStyles } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
} from "react";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../../form/initial-value";
import { validations } from "../../../../form/validation";
import InputField from "../../../../component/form/input-field/InputField";
import SelectField from "../../../../component/form/select-field/SelectField";
import { TSprintValues } from "../../../../form/initial-value/sprint.values";
import { useAddSprintMutation } from "../../../../hooks/sprint/mutation/addSprint.mutation";

export interface ISprintModalRef {
  toggleModal: () => void;
  updateData: (student: TSprintData) => void;
}
interface ISprintModalProps {
  reload: () => void;
}

const SprintModal = (
  props: ISprintModalProps,
  ref: ForwardedRef<ISprintModalRef>
) => {
  const [data, setData] = useState<TSprintData>();
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: addLoading, mutateAsync: addSprint } =
    useAddSprintMutation();
  const { getInputProps, onSubmit, reset } = useForm({
    initialValues: initialValue.AddSprintValues,
    validate: yupResolver(validations.sprint),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleFormSubmit = useCallback(
    async (values: TSprintValues) => {
      const res = await addSprint(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addSprint, toggle, reload, reset, data]
  );
  const handelCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleModal: handelCloseModal,
        updateData: (d) => setData(d),
      };
    },
    [handelCloseModal]
  );

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: " all 0.2s ease-in-out 0s;",
          },
        },
      }}
      opened={opened}
      onClose={handelCloseModal}
      title={data ? "Edit Sprint" : "Add Sprint"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <InputField
          label="Sprint ID"
          name="sprintId"
          getInputProps={getInputProps}
        />
        <InputField
          label="Name"
          name="name"
          getInputProps={getInputProps}
          disabled={data && true}
        />
        <InputField
          label="Project Id"
          name="projectId"
          getInputProps={getInputProps}
          disabled={data && true}
        />
        <InputField
          label="Progress"
          name="progress"
          getInputProps={getInputProps}
          disabled={data && true}
        />

        <SelectField
          label="Select Sprint Status"
          name="status"
          getInputProps={getInputProps}
        />

        <Button
          disabled={addLoading}
          loading={addLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          {data ? "Update Project" : "Add Project"}
        </Button>
      </form>
    </Modal>
  );
};
const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(SprintModal));
