import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getSprintApi = async () => {
  const response: TServerResponse<TApiDocs[], unknown> = await request({
    url: apiUrls.projectApi.GET_SPRINT_API,
    method: "GET",
  });
  return response;
};

export const useGetSprintApi = () => {
  return useQuery(["api", "get-sprint-api"], () => getSprintApi());
};
