import React, { memo, useEffect, useMemo, useState } from "react";
import { COLORS } from "../../assets/colors/index.colors";
import { Box, createStyles, Group, Select, Text } from "@mantine/core";
import moment from "moment";
import { useGetAllProjects } from "../../hooks/projects/query/getAllProjects.query";
import { IconCaretLeftFilled, IconCaretRightFilled } from "@tabler/icons-react";
import { updateDateRange } from "../works/task-report/helper/updateDateRange";
import { startOfDay } from "date-fns";
import { useMonthWiseProjectTask } from "../../hooks/projects/query/getMonthWiseAllTasksForProject.query";
import { allHolidayHook } from "../works/timeline/use-hook/allHolidayHook";
import CustomTable from "../../component/table";
import { COLUMNS } from "../../columns";

const ProjectTaskMonthWise: React.FC = () => {
  const { classes } = useStyles();
  const [startDate, setStartDate] = useState<Date>(
    moment(new Date()).startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment(new Date()).endOf("month").toDate()
  );

  const { data: projects, isLoading } = useGetAllProjects({
    paging: { itemPerPage: 30, page: 1 },
  });

  const handleProjectChange = (value: string) => {
    const selectedProject = projects?.data?.find((proj) => {
      return proj.name === value;
    });

    setSelectedProject(selectedProject as TProjectsData);
  };

  const [selectedProject, setSelectedProject] = useState<TProjectsData | null>(
    null
  );

  const { data: taskData, isLoading: isTasksLoading } = useMonthWiseProjectTask(
    {
      projectId: parseInt(selectedProject?.projectId as string),
      startDate,
      endDate,
    }
  );

  useEffect(() => {
    if (projects && projects?.data?.length) {
      const firstProject = projects?.data[0];
      setSelectedProject(firstProject);
    }
  }, [projects]);

  const checkIsHoliday = allHolidayHook();

  const tasks: TMonthWiseTask[] = useMemo(() => {
    if (!isTasksLoading && taskData) {
      const today = moment();
      return taskData.data
        .map((proj: TMonthWiseTask) => {
          return {
            ...proj,
            isHoliday: checkIsHoliday,
          };
        })
        .filter((item: TMonthWiseTask) => {
          return moment(item.date).isSameOrBefore(today);
        });
    }
    return [];
  }, [isTasksLoading, taskData]);

  const columns = [...COLUMNS.monthsWiseTasks];

  return (
    <div>
      <Group mb={"100"} position="apart">
        {projects && !isLoading && (
          <Select
            data={
              projects?.data?.map((item) => {
                return item.name;
              }) || []
            }
            value={selectedProject?.name}
            onChange={handleProjectChange}
            searchable
          />
        )}

        <Group>
          <Group>
            <button
              className={classes.arrowContainer}
              onClick={() =>
                updateDateRange(
                  "prev",
                  startDate,
                  "Month",
                  setStartDate,
                  setEndDate
                )
              }
            >
              <IconCaretLeftFilled
                className={classes.linkIconActive}
                stroke={1.5}
              />
            </button>
            <Box className={classes.dateContainer}>
              <Text size="sm" fw={500}>
                {startDate ? moment(startDate).format("DD MMM YYYY") : ""} -
                {endDate ? moment(endDate).format("DD MMM YYYY") : ""}
              </Text>
            </Box>
            <button
              className={classes.arrowContainer}
              onClick={() =>
                updateDateRange(
                  "next",
                  startDate,
                  "Month",
                  setStartDate,
                  setEndDate
                )
              }
              disabled={endDate ? endDate >= startOfDay(new Date()) : false}
            >
              <IconCaretRightFilled
                className={classes.linkIconActive}
                stroke={1.5}
              />
            </button>
          </Group>
        </Group>
      </Group>
      <Box mt="200">
        <CustomTable
          isDashboardTable={false}
          loading={isLoading}
          columns={columns}
          data={tasks}
          grid={false}
          header={false}
        />
      </Box>
    </div>
  );
};

export default memo(ProjectTaskMonthWise);

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
  bgColor: {
    width: 12,
    height: 12,
    borderRadius: 50,
    marginRight: 6,
    display: "inline-block",
  },
}));
