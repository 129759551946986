import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllSprint = async (params: TQueryParams) => {
  const response: TServerResponse<TSprintData[], unknown> = await request({
    url: apiUrls.sprint.GET_ALL_Sprint,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      searchFieldNumber: ["sprintId", "projectId"],
      searchFieldString: ["name", "status", "projectId"],
    },
  });
  return response;
};

export const useGetAllSprint = (params: TQueryParams) => {
  return useQuery(["admin", "all-sprint", params], () => getAllSprint(params));
};
