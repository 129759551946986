import { Box } from "@mantine/core";
import React, { useState } from "react";
import Scheduler from "./task-report-scheduler/Scheduler";
import { endOfDay, endOfMonth, startOfMonth } from "date-fns";

const Timeline = () => {
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const today = endOfDay(new Date());
  const [endDate, setEndDate] = useState<Date>(
    endOfMonth(new Date()) > today ? today : endOfMonth(new Date())
  );
  const [selectedStudent, setSelectedStudent] = useState<string | undefined>(
    undefined
  );

  return (
    <Box
      mt={12}
      py="md"
      pr={"sm"}
      pl={"sm"}
      bg={"#fff"}
      style={{ borderRadius: "calc(.5rem * 1)" }}
    >
      <Scheduler
        endDate={endDate}
        selectedStudent={selectedStudent}
        setEndDate={setEndDate}
        setSelectedStudent={setSelectedStudent}
        setStartDate={setStartDate}
        startDate={startDate}
      />
    </Box>
  );
};

export default Timeline;
