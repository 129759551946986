import moment from "moment";

export const calculateHours = (
  startDate: Date | null,
  endDate: Date | null,
  holidays: { date: string }[] = [],
  leaves: { dates: string[]; type: string; status: string }[] = []
): number => {
  if (!startDate || !endDate) return 0;

  const localStartDate = moment(startDate).local().startOf("day");
  const localEndDate = moment(endDate).local().endOf("day");

  const holidaysInRange = holidays.filter((holiday) =>
    moment(holiday.date)
      .local()
      .startOf("day")
      .isBetween(localStartDate, localEndDate, undefined, "[]")
  );

  let totalHours = 0;
  const daysDifference = localEndDate.diff(localStartDate, "days") + 1;
  totalHours = 8 * daysDifference;

  totalHours -= holidaysInRange.length * 8;

  let leaveHours = 0;
  const leaveDatesSet = new Set<string>();

  leaves.forEach((leave) => {
    if (leave.status === "accepted") {
      leave.dates.forEach((date) => {
        const leaveDate = moment(date)
          .local()
          .startOf("day")
          .format("YYYY-MM-DD");

        if (
          moment(leaveDate).isBetween(
            localStartDate,
            localEndDate,
            undefined,
            "[]"
          )
        ) {
          leaveDatesSet.add(leaveDate);
          if (leave.type === "fullday") {
            leaveHours += 8;
          } else if (leave.type === "halfday") {
            leaveHours += 4;
          }
        }
      });
    }
  });

  const overlappingDates = holidaysInRange.filter((holiday) =>
    leaveDatesSet.has(moment(holiday.date).format("YYYY-MM-DD"))
  );

  totalHours += overlappingDates.length * 8;

  totalHours -= leaveHours;

  return totalHours;
};
