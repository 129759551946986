import React, { FC, useEffect, useMemo, useState } from "react";
import {
  format,
  parseISO,
  getHours,
  getMinutes,
  eachDayOfInterval,
  startOfDay,
  getSeconds,
} from "date-fns";
import moment from "moment";
import { Popover, Select, Text } from "@mantine/core";
import {
  IconChevronLeft,
  IconChevronRight,
  IconInfoCircle,
} from "@tabler/icons-react";
import { COLORS } from "../../../../assets/colors/index.colors";
import { useGetUserTimeLine } from "../../../../hooks/reports/query/timeline/getUserTimeline.query";
import { useGetAllStudent } from "../../../../hooks/students/query/getAllStudent.query";
import classes from "./index.module.css";
import { groupOverlappingEvents } from "../../../../helper/timeline/groupOverlappingEvents";
import { checkOverlap } from "../../../../helper/timeline/checkOverlap";
import { updateMonth } from "../../../../helper/timeline/updateMonth";
import TimeLineInfoModel from "../components/TimeLineInfoModel";
import WorkLogDurationCalculator from "../components/WorkLogDurationCalculator";
import { getSlotBackgroundColor } from "../../../../helper/timeline/getSlotBackgroundColor";
import { getEmptySlotText } from "../../../../helper/timeline/getEmptySlotText";
import { allHolidayHook } from "../use-hook/allHolidayHook";
import { useGetAllLeaveDate } from "../../../../hooks/reports/query/getAllLeaveDate.query";
import { dateFormat } from "../../../../helper/dataFormat";
import { openTaskLink } from "../../../../helper/openTaskLink";
import { useGetAllEmployeeAbsentAttendance } from "../../../../hooks/all-student-attendance/query/useAllEmployeeAbsentAttendance.query";

interface IScheduler {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedStudent: string | undefined;
  setSelectedStudent: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectStudent?: boolean;
}

const hours = Array.from({ length: 12 }, (_, i) => i + 10);

const Scheduler: FC<IScheduler> = ({
  endDate,
  selectedStudent,
  setEndDate,
  setStartDate,
  startDate,
  selectStudent = true,
  setSelectedStudent,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });
  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  useEffect(() => {
    if (students.length > 0 && !selectedStudent) {
      setSelectedStudent(students[0].value);
    }
  }, [students]);

  const daysInMonth = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).reverse();

  const { data } = useGetUserTimeLine({
    startDate,
    endDate,
    studentId: selectedStudent,
  });

  const { data: leaveDate } = useGetAllLeaveDate({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    searchParams: {
      search: selectedStudent ?? "",
    },
  });
  const { data: absentData } = useGetAllEmployeeAbsentAttendance({
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
    studentId: selectedStudent ?? "",
  });

  const isHoliday = allHolidayHook();

  const events = useMemo(() => {
    if (!selectedStudent) return [];

    return (
      data?.data?.map((item: ITimeline) => ({
        id: item.id,
        start_date: moment(item.start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(item.end_date).format("YYYY-MM-DD HH:mm:ss"),
        text: item.title,
        workType: item.workType,
      })) || []
    );
  }, [selectedStudent, data]);

  const emptySlotsPerDay = daysInMonth.map((date) => {
    const emptySlotsForDay = [];
    let lastEndTime = new Date(date);
    lastEndTime.setHours(10, 0, 0, 0);

    const eventsForDay = events.filter(
      (event: { start_date: string }) =>
        format(parseISO(event.start_date), "yyyy-MM-dd") ===
        format(date, "yyyy-MM-dd")
    );

    const sortedEventsForDay = eventsForDay.sort(
      (a: { start_date: string }, b: { start_date: string }) =>
        parseISO(a.start_date).getTime() - parseISO(b.start_date).getTime()
    );

    sortedEventsForDay.forEach(
      (event: { start_date: string; end_date: string }) => {
        const eventStart = parseISO(event.start_date);

        if (lastEndTime < eventStart) {
          emptySlotsForDay.push({
            start_hour: getHours(lastEndTime),
            start_minute: getMinutes(lastEndTime),
            end_hour: getHours(eventStart),
            end_minute: getMinutes(eventStart),
          });
        }
        lastEndTime = parseISO(event.end_date);
      }
    );

    const endOfDay = new Date(date);
    endOfDay.setHours(22, 0, 0, 0);

    if (lastEndTime < endOfDay) {
      emptySlotsForDay.push({
        start_hour: getHours(lastEndTime),
        start_minute: getMinutes(lastEndTime),
        end_hour: 22,
        end_minute: 0,
      });
    }

    return emptySlotsForDay;
  });

  return (
    <div className={classes.scheduler}>
      <div className={classes.topNav}>
        {selectStudent && (
          <Select
            data={[...students]}
            value={selectedStudent}
            onChange={(value) => {
              if (value) setSelectedStudent(value);
            }}
            searchable
          />
        )}

        <div className={classes.flex}>
          {selectStudent && (
            <div className={classes.flex}>
              <div className={classes.dateControls}>
                <button
                  onClick={() => updateMonth(-1, setStartDate, setEndDate)}
                  className={classes.arrowLeftContainer}
                >
                  <IconChevronLeft color={COLORS.black} size={24} />
                </button>
                <span className={classes.dateTextHeader}>
                  {format(startDate, "MMMM yyyy")}
                </span>
                <button
                  onClick={() => updateMonth(1, setStartDate, setEndDate)}
                  className={classes.arrowLeftContainer}
                  disabled={endDate >= startOfDay(new Date())}
                >
                  <IconChevronRight color={COLORS.black} size={24} />
                </button>
              </div>
            </div>
          )}

          <button
            className={classes.infoCircleContainerIcon}
            onClick={() => setModalOpen(true)}
          >
            <IconInfoCircle size={20} color={COLORS.black} />
          </button>
        </div>
      </div>

      <div className={classes.timeGridContainer}>
        <div className={classes.timeGrid}>
          <div className={classes.corner}></div>
          <div className={classes.timeLabels}>
            {hours.map((hour) => (
              <div key={hour} className={classes.hour}>
                {hour}:00
              </div>
            ))}
          </div>
        </div>

        <div className={classes.body}>
          {daysInMonth.map((date, dayIndex) => (
            <div className={classes.row} key={date.toString()}>
              <div className={classes.roomLabel}>
                <div>
                  {dateFormat(date)}
                  <div>
                    <WorkLogDurationCalculator workLogs={events} date={date} />
                  </div>
                </div>
              </div>
              <div className={classes.timeSlots}>
                {groupOverlappingEvents(
                  events.filter(
                    (event: { start_date: string }) =>
                      format(parseISO(event.start_date), "yyyy-MM-dd") ===
                      format(date, "yyyy-MM-dd")
                  ),
                  checkOverlap
                ).map((group) =>
                  group.map((event, eventIndex) => {
                    const startTime = parseISO(event.start_date);
                    const endTime = parseISO(event.end_date);
                    const duration = moment.duration(
                      moment(endTime).diff(moment(startTime))
                    );
                    const remainingHours = duration.hours();
                    const remainingMinutes = duration.minutes();
                    const formattedDuration = `${remainingHours}h ${remainingMinutes}m`;

                    const formattedStartTime =
                      moment(startTime).format("h:mm A");
                    const formattedEndTime = moment(endTime).format("h:mm A");

                    const startHour = getHours(startTime);
                    const startMinute = getMinutes(startTime);
                    const endHour = getHours(endTime);
                    const endMinute = getMinutes(endTime);

                    const startPosition =
                      (((startHour - 10) * 60 + startMinute) / 720) * 100;
                    const startSecond = getSeconds(startTime);
                    const endSecond = getSeconds(endTime);

                    const durationInSeconds =
                      (endHour - startHour) * 3600 +
                      (endMinute - startMinute) * 60 +
                      (endSecond - startSecond);

                    const width = (durationInSeconds / (12 * 3600)) * 100;

                    const topPosition = (eventIndex - 0.6) * 20;
                    console.log("event", event);

                    return (
                      <Popover
                        width={300}
                        position="bottom"
                        withArrow
                        shadow="md"
                        key={event.id}
                      >
                        <Popover.Target>
                          <div
                            className={classes.event}
                            style={{
                              left: `${startPosition}%`,
                              width: `${width}%`,
                              height: "15px",
                              top: `${topPosition}px`,
                              position: "absolute",
                              backgroundColor:
                                event.workType === "timeline"
                                  ? "#ff008a"
                                  : "#3eaadd",
                              color: "#fff",
                              padding: "0px",
                              fontSize: "10px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              marginTop: eventIndex % 2 && "2px",
                              cursor: "pointer",
                              zIndex: 1,
                            }}
                          >
                            <div>{event.text}</div>
                          </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <a
                            href={openTaskLink(event.id)}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <div
                              style={{
                                fontSize: 12,
                                color: "#000",
                                fontWeight: "bold",
                              }}
                            >
                              Id: {event.id}
                            </div>
                            <Text size="sm" mb={6}>
                              {event.text}
                            </Text>
                          </a>

                          <Text size="sm" fw={600}>
                            Time: {formattedStartTime} : {formattedEndTime} (
                            {formattedDuration})
                          </Text>
                          <Text size="xs">
                            {event.workType !== "timeline" &&
                              "(  Auto Generated )"}
                          </Text>
                        </Popover.Dropdown>
                      </Popover>
                    );
                  })
                )}

                {emptySlotsPerDay[dayIndex].map((slot, index) => {
                  const slotStartMinutes =
                    slot.start_hour * 60 + slot.start_minute;
                  const slotEndMinutes = slot.end_hour * 60 + slot.end_minute;

                  const startPosition = ((slotStartMinutes - 600) / 720) * 100;
                  const width =
                    ((slotEndMinutes - slotStartMinutes) / 720) * 100;

                  let isLeaveDay = false;
                  let isAbsent = false;

                  if (selectedStudent) {
                    isLeaveDay =
                      leaveDate?.data?.some(
                        (leave) =>
                          leave.status === "accepted" &&
                          leave.type === "fullday" &&
                          leave.dates.some((leaveDate: moment.MomentInput) =>
                            moment(leaveDate).isSame(date, "day")
                          )
                      ) ?? false;

                    isAbsent =
                      absentData?.data?.some(
                        (attendance: { createdAt: Date; status: string }) =>
                          attendance.status === "absent" &&
                          moment(attendance.createdAt).isSame(date, "day")
                      ) ?? false;
                  }

                  const slotBackgroundColor = getSlotBackgroundColor(
                    date,
                    isLeaveDay ?? false,
                    isHoliday,
                    isAbsent
                  );
                  const emptySlotText = getEmptySlotText(
                    date,
                    isLeaveDay ?? false,
                    isHoliday,
                    isAbsent
                  );

                  return (
                    <button
                      key={`${slot.start_hour}-${index}`}
                      className={classes.emptySlot}
                      style={{
                        left: `${startPosition}%`,
                        width: `${width}%`,
                        backgroundColor: slotBackgroundColor,
                      }}
                    >
                      {emptySlotText}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <TimeLineInfoModel modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </div>
  );
};

export default Scheduler;
