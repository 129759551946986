import { ActionIcon, Box, Flex, rem } from "@mantine/core";
import React, { FC, useMemo, useState } from "react";
import CustomTable from "../../../../component/table";
import { COLUMNS } from "../../../../columns";
import { useGetAllEmpWorks } from "../../../../hooks/emp-work/query/getAllEmpWork.query";
import moment from "moment";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { MonthPickerInput } from "@mantine/dates";

interface IEmpWork {
  selectedStudent?: string | undefined;
  startDate: Date;
  endDate: Date;
  setStartDate: (value: React.SetStateAction<Date>) => void;
  setEndDate: (value: React.SetStateAction<Date>) => void;
}

const ProjectWorkHoursTable: FC<IEmpWork> = ({
  selectedStudent,
  endDate,
  startDate,
  setEndDate,
  setStartDate,
}) => {
  const inputDate = moment(new Date()).startOf("day").toDate();
  const [date, setDate] = useState<Date | null>(inputDate);
  const [searchText, setSearchText] = useState<string>("");

  const { data, isLoading } = useGetAllEmpWorks({
    startDate: startDate ?? new Date(),
    endDate: endDate ?? new Date(),
    searchParams: {
      search: selectedStudent ?? "",
    },
  });

  const EmpWork = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data
        .map((employeeWorkArray) => {
          if (!employeeWorkArray.length) return null;

          return {
            ...employeeWorkArray,
            startDate,
            firstEmployee: employeeWorkArray[0]?.employee || "",
          };
        })
        .filter((emp) =>
          emp?.firstEmployee.toLowerCase().includes(searchText.toLowerCase())
        );
    }
    return [];
  }, [isLoading, data, searchText]);

  const handleDateChange = (val: Date | null) => {
    if (val) {
      setDate(val);
      setStartDate(moment(val).startOf("month").toDate());
      setEndDate(moment(val).endOf("month").toDate());
    }
  };

  return (
    <Box>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={[...COLUMNS.projectWorkHours]}
        data={EmpWork}
        grid={false}
        headerProps={{
          search: true,
          onChangeText: setSearchText,
          rightComponent: (
            <Flex mih={50} gap="md">
              <Flex align={"center"} mr={6}>
                <ActionIcon
                  onClick={() =>
                    handleDateChange(moment(date).subtract(1, "month").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  mr={3}
                >
                  <IconChevronLeft size={16} color="black" />
                </ActionIcon>
                <MonthPickerInput
                  maxDate={new Date()}
                  styles={{ input: { border: "none" } }}
                  icon={
                    <IconCalendar
                      style={{ width: rem(18), height: rem(18) }}
                      stroke={1.5}
                    />
                  }
                  placeholder="Pick month"
                  value={date}
                  onChange={handleDateChange}
                />
                <ActionIcon
                  disabled={
                    date ? moment(date).isSame(moment(), "month") : false
                  }
                  onClick={() =>
                    handleDateChange(moment(date).add(1, "month").toDate())
                  }
                  size={"lg"}
                  variant="light"
                  bg={"white"}
                  ml={3}
                >
                  <IconChevronRight size={16} color="black" />
                </ActionIcon>
              </Flex>
            </Flex>
          ),
        }}
      />
    </Box>
  );
};

export default ProjectWorkHoursTable;
