import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllNotification = async (params: TQueryParams) => {
  const response: TServerResponse<TNotificationData[], unknown> = await request(
    {
      url: apiUrls.notification.GET_ALL_NOTIFICATIONS,
      method: "GET",
      params: {
        ...params.paging,
        search: params.searchParams?.search,
        searchFieldString: ["type", "status"],
      },
    }
  );
  return response;
};

export const useGetAllNotifications = (params: TQueryParams) => {
  return useQuery(["admin", "get-all-notifications", params], () =>
    getAllNotification(params)
  );
};
