import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllEmpWork = async (params: TQueryParams) => {
  const response: TServerResponse<WorkLogData, unknown> = await request({
    url: apiUrls.empWorks.ALL_EMP_WORK,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.searchParams?.search,
      searchFieldObjectKey: ["studentID"],
    },
  });
  return response;
};

export const useGetAllEmpWorks = (params: TQueryParams) => {
  return useQuery(["admin", "all-empWork", params], () =>
    getAllEmpWork(params)
  );
};
