import { Box, createStyles, Group, Select, Text } from "@mantine/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelProps,
} from "recharts";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { COLORS } from "../../../assets/colors/index.colors";
import { useGetAllEmpPoint } from "../../../hooks/empPoint/query/getAllEmpPoint.query";
import { useGetAllStudent } from "../../../hooks/students/query/getAllStudent.query";
import moment from "moment";
import { handleDateTypeChange } from "../../works/task-report/helper/handleDateTypeChange";
import { updateDateRange } from "../../works/task-report/helper/updateDateRange";
import {
  IconCaretLeftFilled,
  IconCaretRightFilled,
  IconPlus,
} from "@tabler/icons-react";
import { startOfDay } from "date-fns";
import EmpPointModal, { IEmpPointModalRef } from "./modal/EmpPointModal";
import ThemeButton from "../../../component/button/ThemeButton";

const EmpPoint = () => {
  const { classes } = useStyles();
  const modalRef = useRef<IEmpPointModalRef>(null);
  const inputDate = moment().startOf("week").toDate();
  const endDates = moment().endOf("week").toDate();
  const [dateType, setDateType] = useState<string | null>("Week");
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const [startDate, setStartDate] = useState<Date>(inputDate);
  const [endDate, setEndDate] = useState<Date>(endDates);
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });
  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  useEffect(() => {
    if (students.length > 0 && !selectedStudent) {
      setSelectedStudent(students[0].value);
    }
  }, [students]);

  const { data, refetch } = useGetAllEmpPoint({
    startDate: startDate,
    endDate: endDate,
    studentId: selectedStudent,
  });

  const maxPoints =
    data && Array.isArray(data.data)
      ? Math.max(...data.data.map((d) => d.totalPoints), 12)
      : 12;

  const CustomLabel: React.FC<LabelProps> = ({ x, y, value }) => {
    if (typeof value !== "number") return null;

    return (
      <text
        x={(Number(x) || 0) + 15}
        y={value < 0 ? (Number(y) || 0) - 10 : (Number(y) || 0) - 4}
        fill={value < 0 ? "#fff" : "#ff008a"}
        textAnchor="middle"
        fontSize={12}
      >
        {value.toFixed(2)}
      </text>
    );
  };

  return (
    <Box className={classes.root}>
      <EmpPointModal reload={refetch} ref={modalRef} />
      <Group position="apart">
        <Group>
          <Select
            data={[...students]}
            value={selectedStudent}
            onChange={(value) => {
              if (value) setSelectedStudent(value);
            }}
            searchable
          />
          <Box w={100}>
            <Select
              defaultValue="Day"
              data={["Week", "Month"]}
              value={dateType}
              onChange={(value) => {
                if (value) {
                  handleDateTypeChange(
                    value,
                    setDateType,
                    setStartDate,
                    setEndDate
                  );
                }
              }}
            />
          </Box>

          <Group>
            <button
              className={classes.arrowContainer}
              onClick={() =>
                updateDateRange(
                  "prev",
                  startDate,
                  dateType,
                  setStartDate,
                  setEndDate
                )
              }
            >
              <IconCaretLeftFilled
                className={classes.linkIconActive}
                stroke={1.5}
              />
            </button>
            <Box className={classes.dateContainer}>
              <Text size="sm" fw={500}>
                {startDate ? moment(startDate).format("DD MMM YYYY") : ""} -
                {endDate ? moment(endDate).format("DD MMM YYYY") : ""}
              </Text>
            </Box>
            <button
              className={classes.arrowContainer}
              onClick={() =>
                updateDateRange(
                  "next",
                  startDate,
                  dateType,
                  setStartDate,
                  setEndDate
                )
              }
              disabled={endDate ? endDate >= startOfDay(new Date()) : false}
            >
              <IconCaretRightFilled
                className={classes.linkIconActive}
                stroke={1.5}
              />
            </button>
          </Group>
        </Group>

        <Box onClick={() => modalRef.current?.toggleModal()}>
          <ThemeButton
            title="Add Emp Point"
            mr={15}
            leftIcon={<IconPlus size={20} />}
          />
        </Box>
      </Group>
      <Box mt={70}>
        {Array.isArray(data?.data) && (data?.data ?? []).length > 0 ? (
          <Box style={{ width: "100%", height: "400px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data?.data ?? []}>
                <XAxis
                  dataKey="date"
                  tickFormatter={(date) => moment(date).format("DD")}
                />
                <YAxis domain={[0, maxPoints]} />

                <Bar
                  dataKey="totalPoints"
                  barSize={30}
                  label={{ position: "insideTop", content: CustomLabel }}
                >
                  {data?.data?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry?.holidayTitle ? "#d90303a8" : "#ff008a"}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text size="lg" color="dimmed">
              No Data Available
            </Text>
          </Box>
        )}
      </Box>

      <Box>
        <Group position="right">
          <Box>
            <span
              style={{ background: "#d90303a8" }}
              className={classes.bgColor}
            />
            Holiday
          </Box>
          <Box>
            <span
              style={{ background: "#ff008a" }}
              className={classes.bgColor}
            />
            Regular
          </Box>
        </Group>
      </Box>
    </Box>
  );
};

export default EmpPoint;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
  arrowContainer: {
    padding: "3px 6px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    background: "#fff",
  },
  linkIconActive: {
    color: "#757373",
    width: 20,
    height: 20,
  },
  dateContainer: {
    padding: "3px 8px",
    border: "1px solid #757373",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
  },
  bgColor: {
    width: 12,
    height: 12,
    borderRadius: 50,
    marginRight: 6,
    display: "inline-block",
  },
}));
