import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const CreateLeavePolicy = async (data: TLeavePolicy) => {
  const response = await request({
    url: apiUrls.leavePolicy.CREATE_LEAVE_POLICY,
    method: "POST",
    data,
  });
  return response;
};

export const useCreateLeavePolicyMutation = () => {
  return useMutation(CreateLeavePolicy);
};
