import React from "react";
import { Paper } from "@mantine/core";
import { COLORS } from "../../assets/colors/index.colors";
import { useDebouncedState } from "@mantine/hooks";
import { useMemo, useState } from "react";
import moment from "moment";
import { useGetEmpWorkHourLossQuery } from "../../hooks/employee/query/useGetEmpWorkHourLoss.query";
import { getFilterDates } from "./utils";
import CustomTable from "../../component/table";
import { IWorkHourLoss } from "./definiation";
import { workHourLossColumn } from "./column";
import { DatePickerInput } from "@mantine/dates";
import { CONSTANTS } from "../../constant";

const WorkHourLoss = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useDebouncedState("", 200);
  const [date, setDate] = useState<[Date | null, Date | null]>([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);

  const dates = useMemo(() => {
    return getFilterDates(date);
  }, [date]);

  const { data, isLoading } = useGetEmpWorkHourLossQuery({
    search,
    page: page,
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    startDate: dates[0],
    endDate: dates[1],
  });
  

  const workLossData: IWorkHourLoss[] = useMemo(() => {
    if (data && data.data) {
      return data.data;
    }
    return [] as IWorkHourLoss[];
  }, [data, data?.data]);

  return (
    <Paper py={16} px={30} radius={5} color={COLORS.white}>
      <CustomTable
        columns={workHourLossColumn}
        data={workLossData}
        headerProps={{
          search: true,
          onChangeText: setSearch,
          rightComponent: (
            <DatePickerInput
              type="range"
              value={date}
              onChange={setDate}
              mr={16}
            />
          ),
        }}
        grid={false}
        isDashboardTable={false}
        loading={isLoading}
        paginationProps={{
          setPage: setPage,
          totalPages: data?.pageData?.total ?? 0,
          active: page,
          pageLimit: CONSTANTS.PAGE_LIMIT,
        }}
      />
    </Paper>
  );
};

export default WorkHourLoss;
